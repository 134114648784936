import React, { useState } from "react";
import { Variable } from "lucide-react";
import { cn } from "../../../../util/reusables";
import { LocationAccountInfo } from "../../../../api/locations.api";
import {
  Command,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../../primitives/command";
import { PortfolioItem } from "../../../../api/portfolio.api";

interface VariableSelectorProps {
  onSelectVariable: (variable: string) => void;
  className?: string;
}

export default function VariableSelector({
  onSelectVariable,
  className,
}: VariableSelectorProps) {
  const [searchQuery, setSearchQuery] = useState<string>("");

  // Filter variables based on search query
  const filteredVariables = dynamicVariables.filter(
    (variable) =>
      searchQuery.length === 0 ||
      variableToLabel(variable)
        .toLowerCase()
        .includes(searchQuery.toLowerCase()),
  );

  return (
    <Command shouldFilter={false} className={cn("min-w-72", className)}>
      <CommandInput
        value={searchQuery}
        autoFocus
        onValueChange={setSearchQuery}
        placeholder="Search variables..."
        className="h-8 w-full rounded-full bg-muted"
      />
      <CommandList>
        <CommandGroup heading="Location Variables">
          {filteredVariables.length === 0 ? (
            <div className="py-6 text-center text-sm">No variables found.</div>
          ) : (
            filteredVariables.map((variable) => (
              <CommandItem
                key={variable}
                value={variable}
                onSelect={() => onSelectVariable(variable)}
              >
                <Variable className="mr-2 size-3" />
                <span>{variableToLabel(variable)}</span>
              </CommandItem>
            ))
          )}
        </CommandGroup>
      </CommandList>
    </Command>
  );
}

/**
 * Converts a variable name to a human-readable label
 *
 * @param variable - The variable name to convert
 * @returns The human-readable label for the variable
 */
export const variableToLabel = (variable: string): string => {
  return variable
    .replace(/_/g, " ")
    .replace(/\b\w/g, (char) => char.toUpperCase());
};

/**
 * List of all supported dynamic variables for location-based content
 */
export const dynamicVariables = [
  "location_name",
  "address",
  "city",
  "state",
  "zip",
  "contact_email",
  "phone_number",
  "website_url",
  // "hours",
] as const;

/**
 * Type representing valid dynamic variable names
 */
export type DynamicVariable = (typeof dynamicVariables)[number];

/**
 * Maps dynamic variables to their accessor functions for retrieving values from a LocationAccountInfo object
 */
export const dynamicVariableAccessors: Record<
  DynamicVariable,
  (account: LocationAccountInfo) => string
> = {
  location_name: (account) => account?.teamName || "",
  address: (account) => account?.location?.address || "",
  city: (account) => account?.location?.city || "",
  state: (account) => account?.location?.state || "",
  zip: (account) => account?.location?.zip || "",
  contact_email: (account) => account?.location?.contact_email || "",
  phone_number: (account) => formatPhoneNumber(account?.location?.phone_number),
  website_url: (account) => account?.location?.website_url || "",
  // hours: (account) => formatOpeningHours(account?.location?.hours),
};

/**
 * Gets all dynamic variable values for a specific account
 *
 * @param account - The account to extract variable values from
 * @returns A record mapping variable names to their values
 */
export const getDynamicVariableValues = (
  account?: LocationAccountInfo,
): Record<DynamicVariable, string> => {
  if (!account) return {} as Record<DynamicVariable, string>;

  return dynamicVariables.reduce<Record<DynamicVariable, string>>(
    (values, variable) => {
      values[variable] = dynamicVariableAccessors[variable](account);
      return values;
    },
    {} as Record<DynamicVariable, string>,
  );
};

/**
 * Interface representing a node in the rich text editor content
 */
interface ContentNode {
  type: string;
  attrs?: {
    variable?: DynamicVariable;
    [key: string]: any;
  };
  text?: string;
  content?: ContentNode[];
  [key: string]: any;
}

/**
 * Analyzes a template string to find which dynamic variables are being used
 *
 * @param template - The template string to analyze (can be a JSON string or plain text)
 * @returns An array of dynamic variable names that are used in the template
 */
export const getDynamicVariablesInUse = (
  portfolioItem: PortfolioItem,
): DynamicVariable[] => {
  if (!portfolioItem) return [];

  const captions = [
    portfolioItem.caption,
    ...(Object.values(portfolioItem.overrides ?? {})?.map(
      (override) => override.caption,
    ) ?? []),
  ];
  const usedVariables = new Set<DynamicVariable>();

  captions.forEach((caption) => {
    if (!caption) return;

    try {
      // Try to parse as JSON (for rich text editor content)
      const content = JSON.parse(caption) as ContentNode;

      // Recursive function to find variables in the content structure
      const findVariablesInNode = (node: ContentNode): void => {
        if (node.type === "variable" && node.attrs?.variable) {
          if (
            dynamicVariables.includes(node.attrs.variable as DynamicVariable)
          ) {
            usedVariables.add(node.attrs.variable as DynamicVariable);
          }
        }

        // Process content array if it exists
        if (Array.isArray(node.content)) {
          node.content.forEach((child) => {
            findVariablesInNode(child);
          });
        }
      };

      findVariablesInNode(content);
    } catch (e) {
      // If not valid JSON, check for variable patterns in plain text
      // Look for patterns like {{variable_name}} in the text
      const variablePattern = /\{\{(.*?)\}\}/g;
      let match;

      while ((match = variablePattern.exec(caption)) !== null) {
        const variableName = match[1].trim() as DynamicVariable;
        if (dynamicVariables.includes(variableName)) {
          usedVariables.add(variableName);
        }
      }
    }
  });

  return Array.from(usedVariables);
};

/**
 * Replaces dynamic variables in a template with their actual values
 *
 * @param template - The template string containing variables to replace
 * @param account - The account containing the values for the variables
 * @returns The template with all variables replaced with their values
 */
export const replaceDynamicVariables = (
  template: string,
  account?: LocationAccountInfo,
): string => {
  if (!template) return "";
  if (!account) return template;

  const variableValues = getDynamicVariableValues(account);

  try {
    const content = JSON.parse(template) as ContentNode;

    // Deep clone the content to avoid mutating the original
    const processedContent = JSON.parse(JSON.stringify(content)) as ContentNode;

    // Recursive function to process all nodes
    const processNode = (node: ContentNode): ContentNode => {
      if (node.type === "variable" && node.attrs?.variable) {
        if (!variableValues[node.attrs.variable]) {
          // Return a placeholder for missing variables
          return {
            type: "text",
            text: "[MISSING VARIABLE]",
          };
        }
        // Replace variable node with text node containing the replacement value
        return {
          type: "text",
          text: variableValues[node.attrs.variable] || "[MISSING VARIABLE]",
        };
      }

      // Process content array if it exists
      if (Array.isArray(node.content)) {
        node.content = node.content.map((child) => {
          return processNode(child);
        });
      }

      return node;
    };

    // Process the entire content structure
    const result = processNode(processedContent);

    return JSON.stringify(result);
  } catch (error) {
    // Fallback to simple string replacement if not valid JSON
    return template.replace(/{{\s*(\w+)\s*}}/g, (_, variable) => {
      return (
        variableValues[variable as DynamicVariable] || "[MISSING VARIABLE]"
      );
    });
  }
};

// const formatOpeningHours = (hours: Record<string, any>): string => {
//   if (!hours) return "Hours not available";
//   return Object.entries(hours)
//     .map(
//       ([day, { is_open, open, close }]) =>
//         `${capitalize(day)}: ${is_open ? `${open} - ${close}` : "Closed"}`,
//     )
//     .join(", ");
// };

const formatPhoneNumber = (phone: string | undefined): string => {
  if (!phone) return "";
  return phone.replace(/(\\d{3})(\\d{3})(\\d{4})/, "($1) $2-$3");
};
