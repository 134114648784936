import { useQuery } from "@tanstack/react-query";
import { Home } from "lucide-react";
import React, { type ReactNode } from "react";
import { checkMetaAccountStatus } from "../../api/paid/meta/campaigns.api";
import { url } from "../../routes";
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
} from "../../primitives/alert-dialog";
import { Button } from "../../primitives/button";
import { Module, updateUserModule } from "../../api/auth.api";
import Load from "../../partials/Load/Load";
import { Link, Navigate, useNavigate } from "react-router-dom";
import WizardIllustration from "../../assets/WizardIllustration";
import { motion, AnimatePresence } from "framer-motion";
import { setUser } from "../../reducers/user";
import { useDispatch, useSelector } from "react-redux";
import { setError } from "../../reducers/toolkit";
import { RootState } from "../../store";

export default function CheckAdAccount({ children }: { children: ReactNode }) {
  const user = useSelector((state: RootState) => state.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { data: accountStatus, isLoading: isLoadingAccountStatus } = useQuery({
    queryKey: ["metaAccountStatus", user?.hub?._id, user?.workspace?._id],
    queryFn: checkMetaAccountStatus,
  });

  if (isLoadingAccountStatus) return <Load />;

  if (user?.module !== "paid")
    return <Navigate to={url(user, "/", undefined, true)} />;

  if (accountStatus?.success) return <>{children}</>;

  return (
    <AlertDialog open={user?.module === "paid"}>
      <AlertDialogContent className="flex h-full max-h-[90vh] max-w-[90vw] flex-col items-center justify-center gap-8 border-[3px] border-white bg-gradient-to-br from-background/90 via-background/80 to-background/70 p-8 shadow-inner backdrop-blur-md">
        <motion.div
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ type: "spring", duration: 0.8 }}
          className="h-64 w-64"
        >
          <WizardIllustration />
        </motion.div>

        <AlertDialogHeader className="flex flex-col gap-6 text-center">
          <AlertDialogTitle className="text-4xl font-bold tracking-tight">
            <motion.div
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ delay: 0.3, type: "spring" }}
              className="flex items-center justify-center gap-2"
              dangerouslySetInnerHTML={{
                __html: accountStatus?.message
                  ?.replace(
                    /(https?:\/\/[^\s]+)/g,
                    '<a href="$1" target="_blank" rel="noopener noreferrer" class="text-primary hover:text-primary/80 underline underline-offset-4">$1</a>',
                  )
                  ?.replace(
                    /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/g,
                    '<a href="mailto:$1" class="text-primary hover:text-primary/80 underline underline-offset-4">$1</a>',
                  ),
              }}
            />
          </AlertDialogTitle>
          <AlertDialogDescription className="sr-only text-lg text-muted-foreground">
            Please complete the required steps to continue using the platform.
          </AlertDialogDescription>
        </AlertDialogHeader>

        <AlertDialogFooter className="flex w-full flex-col gap-4 sm:flex-row sm:justify-center">
          <AnimatePresence mode="wait">
            <motion.div
              key="return-home"
              initial={{ y: 20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 20, opacity: 0 }}
              transition={{ delay: 0.6, type: "spring" }}
            >
              <Button
                variant="outline"
                className="w-full transition-colors hover:bg-background/80 sm:w-auto"
                size="lg"
                onClick={() => {
                  updateUserModule("organic" as Module)
                    .then((updatedUser) => {
                      dispatch(setUser(updatedUser));
                    })
                    .then(() => {
                      sessionStorage.removeItem("flamel-redirect");
                      navigate(url(user, "/", undefined, true));
                    })
                    .catch(() => {
                      dispatch(setError("Failed to update module"));
                    });
                }}
              >
                <Home className="mr-2 h-4 w-4" />
                Return Home
              </Button>
            </motion.div>

            {accountStatus?.route && accountStatus?.cta && (
              <motion.div
                key="cta-button"
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 20, opacity: 0 }}
                transition={{ delay: 0.8, type: "spring" }}
              >
                <Link to={url(user, accountStatus?.route)}>
                  <Button
                    size="lg"
                    className="w-full bg-primary text-primary-foreground shadow-fl transition-all hover:bg-primary/90 hover:shadow-xl sm:w-auto"
                  >
                    {accountStatus?.cta}
                  </Button>
                </Link>
              </motion.div>
            )}
          </AnimatePresence>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}
