import React, { FormEvent, useState } from "react";
import { z } from "zod";
import { UserAccount } from "~/src/api/withApi";
import { onboardUser } from "~/src/api/admin.api";
import { Button } from "~/src/primitives/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "~/src/primitives/card";
import { Input } from "~/src/primitives/input";
import { Label } from "~/src/primitives/label";
import { useToast } from "~/src/primitives/use-toast";

// Define the email schema
const emailSchema = z.string().email();

// Function to validate an email
export function validateEmail(email: string) {
  try {
    emailSchema.parse(email);
    console.log(`${email} is a valid email.`);
    return true;
  } catch (e) {
    console.error(`${email} is not a valid email.`, e.errors);
  }
  return false;
}

export default function AssistedSignUp() {
  const [isLoading, setIsLoading] = useState(false);
  const { toast } = useToast();
  const [password] = useState<string>("FlamelOnboarding23!");

  const handleCreateAccount = async (event: FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();
    const { target } = event ?? {};
    const [email, firstName, lastName, businessName] = (target as any) ?? [];

    try {
      if (!validateEmail(email?.value)) {
        throw new Error("Please use a valid email.");
      }

      const newUser: UserAccount = {
        email: email?.value?.toLowerCase(),
        password: password,
        firstname: firstName?.value,
        lastname: lastName?.value,
        metadata: { businessName: businessName?.value },
        pfp: "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460__340.png",
      };

      await onboardUser(newUser);
      toast({
        title: "Account created",
        description: "Account created successfully.",
      });
    } catch (err) {
      toast({
        title: "Error",
        description: err.message,
        variant: "destructive",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Card className="min-w-sm max-w-sm">
      <CardHeader className="space-y-1">
        <CardTitle className="text-2xl">Create an account</CardTitle>
        <CardDescription>
          Enter an email below to create an account.
        </CardDescription>
      </CardHeader>
      <CardContent className="grid gap-4">
        <form onSubmit={async (e) => await handleCreateAccount(e)}>
          <div className="grid gap-4">
            <div className="grid gap-1.5">
              <Label htmlFor="email">Email</Label>
              <Input
                required
                id="email"
                placeholder="name@example.com"
                type="email"
                autoCapitalize="none"
                autoComplete="email"
                autoCorrect="off"
                disabled={isLoading}
              />
            </div>
            <div className="grid gap-1.5">
              <Label htmlFor="firstName">First Name</Label>
              <Input
                required
                id="firstName"
                placeholder="Luna"
                type="text"
                autoCapitalize="none"
                autoComplete="given-name"
                autoCorrect="off"
                disabled={isLoading}
              />
            </div>
            <div className="grid gap-1.5">
              <Label htmlFor="lastName">Last Name</Label>
              <Input
                required
                id="lastName"
                placeholder="Lovelace"
                type="text"
                autoCapitalize="none"
                autoComplete="family-name"
                autoCorrect="off"
                disabled={isLoading}
              />
            </div>
            <div className="grid gap-1.5">
              <Label htmlFor="businessName">Business Name</Label>
              <Input
                required
                id="businessName"
                placeholder="Business & Co."
                type="text"
                autoCapitalize="none"
                autoCorrect="off"
                disabled={isLoading}
              />
            </div>
            <div className="grid gap-1.5">
              <Label htmlFor="password">Password</Label>
              <p>{password}</p>
            </div>
            <Button disabled={isLoading}>
              {isLoading && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="mr-2 h-4 w-4 animate-spin"
                >
                  <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                </svg>
              )}
              Create Account
            </Button>
          </div>
        </form>
      </CardContent>
    </Card>
  );
}
