import React from "react";
import { BsPatchCheckFill } from "react-icons/bs";
import "./linkedin-template.css";
import LoadMedia from "../../Load/LoadMedia";
import { cn, pluralize, username, withPfp } from "~/src/util/reusables";
import { useSelector } from "react-redux";
import { RootState } from "~/src/store";
import {
  LinkedInPostResponse,
  LinkedInReactionType,
  getActiveAccountForPlatform,
} from "~/src/views/CommunityManagement/lib/utils";
import dayjs from "~/src/util/dayjs";
import { Globe2 } from "lucide-react";
import { ReactionIcon } from "~/src/views/CommunityManagement/lib/linkedin-reactions";
import Post from "~/src/lib/Post/Post";
import { PlatformType } from "~/src/util/platforms";
import { Team } from "~/src/api/team.api";
import ViewOnlyCaptionWithFallback from "../../../views/Post/components/editors/ViewOnlyCaptionWithFallback";
import { LocationAccountInfo } from "../../../api/withApi";

interface LinkedInTemplateProps {
  className?: string;
  display?: React.ReactNode;
  media?: string;
  mimeType?: string;
  caption: string;
  when?: dayjs.Dayjs;
  selected?: boolean;
  style?: any;
  onSelectAccount?: () => void;
  raw?: LinkedInPostResponse;
  /**
   * @deprecated use direct `forceDisplayPfp` and `forceDisplayName` instead
   */
  forceWorkspace?: Team; // Allows you to override the PFP and Name per: https://gitlab.com/flamel-ai/flamel-ai/-/issues/905
  forceDisplayPfp?: string; // Allows absolute control over pfp
  forceDisplayName?: string; // Allows absolute control over name
  account?: LocationAccountInfo;
}

const formatSince = (targetDate: number) => {
  const now = dayjs();
  const duration = now.diff(targetDate);

  if (duration >= 31536000000) {
    // More than a year
    return `${Math.floor(duration / 31536000000)}y`;
  } else if (duration >= 2592000000) {
    // More than a month
    return `${Math.floor(duration / 2592000000)}mo`;
  } else if (duration >= 604800000) {
    // More than a week (7 days)
    return `${Math.floor(duration / 604800000)}w`;
  } else if (duration >= 86400000) {
    // More than a day
    return `${Math.floor(duration / 86400000)}d`;
  } else if (duration >= 3600000) {
    // More than an hour
    return `${Math.floor(duration / 3600000)}h`;
  } else if (duration >= 60000) {
    // More than a minute
    return `${Math.floor(duration / 60000)}m`;
  } else if (duration <= 60000 && duration >= 0) {
    return "now";
  } else {
    const futureDate = dayjs(targetDate);
    return futureDate.year() === dayjs().year()
      ? futureDate.format("MMMM D")
      : futureDate.format("MMMM D, YYYY");
  }
};

const LinkedInTemplate = ({
  className,
  display,
  media,
  mimeType,
  caption,
  selected,
  style,
  when,
  onSelectAccount,
  raw,
  forceWorkspace,
  forceDisplayPfp,
  forceDisplayName,
  account,
}: LinkedInTemplateProps) => {
  const user = useSelector((state: RootState) => state.user);
  const { parent, author, publishedAt, reactions, totalComments, content } =
    raw ?? {};
  const { name, profilePictureUrl } = author ?? {};
  const article = content?.article;
  const postedAt = when?.valueOf() ?? publishedAt;

  const { name: _name, pfp: _pfp } = getActiveAccountForPlatform(
    forceWorkspace ?? user?.workspace,
    PlatformType.LinkedIn,
  );

  const parentComponent = parent && (
    <div className="px-3">
      <Post
        caption={parent?.body ?? ""}
        platform="linkedin"
        media={parent?.media}
        raw={{ publishedAt: parent?.publishedAt, author: parent?.author }}
      />
    </div>
  );

  return (
    <div
      className={cn(
        "h-auto max-h-fit w-full animate-fadein overflow-hidden rounded-lg bg-background",
        className,
      )}
      style={style ?? {}}
    >
      <div className="linkedin post-template h-full overflow-y-auto">
        <div className="user" onClick={onSelectAccount}>
          <div className="mr-2 inline-block h-9 w-9 overflow-hidden rounded-full p-0">
            <img
              className="h-full w-full object-cover"
              crossOrigin="anonymous"
              src={withPfp(
                forceDisplayName
                  ? forceDisplayPfp
                  : author
                    ? profilePictureUrl
                    : _pfp,
                (forceDisplayName ?? author) ? name : (_name ?? username(user)),
              )}
              alt=""
            />
          </div>
          <div className="name">
            {forceDisplayName ?? (author ? name : (_name ?? username(user)))}
            {postedAt ? (
              <div className="details flex items-center space-x-1">
                <span>{formatSince(postedAt)}</span>
                <span>&middot;</span>
                <Globe2 className="h-3 w-3" />
              </div>
            ) : (
              <div className="details flex items-center space-x-1">
                <span>Post to Anyone</span>
              </div>
            )}
          </div>
        </div>
        <ViewOnlyCaptionWithFallback
          platform={PlatformType.LinkedIn}
          stringContent={caption}
          className="caption break break-words text-sm"
          account={account}
        />
        {display && (
          <div
            className={cn("relative", article?.source && "cursor-pointer")}
            onClick={() => {
              if (article?.source) {
                const a = document.createElement("a");
                a.href = article.source;
                a.target = "_blank";
                a.click();
              }
            }}
          >
            {display}
            {article && (
              <div className="grid w-full gap-1 bg-[#EEF3F7] px-3 py-2">
                <p className="text-sm font-semibold">{article.title}</p>
                <p className="text-2xs text-muted-foreground">
                  {new URL(article.source).host}
                </p>
              </div>
            )}
          </div>
        )}
        {parentComponent}
        {!display && media && (
          <LoadMedia
            mimeType={mimeType}
            src={media}
            className="rounded-t-none"
          />
        )}
        <div className="flex items-center justify-between p-3">
          <div className="flex items-center gap-1">
            {reactions && Boolean(Object.keys(reactions).length) && (
              <>
                <div className="flex items-center [&>:not(:first-child)]:-ml-1">
                  {Object.keys(reactions).map((r) => (
                    <div
                      key={r}
                      className="flex h-4 w-4 items-center justify-center overflow-hidden rounded-full border border-white object-cover ring ring-inset ring-white"
                    >
                      <ReactionIcon reaction={r as LinkedInReactionType} />
                    </div>
                  ))}
                </div>
                <span className="block text-xs text-gray-600">
                  {" "}
                  {pluralize(
                    Object.values(reactions).reduce((acc, r) => acc + r, 0),
                    "reaction",
                  )}
                </span>
              </>
            )}
          </div>
          <div className="flex items-center gap-1">
            {Boolean(totalComments) && (
              <span className="block text-xs text-gray-600">
                {" "}
                {pluralize(totalComments, "comment")}
              </span>
            )}
          </div>
        </div>
        <BsPatchCheckFill
          width={40}
          height={40}
          className={`selected-checkmark ${selected ? "" : "hidden"}`}
        />
      </div>
    </div>
  );
};

export default LinkedInTemplate;
