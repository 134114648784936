import React from "react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { Button } from "~/src/primitives/button";
import { Unplug } from "lucide-react";
import { Team } from "~/src/api/team.api";
import Integrations from "~/src/views/Account/components/Integrations";

interface WorkspaceIntegrationsModalProps {
  show: boolean;
  workspace?: Partial<Team>;
  onHide: () => void;
  onUpdate?: () => void | Promise<void>; // Custom logic to be called after an update occurs
}

const WorkspaceIntegrationsModal = ({
  show,
  workspace,
  onHide,
  onUpdate = () => {},
}: WorkspaceIntegrationsModalProps) => (
  <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
    <DialogContent className="flex h-full flex-col overflow-hidden p-0 sm:max-h-[90vh]">
      <DialogHeader>
        <div className="flex flex-row items-center justify-center p-4">
          <div>
            <Unplug className="mr-2 h-4 w-4" />
          </div>
          <div>
            <span className="font-lighter">
              Integrations for{" "}
              <span className="font-bold">{workspace?.name}</span>
            </span>
          </div>
        </div>
      </DialogHeader>

      <Integrations
        workspace={workspace as Team}
        onUpdate={onUpdate}
        className="overflow-y-auto p-4"
      />

      <DialogFooter className="px-4 pb-4">
        <Button variant="outline" size="sm" onClick={onHide}>
          Done
        </Button>
      </DialogFooter>
    </DialogContent>
  </Dialog>
);

export default WorkspaceIntegrationsModal;
