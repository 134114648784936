import axios from "axios";
import {
  Bell,
  GitBranch,
  Brain,
  Calendar,
  CalendarClock,
  Circle,
  DollarSign,
  Download,
  DownloadCloud,
  ExternalLink,
  FacebookIcon,
  Flag,
  Globe,
  Instagram,
  InstagramIcon,
  Link,
  LucideIcon,
  MessageCircle,
  MessagesSquare,
  MonitorSmartphone,
  Newspaper,
  Phone,
  Play,
  PlayCircle,
  ShoppingCart,
  Smartphone,
  UserCheck,
  UserPlus,
  Users,
  Video,
  Zap,
  Eye,
  MousePointer,
  PhoneCall,
  Minus,
  CheckCircle,
  ThumbsUp,
  MessageSquare,
  User,
} from "lucide-react";
import { BsWhatsapp } from "react-icons/bs";
import { z } from "zod";
import { ObjectValues } from "../../../util/reusables";
import { MessageCircleZap } from "../../../views/Paid/utils";
import { MetaAdLabel } from "./adlabels.api";
import { PaidCampaignObjective } from "./campaigns.api";

export const PaidAdsetStatus = {
  ACTIVE: "ACTIVE",
  ARCHIVED: "ARCHIVED",
  DELETED: "DELETED",
  PAUSED: "PAUSED",
} as const;
export type PaidAdsetStatus = ObjectValues<typeof PaidAdsetStatus>;

export const AdsetDestination = {
  APP: "APP",
  APPLINKS_AUTOMATIC: "APPLINKS_AUTOMATIC",
  FACEBOOK: "FACEBOOK",
  INSTAGRAM_DIRECT: "INSTAGRAM_DIRECT",
  INSTAGRAM_PROFILE: "INSTAGRAM_PROFILE",
  MESSAGING_INSTAGRAM_DIRECT_MESSENGER: "MESSAGING_INSTAGRAM_DIRECT_MESSENGER",
  MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP:
    "MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP",
  MESSAGING_INSTAGRAM_DIRECT_WHATSAPP: "MESSAGING_INSTAGRAM_DIRECT_WHATSAPP",
  MESSAGING_MESSENGER_WHATSAPP: "MESSAGING_MESSENGER_WHATSAPP",
  MESSENGER: "MESSENGER",
  ON_AD: "ON_AD",
  ON_EVENT: "ON_EVENT",
  ON_PAGE: "ON_PAGE",
  ON_POST: "ON_POST",
  ON_VIDEO: "ON_VIDEO",
  SHOP_AUTOMATIC: "SHOP_AUTOMATIC",
  WEBSITE: "WEBSITE",
  WHATSAPP: "WHATSAPP",

  // Custom destinations - TODO - THESE MAP TO SOMETHING!
  MESSAGE_DESTINATIONS: "MESSAGE_DESTINATIONS",
  INSTANT_FORMS_AND_MESSENGER: "INSTANT_FORMS_AND_MESSENGER",
  CALLS: "CALLS",
  WEBSITE_AND_APP: "WEBSITE_AND_APP",
  WEBSITE_AND_CALLS: "WEBSITE_AND_CALLS",
} as const;
export type AdsetDestination = ObjectValues<typeof AdsetDestination>;

export const OptGoal = {
  AD_RECALL_LIFT: "AD_RECALL_LIFT",
  APP_INSTALLS: "APP_INSTALLS",
  APP_INSTALLS_AND_OFFSITE_CONVERSIONS: "APP_INSTALLS_AND_OFFSITE_CONVERSIONS",
  CONVERSATIONS: "CONVERSATIONS",
  DERIVED_EVENTS: "DERIVED_EVENTS",
  ENGAGED_USERS: "ENGAGED_USERS",
  EVENT_RESPONSES: "EVENT_RESPONSES",
  IMPRESSIONS: "IMPRESSIONS",
  IN_APP_VALUE: "IN_APP_VALUE",
  LANDING_PAGE_VIEWS: "LANDING_PAGE_VIEWS",
  LEAD_GENERATION: "LEAD_GENERATION",
  LINK_CLICKS: "LINK_CLICKS",
  MEANINGFUL_CALL_ATTEMPT: "MEANINGFUL_CALL_ATTEMPT",
  MESSAGING_APPOINTMENT_CONVERSION: "MESSAGING_APPOINTMENT_CONVERSION",
  MESSAGING_PURCHASE_CONVERSION: "MESSAGING_PURCHASE_CONVERSION",
  NONE: "NONE",
  OFFSITE_CONVERSIONS: "OFFSITE_CONVERSIONS",
  PAGE_LIKES: "PAGE_LIKES",
  POST_ENGAGEMENT: "POST_ENGAGEMENT",
  PROFILE_VISIT: "PROFILE_VISIT",
  QUALITY_CALL: "QUALITY_CALL",
  QUALITY_LEAD: "QUALITY_LEAD",
  REACH: "REACH",
  REMINDERS_SET: "REMINDERS_SET",
  SUBSCRIBERS: "SUBSCRIBERS",
  THRUPLAY: "THRUPLAY",
  VALUE: "VALUE",
  VISIT_INSTAGRAM_PROFILE: "VISIT_INSTAGRAM_PROFILE",

  // Custom Goals - TODO - what does this map to???
  /**
   * Label: Maximize 2-second continuous video plays
   * Description: We'll try to show your video ads to people who are likely to watch 2 continuous seconds or more. Most 2-second continuous video plays will have at least 50% of the video pixels on screen.
   */
  TWO_SECOND: "TWO_SECOND",
} as const;
export type OptGoal = ObjectValues<typeof OptGoal>;

export const IMetaAdset = z.object({
  id: z.string().describe("Facebook's unique identifier for the ad set"),
  name: z.string().max(400).describe("Name of the ad set (max 400 characters)"),
  account_id: z
    .string()
    .describe("Facebook ad account ID that owns this ad set"),
  campaign_id: z
    .string()
    .describe("ID of the Facebook campaign containing this ad set"),
  status: z
    .enum(["ACTIVE", "PAUSED", "DELETED", "ARCHIVED"])
    .describe("Current status of the ad set"),

  // Optional fields
  adlabels: z
    .array(MetaAdLabel)
    .optional()
    .describe("Ad Labels associated with this ad set"),
  attribution_spec: z
    .array(
      z.object({
        event_type: z.string(),
        window_days: z.number(),
      }),
    )
    .optional()
    .describe("Specifies attribution settings for conversion tracking"),
  bid_amount: z
    .number()
    .optional()
    .describe("Bid cap or target cost for this ad set"),
  bid_strategy: z
    .enum([
      "LOWEST_COST_WITHOUT_CAP",
      "LOWEST_COST_WITH_BID_CAP",
      "COST_CAP",
      "LOWEST_COST_WITH_MIN_ROAS",
    ])
    .optional()
    .describe("Bidding strategy used for the ad set"),
  billing_event: z.string().optional().describe("The billing event type"),
  budget_remaining: z
    .string()
    .optional()
    .describe("Remaining budget for the ad set"),
  campaign_active_time: z
    .string()
    .optional()
    .describe("Active running length of the campaign"),
  campaign_attribution: z
    .string()
    .optional()
    .describe("Attribution setting for the campaign"),
  configured_status: z
    .enum(["ACTIVE", "PAUSED", "DELETED", "ARCHIVED"])
    .optional()
    .describe("Status set by the advertiser"),
  created_time: z
    .string()
    .datetime()
    .optional()
    .describe("Timestamp when the ad set was created"),
  daily_budget: z
    .string()
    .optional()
    .describe("Daily spending limit for the ad set"),
  destination_type: z
    .string()
    .optional()
    .describe("Type of destination for the ads"),
  effective_status: z
    .enum([
      "ACTIVE",
      "PAUSED",
      "DELETED",
      "ARCHIVED",
      "IN_PROCESS",
      "WITH_ISSUES",
    ])
    .optional()
    .describe("Current operational status of the ad set"),
  end_time: z
    .string()
    .datetime()
    .optional()
    .describe("Scheduled end time for the ad set"),
  frequency_control_specs: z
    .array(
      z.object({
        event: z.string(),
        interval_days: z.number(),
        max_frequency: z.number(),
      }),
    )
    .optional()
    .describe("Settings to control ad delivery frequency"),
  is_budget_schedule_enabled: z
    .boolean()
    .optional()
    .describe("Indicates if budget scheduling is active for the ad set"),
  is_dynamic_creative: z
    .boolean()
    .optional()
    .describe("Whether dynamic creative optimization is enabled"),
  lifetime_budget: z
    .string()
    .optional()
    .describe("Total budget for the ad set's lifetime"),
  lifetime_imps: z
    .number()
    .optional()
    .describe("Lifetime impression limit for the ad set"),
  multi_optimization_goal_weight: z
    .string()
    .optional()
    .describe("Weighting for multiple optimization goals"),
  optimization_goal: z
    .string()
    .optional()
    .describe("Primary goal for ad delivery optimization"),
  optimization_sub_event: z
    .string()
    .optional()
    .describe("Secondary event for optimization"),
  pacing_type: z
    .array(z.string())
    .optional()
    .describe("Method of distributing the budget over time"),
  promoted_object: z
    .object({
      page_id: z.string().optional(),
      pixel_id: z.string().optional(),
    })
    .optional()
    .describe("Object being advertised (e.g., Facebook Page)"),
  recurring_budget_semantics: z
    .boolean()
    .optional()
    .describe("Indicates if the budget recurs"),
  source_adset_id: z
    .string()
    .optional()
    .describe("ID of the source ad set if this is a copy"),
  start_time: z
    .string()
    .datetime()
    .optional()
    .describe("Scheduled start time for the ad set"),
  targeting: z
    .object({
      age_max: z
        .number()
        .optional()
        .describe("Maximum age. If used, must be 13 or higher."),
      age_min: z
        .number()
        .optional()
        .describe(
          "Minimum age. Defaults to 18. If used, must be 13 or higher.",
        ),
      genders: z
        .array(z.number())
        .optional()
        .describe(
          "Genders to target. 1 targets males, 2 targets females. Defaults to all.",
        ),
      geo_locations: z
        .object({
          countries: z.array(z.any()).optional(),
          regions: z.array(z.any()).optional(),
          cities: z.array(z.any()).optional(),
          zips: z.array(z.any()).optional(),
          places: z.array(z.any()).optional(),
          location_types: z.array(z.any()).optional(),
          country_groups: z.array(z.any()).optional(),
          custom_locations: z.array(z.any()).optional(),
        })
        .optional(),
      excluded_geo_locations: z
        .object({
          countries: z.array(z.string()).optional(),
          regions: z.array(z.object({ key: z.string() })).optional(),
          cities: z
            .array(
              z.object({
                key: z.string(),
                radius: z.number(),
                distance_unit: z.enum(["mile", "kilometer"]),
              }),
            )
            .optional(),
          zips: z.array(z.object({ key: z.string() })).optional(),
        })
        .optional(),
      interests: z.array(z.any()).optional(),
      behaviors: z.array(z.any()).optional(),
      user_device: z.array(z.any()).optional(),
      demographics: z.array(z.any()).optional(),
      education_statuses: z.array(z.any()).optional(),
      education_schools: z.array(z.any()).optional(),
      education_majors: z.array(z.any()).optional(),
      work_employers: z.array(z.any()).optional(),
      work_positions: z.array(z.any()).optional(),
      relationship_statuses: z.array(z.any()).optional(),
      life_events: z.array(z.any()).optional(),
      industries: z.array(z.any()).optional(),
      income: z.array(z.any()).optional(),
      generation: z.array(z.any()).optional(),
      home_ownership: z.array(z.any()).optional(),
      home_type: z.array(z.any()).optional(),
      ethnic_affinity: z.array(z.any()).optional(),
      family_statuses: z.array(z.any()).optional(),
      politics: z.array(z.any()).optional(),
      custom_audiences: z
        .array(
          z.object({
            id: z.string(),
            name: z.string().optional(),
          }),
        )
        .optional(),
      excluded_custom_audiences: z
        .array(
          z.object({
            id: z.string(),
          }),
        )
        .optional(),
      publisher_platforms: z
        .array(
          z.enum(["facebook", "instagram", "audience_network", "messenger"]),
        )
        .optional(),
      device_platforms: z.array(z.enum(["mobile", "desktop"])).optional(),
      targeting_optimization: z.string().optional(),
      targeting_automation: z
        .object({
          advantage_audience: z.number().optional(),
        })
        .optional(),
    })
    .optional()
    .describe("Detailed targeting specifications for the ad set"),
  targeting_optimization_types: z
    .array(
      z.object({
        key: z.string(),
        value: z.number(),
      }),
    )
    .optional()
    .describe("Types of targeting optimizations applied"),
  updated_time: z
    .string()
    .datetime()
    .optional()
    .describe("Timestamp of the last update to the ad set"),
  use_new_app_click: z
    .boolean()
    .optional()
    .describe("Whether to use the new app click metric"),
  isParent: z.boolean().optional().describe("Whether this is a parent ad set"),
  bound: z.array(z.string()).optional().describe("Associated team IDs"),
  children: z.array(z.string()).optional().describe("Children ad set IDs"),
  adsLastSyncTimestamp: z
    .number()
    .optional()
    .describe("Last sync timestamp for ads"),
});

export type IMetaAdset = z.infer<typeof IMetaAdset> & {
  _id?: string;
  hub?: string;
  team?: string;
  parent?: string;
  metaErrors?: any[];
};

export const CreateAdsetSchema = IMetaAdset.pick({
  name: true,
  status: true,
  billing_event: true,
  daily_budget: true,
  lifetime_budget: true,
  start_time: true,
  end_time: true,
  targeting: true,
  optimization_goal: true,
  bid_amount: true,
  bid_strategy: true,
  campaign_id: true,
  destination_type: true,
  promoted_object: true,
  attribution_spec: true,
  pacing_type: true,
  isParent: true,
  bound: true,
  adlabels: true,
}).extend({
  name: z
    .string()
    .min(1, "Ad set name is required")
    .max(400, "Ad set name must be 400 characters or less"),
  daily_budget: z.union([z.string(), z.number()]).optional().transform(String),
  lifetime_budget: z
    .union([z.string(), z.number()])
    .optional()
    .transform(String),
});
export type CreateAdsetData = z.infer<typeof CreateAdsetSchema>;

type DestinationOptimization = {
  optimize: OptGoal[] | Record<string, OptGoal[]>;
};

type CampaignObjectiveOptimizations = {
  [key in AdsetDestination]?: DestinationOptimization;
} & {
  optimize?: OptGoal[];
};

export type CampaignObjectiveOptimizationMapping = {
  [key in PaidCampaignObjective]: CampaignObjectiveOptimizations;
};
/**
 * https://developers.facebook.com/docs/marketing-api/reference/ad-campaign-group#odax-mapping
 */
export const CAMPAIGN_OBJECTIVE_OPTIMIZATION_MAPPING: CampaignObjectiveOptimizationMapping =
  {
    [PaidCampaignObjective.Awareness]: {
      optimize: [
        OptGoal.REACH,
        OptGoal.IMPRESSIONS,
        OptGoal.AD_RECALL_LIFT,
        OptGoal.THRUPLAY,
        OptGoal.TWO_SECOND,
      ],
    },
    [PaidCampaignObjective.Traffic]: {
      [AdsetDestination.WEBSITE]: {
        optimize: [
          OptGoal.LANDING_PAGE_VIEWS,
          OptGoal.LINK_CLICKS,
          OptGoal.REACH,
          // TODO: OptGoal.CONVERSATIONS,
          // TODO: OptGoal.IMPRESSIONS,
        ],
      },
      // 11/12 REMOVING FOR BETA
      // TODO: Get a screenshot and double check these...
      [AdsetDestination.APP]: {
        optimize: [OptGoal.LINK_CLICKS, OptGoal.REACH],
      },
      [AdsetDestination.MESSAGE_DESTINATIONS]: {
        optimize: [
          OptGoal.LINK_CLICKS,
          OptGoal.REACH,
          OptGoal.CONVERSATIONS,
          OptGoal.IMPRESSIONS,
        ],
      },

      [AdsetDestination.INSTAGRAM_PROFILE]: {
        optimize: [OptGoal.PROFILE_VISIT],
      },
      // NOTE: Couldn't find in docs
      [AdsetDestination.CALLS]: {
        optimize: [OptGoal.QUALITY_CALL],
      },
    },
    [PaidCampaignObjective.Engagement]: {
      [AdsetDestination.MESSAGE_DESTINATIONS]: {
        optimize: [OptGoal.CONVERSATIONS, OptGoal.LINK_CLICKS],
      },
      // NOTE: Unsure... not well documented for `OptGoal.TWO_SECOND`
      [AdsetDestination.ON_AD]: {
        optimize: {
          video_views: [OptGoal.THRUPLAY, OptGoal.TWO_SECOND],
          post_engagement: [
            OptGoal.POST_ENGAGEMENT,
            OptGoal.REACH,
            OptGoal.IMPRESSIONS,
          ],
          event_responses: [
            OptGoal.EVENT_RESPONSES,
            OptGoal.POST_ENGAGEMENT,
            OptGoal.REACH,
            OptGoal.IMPRESSIONS,
          ],
          reminders_set: [OptGoal.REMINDERS_SET],
        },
      },
      [AdsetDestination.CALLS]: {
        optimize: [OptGoal.QUALITY_CALL],
      },
      [AdsetDestination.WEBSITE]: {
        optimize: [
          OptGoal.OFFSITE_CONVERSIONS,
          OptGoal.LANDING_PAGE_VIEWS,
          OptGoal.LINK_CLICKS,
          OptGoal.IMPRESSIONS,
          OptGoal.REACH,
        ],
      },
      [AdsetDestination.APP]: {
        optimize: [OptGoal.APP_INSTALLS, OptGoal.LINK_CLICKS, OptGoal.REACH],
      },
      // NOTE: Unsure... not well documented
      // COULD BE ON_PAGE
      [AdsetDestination.FACEBOOK]: {
        optimize: [OptGoal.PAGE_LIKES],
      },
    },
    [PaidCampaignObjective.Leads]: {
      [AdsetDestination.WEBSITE]: {
        optimize: [
          // OptGoal.OFFSITE_CONVERSIONS,
          // OptGoal.VALUE,
          OptGoal.LANDING_PAGE_VIEWS,
          OptGoal.LINK_CLICKS,
          OptGoal.REACH,
          OptGoal.IMPRESSIONS,
        ],
      },
      // NOTE: NOT SEEING THIS OPTION FOR LEADS 10/16
      // [AdsetDestination.WEBSITE_AND_CALLS]: {
      //   optimize: [OptGoal.OFFSITE_CONVERSIONS],
      // },
      // AKA "Instant forms"
      [AdsetDestination.ON_AD]: {
        optimize: [OptGoal.LEAD_GENERATION, OptGoal.QUALITY_LEAD],
      },

      /* CHORE: Reduce scope to Diva Dance critical paths...
      [AdsetDestination.MESSENGER]: {
        optimize: [OptGoal.LEAD_GENERATION],
      },
      [AdsetDestination.INSTANT_FORMS_AND_MESSENGER]: {
        optimize: [OptGoal.LEAD_GENERATION],
      },
      [AdsetDestination.INSTAGRAM_DIRECT]: {
        optimize: [OptGoal.LEAD_GENERATION],
      },
      [AdsetDestination.CALLS]: {
        optimize: [OptGoal.QUALITY_CALL],
      },
      [AdsetDestination.APP]: {
        optimize: [
          OptGoal.ENGAGED_USERS,
          OptGoal.APP_INSTALLS,
          OptGoal.LINK_CLICKS,
        ],
      },
      */
    },
    [PaidCampaignObjective.AppPromotion]: {
      [AdsetDestination.APP]: {
        optimize: [
          OptGoal.IN_APP_VALUE,
          OptGoal.APP_INSTALLS,
          OptGoal.VALUE,
          OptGoal.LINK_CLICKS,
        ],
      },
    },
    [PaidCampaignObjective.Sales]: {
      [AdsetDestination.WEBSITE]: {
        optimize: [
          OptGoal.OFFSITE_CONVERSIONS,
          OptGoal.VALUE,
          OptGoal.LANDING_PAGE_VIEWS,
          OptGoal.LINK_CLICKS,
          OptGoal.REACH,
          OptGoal.IMPRESSIONS,
        ],
      },
      [AdsetDestination.APP]: {
        optimize: [OptGoal.ENGAGED_USERS, OptGoal.LINK_CLICKS, OptGoal.REACH],
      },
      [AdsetDestination.WEBSITE_AND_APP]: {
        optimize: [OptGoal.OFFSITE_CONVERSIONS],
      },
      [AdsetDestination.MESSAGE_DESTINATIONS]: {
        optimize: [
          OptGoal.CONVERSATIONS,
          OptGoal.OFFSITE_CONVERSIONS,
          OptGoal.LINK_CLICKS,
          OptGoal.REACH,
          OptGoal.IMPRESSIONS,
        ],
      },
      // TODO: Is this right??? - "Maximize number of calls"
      [AdsetDestination.CALLS]: {
        optimize: [OptGoal.QUALITY_CALL],
      },
    },
  };

export const AdsetDestinationInfo: Record<
  AdsetDestination,
  {
    disabled?: boolean;
    label: string;
    icon: LucideIcon;
    description: (objective: PaidCampaignObjective) => string;
  }
> = {
  [AdsetDestination.WEBSITE]: {
    label: "Website",
    icon: Link,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Sales:
          return "Drive sales and conversions through your website.";
        case PaidCampaignObjective.Leads:
          return "Generate leads through your website.";
        case PaidCampaignObjective.Traffic:
          return "Drive traffic to your website.";
        case PaidCampaignObjective.Engagement:
          return "Increase engagement on your website.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness through your website.";
        case PaidCampaignObjective.AppPromotion:
          return "Promote your app through your website.";
        default:
          return "Promote your website.";
      }
    },
  },
  [AdsetDestination.MESSENGER]: {
    label: "Messenger",
    disabled: true,
    icon: MessageCircle,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Leads:
          return "Generate leads by starting chats in Messenger.";
        case PaidCampaignObjective.Engagement:
          return "Increase engagement through Messenger chats.";
        case PaidCampaignObjective.Sales:
          return "Drive sales and conversions through Messenger chats.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness through Messenger conversations.";
        case PaidCampaignObjective.Traffic:
          return "Drive traffic to Messenger conversations.";
        case PaidCampaignObjective.AppPromotion:
          return "Promote your app through Messenger conversations.";
        default:
          return "Connect with people through Messenger.";
      }
    },
  },
  [AdsetDestination.INSTANT_FORMS_AND_MESSENGER]: {
    label: "Instant forms and Messenger",
    disabled: true,
    icon: MessageCircleZap,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Leads:
          return "Generate leads through forms or Messenger chats.";
        case PaidCampaignObjective.Sales:
          return "Drive sales through forms or Messenger chats.";
        case PaidCampaignObjective.Engagement:
          return "Increase engagement through forms or Messenger chats.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness through forms or Messenger chats.";
        case PaidCampaignObjective.Traffic:
          return "Drive traffic to forms or Messenger chats.";
        case PaidCampaignObjective.AppPromotion:
          return "Promote your app through forms or Messenger chats.";
        default:
          return "Collect information and connect through forms and Messenger.";
      }
    },
  },
  [AdsetDestination.INSTAGRAM_DIRECT]: {
    label: "Instagram",
    disabled: true,
    icon: InstagramIcon,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Leads:
          return "Generate leads by starting chats on Instagram.";
        case PaidCampaignObjective.Engagement:
          return "Increase engagement through Instagram Direct messages.";
        case PaidCampaignObjective.Sales:
          return "Drive sales through Instagram Direct messages.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness through Instagram Direct messages.";
        case PaidCampaignObjective.Traffic:
          return "Drive traffic to Instagram Direct conversations.";
        case PaidCampaignObjective.AppPromotion:
          return "Promote your app through Instagram Direct messages.";
        default:
          return "Connect with people through Instagram Direct.";
      }
    },
  },
  [AdsetDestination.CALLS]: {
    label: "Calls",
    disabled: true,
    icon: Phone,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Sales:
          return "Drive sales and conversions through phone calls.";
        case PaidCampaignObjective.Leads:
          return "Generate leads by asking people to call your business.";
        case PaidCampaignObjective.Engagement:
          return "Increase engagement through phone calls.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness through phone calls.";
        case PaidCampaignObjective.Traffic:
          return "Drive traffic to your phone lines.";
        case PaidCampaignObjective.AppPromotion:
          return "Promote your app through phone calls.";
        default:
          return "Encourage people to call your business.";
      }
    },
  },
  [AdsetDestination.APP]: {
    label: "App",
    disabled: true,
    icon: Smartphone,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Sales:
          return "Drive sales and conversions through your app.";
        case PaidCampaignObjective.AppPromotion:
          return "Promote and drive installs for your app.";
        case PaidCampaignObjective.Leads:
          return "Generate leads through your app.";
        case PaidCampaignObjective.Engagement:
          return "Increase engagement with your app.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness of your app.";
        case PaidCampaignObjective.Traffic:
          return "Drive traffic to your app.";
        default:
          return "Promote your app.";
      }
    },
  },
  [AdsetDestination.ON_AD]: {
    label: "Instant forms",
    icon: Zap,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Leads:
          return "Generate leads by asking people to fill out a form.";
        case PaidCampaignObjective.Sales:
          return "Collect information to drive sales through instant forms.";
        case PaidCampaignObjective.Engagement:
          return "Increase engagement through instant forms.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness by collecting information through instant forms.";
        case PaidCampaignObjective.Traffic:
        case PaidCampaignObjective.AppPromotion:
        default:
          return "Collect information through instant forms.";
      }
    },
  },
  [AdsetDestination.FACEBOOK]: {
    label: "Facebook",
    disabled: true,
    icon: FacebookIcon,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Leads:
          return "Generate leads through Facebook.";
        case PaidCampaignObjective.Engagement:
          return "Increase engagement on Facebook.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness on Facebook.";
        case PaidCampaignObjective.Sales:
          return "Drive sales through Facebook.";
        case PaidCampaignObjective.Traffic:
          return "Drive traffic on Facebook.";
        case PaidCampaignObjective.AppPromotion:
          return "Promote your app on Facebook.";
        default:
          return "Promote your presence on Facebook.";
      }
    },
  },
  [AdsetDestination.WHATSAPP]: {
    label: "WhatsApp",
    disabled: true,
    icon: BsWhatsapp as LucideIcon,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Leads:
          return "Generate leads through WhatsApp.";
        case PaidCampaignObjective.Engagement:
          return "Increase engagement through WhatsApp.";
        case PaidCampaignObjective.Sales:
          return "Drive sales through WhatsApp conversations.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness through WhatsApp.";
        case PaidCampaignObjective.Traffic:
          return "Drive traffic to WhatsApp conversations.";
        case PaidCampaignObjective.AppPromotion:
          return "Promote your app through WhatsApp.";
        default:
          return "Connect with people through WhatsApp.";
      }
    },
  },
  [AdsetDestination.WEBSITE_AND_APP]: {
    label: "Website and App",
    disabled: true,
    icon: MonitorSmartphone,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Sales:
          return "Drive sales and conversions on your website or app.";
        case PaidCampaignObjective.Leads:
          return "Generate leads through your website or app.";
        case PaidCampaignObjective.Traffic:
          return "Drive traffic to your website or app.";
        case PaidCampaignObjective.Engagement:
          return "Increase engagement on your website or app.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness through your website or app.";
        case PaidCampaignObjective.AppPromotion:
          return "Promote your app and drive installs.";
        default:
          return "Promote your website and app.";
      }
    },
  },
  [AdsetDestination.MESSAGE_DESTINATIONS]: {
    label: "Message Destinations",
    disabled: true,
    icon: MessagesSquare,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Sales:
          return "Drive sales and conversions through Messenger, Instagram Direct, and WhatsApp.";
        case PaidCampaignObjective.Leads:
          return "Generate leads through Messenger, Instagram Direct, and WhatsApp.";
        case PaidCampaignObjective.Engagement:
          return "Increase engagement through Messenger, Instagram Direct, and WhatsApp.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness through Messenger, Instagram Direct, and WhatsApp.";
        case PaidCampaignObjective.Traffic:
          return "Drive traffic to conversations on Messenger, Instagram Direct, and WhatsApp.";
        case PaidCampaignObjective.AppPromotion:
          return "Promote your app through Messenger, Instagram Direct, and WhatsApp.";
        default:
          return "Connect with people through Messenger, Instagram Direct, and WhatsApp.";
      }
    },
  },
  [AdsetDestination.APPLINKS_AUTOMATIC]: {
    label: "App Links Automatic",
    disabled: true,
    icon: ExternalLink,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.AppPromotion:
          return "Automatically direct users to your app or website to promote app installs.";
        case PaidCampaignObjective.Sales:
          return "Automatically direct users to your app or website to drive sales.";
        case PaidCampaignObjective.Leads:
          return "Automatically direct users to your app or website to generate leads.";
        case PaidCampaignObjective.Engagement:
          return "Automatically direct users to your app or website to increase engagement.";
        case PaidCampaignObjective.Awareness:
          return "Automatically direct users to your app or website to increase awareness.";
        case PaidCampaignObjective.Traffic:
          return "Automatically direct users to your app or website to drive traffic.";
        default:
          return "Automatically direct users to your app or website based on whether they have your app installed.";
      }
    },
  },
  [AdsetDestination.INSTAGRAM_PROFILE]: {
    label: "Instagram Profile",
    disabled: true,
    icon: InstagramIcon,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Engagement:
          return "Increase engagement on your Instagram profile.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness of your Instagram profile.";
        case PaidCampaignObjective.Sales:
          return "Drive sales through your Instagram profile.";
        case PaidCampaignObjective.Leads:
          return "Generate leads through your Instagram profile.";
        case PaidCampaignObjective.Traffic:
          return "Drive traffic to your Instagram profile.";
        case PaidCampaignObjective.AppPromotion:
          return "Promote your app through your Instagram profile.";
        default:
          return "Promote your Instagram profile.";
      }
    },
  },
  [AdsetDestination.MESSAGING_INSTAGRAM_DIRECT_MESSENGER]: {
    label: "Instagram Direct and Messenger",
    disabled: true,
    icon: InstagramIcon,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Leads:
          return "Generate leads through Instagram Direct messages and Facebook Messenger.";
        case PaidCampaignObjective.Sales:
          return "Drive sales through Instagram Direct messages and Facebook Messenger.";
        case PaidCampaignObjective.Engagement:
          return "Increase engagement through Instagram Direct messages and Facebook Messenger.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness through Instagram Direct messages and Facebook Messenger.";
        case PaidCampaignObjective.Traffic:
          return "Drive traffic to conversations on Instagram Direct and Facebook Messenger.";
        case PaidCampaignObjective.AppPromotion:
          return "Promote your app through Instagram Direct messages and Facebook Messenger.";
        default:
          return "Connect with people through Instagram Direct messages and Facebook Messenger.";
      }
    },
  },
  [AdsetDestination.MESSAGING_INSTAGRAM_DIRECT_MESSENGER_WHATSAPP]: {
    label: "Instagram Direct, Messenger, and WhatsApp",
    disabled: true,
    icon: MessagesSquare,
    description: (objective: PaidCampaignObjective) => {
      switch (objective) {
        case PaidCampaignObjective.Leads:
          return "Generate leads through Instagram Direct, Facebook Messenger, and WhatsApp.";
        case PaidCampaignObjective.Sales:
          return "Drive sales through Instagram Direct, Facebook Messenger, and WhatsApp.";
        case PaidCampaignObjective.Engagement:
          return "Increase engagement through Instagram Direct, Facebook Messenger, and WhatsApp.";
        case PaidCampaignObjective.Awareness:
          return "Increase awareness through Instagram Direct, Facebook Messenger, and WhatsApp.";
        case PaidCampaignObjective.Traffic:
          return "Drive traffic to conversations on Instagram Direct, Facebook Messenger, and WhatsApp.";
        case PaidCampaignObjective.AppPromotion:
          return "Promote your app through Instagram Direct, Facebook Messenger, and WhatsApp.";
        default:
          return "Connect with people through Instagram Direct messages, Facebook Messenger, and WhatsApp.";
      }
    },
  },
  [AdsetDestination.MESSAGING_INSTAGRAM_DIRECT_WHATSAPP]: {
    label: "Instagram Direct and WhatsApp",
    disabled: true,
    icon: MessagesSquare,
    description: () =>
      "Connect with people through Instagram Direct messages and WhatsApp.",
  },
  [AdsetDestination.MESSAGING_MESSENGER_WHATSAPP]: {
    label: "Messenger and WhatsApp",
    disabled: true,
    icon: MessagesSquare,
    description: () =>
      "Connect with people through Facebook Messenger and WhatsApp.",
  },
  [AdsetDestination.ON_EVENT]: {
    label: "On Event",
    disabled: true,
    icon: CalendarClock,
    description: (objective: PaidCampaignObjective) =>
      objective === PaidCampaignObjective.Engagement
        ? "Increase engagement for your event."
        : objective === PaidCampaignObjective.Awareness
          ? "Increase awareness of your event."
          : "Promote your event.",
  },
  [AdsetDestination.ON_PAGE]: {
    label: "On Page",
    disabled: true,
    icon: Flag,
    description: (objective: PaidCampaignObjective) =>
      objective === PaidCampaignObjective.Engagement
        ? "Increase engagement on your Facebook Page."
        : objective === PaidCampaignObjective.Awareness
          ? "Increase awareness of your Facebook Page."
          : "Promote your Facebook Page.",
  },
  [AdsetDestination.ON_POST]: {
    label: "On Post",
    disabled: true,
    icon: Newspaper,
    description: (objective: PaidCampaignObjective) =>
      objective === PaidCampaignObjective.Engagement
        ? "Increase engagement on your specific post."
        : objective === PaidCampaignObjective.Awareness
          ? "Increase awareness of your specific post."
          : "Promote a specific post.",
  },
  [AdsetDestination.ON_VIDEO]: {
    label: "On Video",
    disabled: true,
    icon: Video,
    description: (objective: PaidCampaignObjective) =>
      objective === PaidCampaignObjective.Engagement
        ? "Increase engagement on your video."
        : objective === PaidCampaignObjective.Awareness
          ? "Increase awareness of your video."
          : "Promote your video.",
  },
  [AdsetDestination.SHOP_AUTOMATIC]: {
    label: "Shop Automatic",
    disabled: true,
    icon: ShoppingCart,
    description: (objective: PaidCampaignObjective) =>
      objective === PaidCampaignObjective.Sales
        ? "Automatically promote products from your shop to drive sales."
        : objective === PaidCampaignObjective.Traffic
          ? "Automatically promote products from your shop to drive traffic."
          : "Automatically promote products from your shop.",
  },
  [AdsetDestination.WEBSITE_AND_CALLS]: {
    label: "Website and calls",
    disabled: true,
    icon: MonitorSmartphone,
    description: () => "Generate leads on both your website and through calls.",
  },
};

export const getAdsetDestinationInfo = (
  objective: PaidCampaignObjective,
  destination: AdsetDestination,
) => {
  const info = AdsetDestinationInfo[destination];
  return info
    ? {
        label: info.label,
        description:
          typeof info.description === "function"
            ? info.description(objective)
            : info.description,
        icon: info.icon ?? Circle,
        disabled: info.disabled,
      }
    : {
        label: destination,
        description: (() => {
          switch (objective) {
            case PaidCampaignObjective.Sales:
              return "Drive sales and conversions through this destination.";
            case PaidCampaignObjective.Leads:
              return "Generate leads through this destination.";
            case PaidCampaignObjective.Awareness:
              return "Increase awareness through this destination.";
            case PaidCampaignObjective.Traffic:
              return "Drive traffic to this destination.";
            case PaidCampaignObjective.Engagement:
              return "Increase engagement through this destination.";
            case PaidCampaignObjective.AppPromotion:
              return "Promote your app through this destination.";
            default:
              return "Achieve your campaign objective through this destination.";
          }
        })(),
        icon: Circle,
        disabled: true,
      };
};

export const getDestinationsForObjective = (
  objective: PaidCampaignObjective,
): Array<{
  destination: AdsetDestination;
  info: ReturnType<typeof getAdsetDestinationInfo>;
  optimize?: Array<{
    goal: OptGoal;
    info: ReturnType<typeof getOptGoalInfo>;
  }>;
}> => {
  const objectiveMapping = CAMPAIGN_OBJECTIVE_OPTIMIZATION_MAPPING[objective];

  if (!objectiveMapping) {
    return [];
  }

  const destinations: Array<{
    destination: AdsetDestination | null;
    optimize?: OptGoal[];
  }> = [];

  Object.entries(objectiveMapping).forEach(([key, value]) => {
    if (key === "optimize") {
      // Handle global optimize goals
      destinations.push({
        destination: null,
        optimize: value as OptGoal[],
      });
    } else {
      const destination = key as AdsetDestination;
      const optimizeGoals = (value as DestinationOptimization)?.optimize;

      if (Array.isArray(optimizeGoals)) {
        destinations.push({
          destination,
          optimize: optimizeGoals,
        });
      }
    }
  });

  return destinations.map((item) => ({
    destination: item.destination,
    info: getAdsetDestinationInfo(objective, item.destination),
    optimize: item.optimize?.map((goal) => ({
      goal,
      info: getOptGoalInfo(objective, item.destination, goal),
    })),
  }));
};

export const getOptGoalInfo = (
  objective: PaidCampaignObjective,
  destination: AdsetDestination,
  goal: OptGoal,
) => {
  const goalInfo: Record<
    OptGoal,
    {
      purpose: string;
      description: string;
      icon: LucideIcon;
      fields?: Array<{
        id: string;
        options?: string[];
        required: boolean;
        readonly?: boolean;
        value?: any;
        hidden?: boolean;
        description?: string;
      }>;
    }
  > = {
    [OptGoal.AD_RECALL_LIFT]: {
      purpose: "Maximize ad recall lift",
      description:
        "We'll try to show your ads to people most likely to remember them, increasing brand awareness.",
      icon: Brain,
      fields: [
        {
          id: "promoted_object.page_id",
          required: true,
          hidden: objective !== PaidCampaignObjective.Awareness,
          description: "Choose the page you want to promote.",
        },
        {
          id: "bid_strategy",
          required: true,
          readonly: true,
          value: "LOWEST_COST_WITHOUT_CAP",
          description:
            "Meta will aim to spend your enitre budget and get the most ad recall using the highest volume bid strategy. This is the only bid strategy available for this campaign.",
        },
      ],
    },
    [OptGoal.APP_INSTALLS]: {
      purpose: "Maximize number of app installs",
      description:
        "We'll try to show your ads to people most likely to install your app.",
      icon: Download,
      fields: [
        {
          id: "cost_per_result_goal",
          required: false,
          description:
            "Meta will aim to spend your entire budget and get the most landing page views using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.",
        },
      ],
    },
    [OptGoal.APP_INSTALLS_AND_OFFSITE_CONVERSIONS]: {
      purpose: "Maximize app installs and offsite conversions",
      description:
        "We'll try to show your ads to people likely to install your app or convert on your website.",
      icon: DownloadCloud,
    },
    [OptGoal.CONVERSATIONS]: {
      purpose: "Maximize number of conversations",
      description:
        "We'll try to show your ads to people most likely to start a conversation with your business.",
      icon: MessageCircle,
      fields: [
        {
          id: "promoted_object.page_id",
          required: true,
          hidden: destination !== AdsetDestination.MESSAGE_DESTINATIONS,
          description: "Choose a Page for this ad set.",
        },
        {
          id: "messaging_apps",
          options: [
            AdsetDestination.MESSENGER,
            AdsetDestination.INSTAGRAM_DIRECT,
            AdsetDestination.WHATSAPP,
          ],
          required: true,
          hidden: destination !== AdsetDestination.MESSAGE_DESTINATIONS,
          description: "Send people to Messenger, Instagram and WhatsApp.",
        },
        {
          id: "bid_control",
          required: false,
          description:
            "Meta will aim to spend your entire budget and get the most results using the highest volume bid strategy.",
        },
      ],
    },
    [OptGoal.DERIVED_EVENTS]: {
      purpose: "Maximize derived events",
      description:
        "We'll try to optimize for events derived from other actions, tailored to your specific goals.",
      icon: GitBranch,
    },
    [OptGoal.ENGAGED_USERS]: {
      purpose: "Maximize number of engaged users",
      description:
        "We'll try to show your ads to people most likely to use and engage with your app.",
      icon: Users,
    },
    [OptGoal.EVENT_RESPONSES]: {
      purpose: "Maximize number of event responses",
      description:
        "We'll try to show your event ads to people most likely to respond to them.",
      icon: Calendar,
      fields: [
        {
          id: "cost_per_result_goal",
          required: false,
          description:
            "Meta will aim to spend your entire budget and get the most event responses using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.",
        },
      ],
    },
    [OptGoal.IMPRESSIONS]: {
      purpose: "Maximize number of impressions",
      description:
        "We'll try to show your ads as many times as possible to people in your target audience.",
      icon: Eye,
      fields: [
        {
          id: "promoted_object.page_id",
          required: true,
          hidden:
            objective !== PaidCampaignObjective.Awareness &&
            destination !== AdsetDestination.MESSAGE_DESTINATIONS,
          description:
            objective === PaidCampaignObjective.Awareness
              ? "Choose the page you want to promote."
              : "Choose a Page for this ad set.",
        },
        {
          id: "messaging_apps",
          options: [
            AdsetDestination.MESSENGER,
            AdsetDestination.INSTAGRAM_DIRECT,
            AdsetDestination.WHATSAPP,
          ],
          required: true,
          hidden: destination !== AdsetDestination.MESSAGE_DESTINATIONS,
          description: "Send people to Messenger, Instagram and WhatsApp.",
        },
        {
          id: "bid_control",
          required: false,
          description:
            "Meta will aim to spend your entire budget and get the most 1,000 impressions using the highest volume bid strategy.",
        },
      ],
    },
    // TODO: Make sure this mapping is right
    [OptGoal.IN_APP_VALUE]: {
      purpose: "Maximize number of app events",
      description:
        "We'll try to show your ads to people most likely to take a specific action in your app at least once.",
      icon: Smartphone,
      fields: [
        {
          id: "cost_per_result_goal",
          required: false,
          description:
            "Meta will aim to spend your entire budget and get the most landing page views using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.",
        },
      ],
    },
    [OptGoal.LANDING_PAGE_VIEWS]: {
      purpose: "Maximize number of landing page views",
      description:
        "We'll try to show your ads to people most likely to view the website or Instant Experience linked in your ad.",
      icon: ExternalLink,
      fields: [
        {
          id: "cost_per_result_goal",
          required: false,
          description:
            "Meta will aim to spend your entire budget and get the most landing page views using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.",
        },
      ],
    },
    [OptGoal.LEAD_GENERATION]: {
      purpose: "Maximize number of leads",
      description:
        "We'll try to show your ads to people most likely to share their contact information with you.",
      icon: UserPlus,
      fields: [
        {
          id: "promoted_object.page_id",
          required: true,
          hidden:
            destination !== AdsetDestination.MESSENGER &&
            destination !== AdsetDestination.INSTANT_FORMS_AND_MESSENGER &&
            destination !== AdsetDestination.ON_AD,
          description: "Choose a Page for this ad set.",
        },
        {
          id: "instagram_profile",
          required: true,
          readonly: true,
          hidden: destination !== AdsetDestination.INSTAGRAM_PROFILE,
        },
        {
          id: "cost_per_result_goal",
          required: false,
          hidden: destination === AdsetDestination.MESSENGER,
          description:
            "Meta will aim to spend your entire budget and get the most leads using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.",
        },
        {
          id: "bid_control",
          required: false,
          hidden: destination !== AdsetDestination.MESSENGER,
          description:
            "Meta will aim to spend your entire budget and get the most leads using the highest volume bid strategy.",
        },
      ],
    },
    [OptGoal.LINK_CLICKS]: {
      purpose: "Maximize number of link clicks",
      description:
        "We'll try to show your ads to the people most likely to click on them.",
      icon: MousePointer,
      fields: [
        {
          id: "promoted_object.page_id",
          required: true,
          hidden: destination !== AdsetDestination.MESSAGE_DESTINATIONS,
          description: "Choose a Page for this ad set.",
        },
        {
          id: "messaging_apps",
          options: [
            AdsetDestination.MESSENGER,
            AdsetDestination.INSTAGRAM_DIRECT,
            AdsetDestination.WHATSAPP,
          ],
          required: true,
          hidden: destination !== AdsetDestination.MESSAGE_DESTINATIONS,
          description: "Send people to Messenger, Instagram and WhatsApp.",
        },
        {
          id: "cost_per_result_goal",
          required: false,
          description:
            "Meta will aim to spend your entire budget and get the most link clicks using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.",
        },
      ],
    },
    [OptGoal.MEANINGFUL_CALL_ATTEMPT]: {
      purpose: "Maximize meaningful call attempts",
      description:
        "We'll try to show your ads to people most likely to make a significant call attempt to your business.",
      icon: PhoneCall,
    },
    [OptGoal.MESSAGING_APPOINTMENT_CONVERSION]: {
      purpose: "Maximize messaging appointment conversions",
      description:
        "We'll try to show your ads to people most likely to book an appointment through messaging.",
      icon: Calendar,
    },
    [OptGoal.MESSAGING_PURCHASE_CONVERSION]: {
      purpose: "Maximize messaging purchase conversions",
      description:
        "We'll try to show your ads to people most likely to make a purchase through messaging.",
      icon: ShoppingCart,
    },
    [OptGoal.NONE]: {
      purpose: "No specific optimization",
      description:
        "We'll try to show your ads without optimizing for a specific goal.",
      icon: Minus,
    },
    [OptGoal.OFFSITE_CONVERSIONS]: {
      purpose: "Maximize number of conversions",
      description:
        "We'll try to show your ads to people most likely to take a specific action on your website or app.",
      icon: CheckCircle,
      fields: [
        {
          id: "pixel",
          required: true,
          hidden:
            objective === PaidCampaignObjective.Leads &&
            destination === AdsetDestination.ON_AD,
          description:
            "The Meta Pixel is required. To publish, set up your pixel or switch to a different optimization, such as landing page views.",
        },
        {
          id: "cost_per_result_goal",
          required: false,
          hidden:
            objective === PaidCampaignObjective.Leads &&
            destination === AdsetDestination.ON_AD,
          description:
            "Meta will aim to spend your entire budget and get the most conversions using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.",
        },
      ],
    },
    [OptGoal.PAGE_LIKES]: {
      purpose: "Maximize number of page likes",
      description:
        "We'll try to deliver your ads to the right people to help you get more Page likes at the lowest cost.",
      icon: ThumbsUp,
      fields: [
        {
          id: "promoted_object.page_id",
          required: true,
          description: "Choose a Page for this ad set.",
        },
        {
          id: "cost_per_result_goal",
          required: false,
          description:
            "Meta will aim to spend your entire budget and get the most Page likes using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.",
        },
      ],
    },
    [OptGoal.POST_ENGAGEMENT]: {
      purpose: "Maximize engagement with a post",
      description:
        "We'll try to show your ads to people most likely to like, comment, share, or interact with your post.",
      icon: MessageSquare,
      fields: [
        {
          id: "cost_per_result_goal",
          required: false,
          description:
            "Meta will aim to spend your entire budget and get the most post engagements using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.",
        },
      ],
    },
    [OptGoal.PROFILE_VISIT]: {
      purpose: "Maximize profile visits",
      description:
        "We'll try to show your ads to people most likely to visit your profile or page.",
      icon: User,
    },
    [OptGoal.QUALITY_CALL]: {
      purpose: "Maximize number of calls",
      description:
        "We'll try to show your ads to people most likely to call your business.",
      icon: Phone,
      fields: [
        {
          id: "promoted_object.page_id",
          required: true,
          hidden:
            ![
              PaidCampaignObjective.Awareness,
              PaidCampaignObjective.Engagement,
            ].includes(objective as any) &&
            destination !== AdsetDestination.MESSAGE_DESTINATIONS &&
            destination !== AdsetDestination.CALLS,
          description:
            objective === PaidCampaignObjective.Awareness
              ? "Choose the page you want to promote."
              : "Choose a Page for this ad set.",
        },
        {
          id: "bid_strategy",
          required: true,
          readonly: true,
          value: "LOWEST_COST_WITHOUT_CAP",
          description:
            "Meta will aim to spend your entire budget and get the most results using the highest volume bid strategy. This is the only bid strategy available for this campaign.",
        },
      ],
    },
    [OptGoal.QUALITY_LEAD]: {
      purpose: "Maximize number of conversion leads",
      description:
        "We'll try to show your ads to the people most likely to convert after sharing their contact information with you.",
      icon: UserCheck,
      fields: [
        {
          id: "promoted_object.page_id",
          required: true,
          description: "Choose the page you want to promote.",
        },
        // TODO: Implement CRM
        // { id: "CRM_integration", required: true, hidden: objective !== PaidCampaignObjective.Leads || destination !== AdsetDestination.ON_AD, description: "Deliver your ads to people who are most likely to convert by integrating your CRM with the conversion leads optimization." },
        {
          id: "bid_strategy",
          required: true,
          readonly: true,
          hidden:
            objective !== PaidCampaignObjective.Leads ||
            destination !== AdsetDestination.ON_AD,
          value: "LOWEST_COST_WITHOUT_CAP",
          description:
            "Meta will aim to spend your enitre budget and get the most results using the highest volume bid strategy. This is the only bid strategy available for this campaign.",
        },
      ],
    },
    [OptGoal.REACH]: {
      purpose: [
        PaidCampaignObjective.Engagement,
        PaidCampaignObjective.Leads,
        PaidCampaignObjective.Sales,
      ].includes(objective as any)
        ? "Maximize daily unique reach"
        : "Maximize reach of ads",
      description: [
        PaidCampaignObjective.Engagement,
        PaidCampaignObjective.Leads,
        PaidCampaignObjective.Sales,
      ].includes(objective as any)
        ? "We'll try to show your ads to people up to once per day."
        : "We'll try to show your ads to the largest number of people within your target audience.",
      icon: Globe,
      fields: [
        {
          id: "promoted_object.page_id",
          required: true,
          hidden:
            objective !== PaidCampaignObjective.Awareness &&
            destination !== AdsetDestination.MESSAGE_DESTINATIONS,
          description:
            objective === PaidCampaignObjective.Awareness
              ? "Choose the page you want to promote."
              : "Choose a Page for this ad set.",
        },
        {
          id: "messaging_apps",
          options: [
            AdsetDestination.MESSENGER,
            AdsetDestination.INSTAGRAM_DIRECT,
            AdsetDestination.WHATSAPP,
          ],
          required: true,
          hidden: destination !== AdsetDestination.MESSAGE_DESTINATIONS,
          description: "Send people to Messenger, Instagram and WhatsApp.",
        },
        {
          id: "cost_per_result_goal",
          required: false,
          description:
            "Meta will aim to spend your entire budget and get the most 1,000 impressions using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.",
        },
      ],
    },
    [OptGoal.REMINDERS_SET]: {
      purpose: "Maximize reminders set",
      description:
        "We'll try to show your event ads to people most likely to set a reminder for your event.",
      icon: Bell,
      fields: [],
    },
    [OptGoal.SUBSCRIBERS]: {
      purpose: "Maximize subscribers",
      description:
        "We'll try to show your ads to people most likely to subscribe to your content or service.",
      icon: UserPlus,
    },
    [OptGoal.THRUPLAY]: {
      purpose: "Maximize ThruPlay views",
      description:
        "We'll try to show your video ads to people who will watch the entire video when it's shorter than 15 seconds. For longer videos, we'll try to show it to people who are likely to watch at least 15 seconds.",
      icon: Play,
      fields: [
        {
          id: "promoted_object.page_id",
          required: true,
          hidden: objective !== PaidCampaignObjective.Awareness,
          description: "Choose the page you want to promote.",
        },
        {
          id: "cost_per_result_goal",
          required: false,
          description:
            "Meta will aim to spend your entire budget and get the most ThruPlays using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.",
        },
      ],
    },
    [OptGoal.TWO_SECOND]: {
      purpose: "Maximize 2-second continuous video views",
      description:
        "We'll try to show your video ads to people who are likely to watch 2 continuous seconds or more. Most 2-second continuous video plays will have at least 50% of the video pixels on screen.",
      icon: PlayCircle,
      fields: [
        {
          id: "promoted_object.page_id",
          required: true,
          hidden: objective !== PaidCampaignObjective.Awareness,
          description: "Choose the page you want to promote.",
        },
        {
          id: "cost_per_result_goal",
          required: false,
          description:
            "Meta will aim to spend your entire budget and get the most video plays (2-second continuous) using the highest volume bid strategy. If keeping the average cost per result around a certain amount is important, enter a cost per result goal.",
        },
      ],
    },
    [OptGoal.VALUE]: {
      purpose: "Maximize value of conversions",
      description:
        "We'll try to show your ads to the people most likely to make higher value purchases.",
      icon: DollarSign,
      fields: [], // TODO: this option was grayed out on FigJam examples...
    },
    [OptGoal.VISIT_INSTAGRAM_PROFILE]: {
      purpose: "Maximize number of Instagram profile visits",
      description:
        "We'll try to show your ads to people most likely to visit your Instagram profile.",
      icon: Instagram,
      fields: [
        {
          id: "bid_control",
          required: false,
          description:
            "Meta will aim to spend your entire budget and get the most 1,000 impressions using the highest volume bid strategy.",
        },
      ],
    },
  };

  return goalInfo?.[goal];
};

export const createPaidAdset = async (
  adsetData: CreateAdsetData,
): Promise<{
  adset: IMetaAdset;
  successCount?: number;
  errorCount?: number;
}> => {
  const response = await axios.post<{
    adset: IMetaAdset;
    successCount?: number;
    errorCount?: number;
  }>(`/api/paid/adsets`, adsetData);
  return response.data;
};

export const convertAdStructureToDynamic = async (
  _id: string,
): Promise<void> => {
  const response = await axios.post<void>(
    `/api/paid/adsets/${_id}/convert-to-dynamic`,
  );
  return response.data;
};

export const retryPaidAdset = async (
  _id: string,
): Promise<{
  adset: IMetaAdset;
  successCount?: number;
  errorCount?: number;
}> => {
  const response = await axios.post<{
    adset: IMetaAdset;
    successCount?: number;
    errorCount?: number;
  }>(`/api/paid/adsets/${_id}/retry`);
  return response.data;
};

export const updatePaidAdsetStatus = async (
  adsetId: string,
  on: boolean,
  shouldReturnParent?: boolean,
): Promise<IMetaAdset> => {
  const response = await axios.patch<IMetaAdset>(
    `/api/paid/adsets/${adsetId}/status`,
    { on, shouldReturnParent },
  );
  return response.data;
};

export const updatePaidAdset = async (
  adsetId: string,
  updateData: Partial<CreateAdsetData>,
): Promise<IMetaAdset> => {
  const response = await axios.patch<IMetaAdset>(
    `/api/paid/adsets/${adsetId}`,
    updateData,
  );
  return response.data;
};

export const getPaidAdset = async (adsetId: string): Promise<IMetaAdset> => {
  const response = await axios.get<IMetaAdset>(`/api/paid/adsets/${adsetId}`);
  return response.data;
};

export const listPaidAdsetsForCampaign = async (
  campaign_id: string,
): Promise<IMetaAdset[]> => {
  const response = await axios.get<IMetaAdset[]>(
    `/api/paid/adsets/campaign/${campaign_id}`,
  );
  return response.data;
};

export const deletePaidAdset = async (
  adsetId: string,
): Promise<{ message: string }> => {
  const response = await axios.delete<{ message: string }>(
    `/api/paid/adsets/${adsetId}`,
  );
  return response.data;
};

export const getMetaPixels = async (adAccountId: string): Promise<any[]> => {
  const response = await axios.get<any[]>(
    `/api/paid/adsets/pixels/${adAccountId}`,
  );
  return response.data;
};

export const syncPaidAdsetsForCampaign = async (
  campaignId?: string,
): Promise<void> => {
  const response = await axios.post<void>(
    `/api/paid/adsets/sync/${campaignId}`,
  );
  return response.data;
};
