import { mergeAttributes, Node } from "@tiptap/core";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    variable: {
      insertVariable: (variable: string) => ReturnType;
    };
  }
}

export interface VariableNodeAttrs {
  /**
   * The identifier for the selected variable
   */
  variable: string | null;
  /**
   * The label to be rendered
   */
  label?: string | null;
}

export type VariableOptions = {
  /**
   * The HTML attributes for a variable node
   */
  HTMLAttributes: Record<string, any>;
};

export const Variable = Node.create<VariableOptions>({
  name: "variable",
  inline: true,
  group: "inline",
  selectable: false,
  atom: true,

  addOptions() {
    return {
      HTMLAttributes: {},
    };
  },

  addAttributes() {
    return {
      variable: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-variable"),
        renderHTML: (attributes) => ({
          "data-variable": attributes.variable,
          "data-type": this.name,
        }),
      },
      label: {
        default: null,
        parseHTML: (element) => element.getAttribute("data-label"),
        renderHTML: (attributes) =>
          attributes.label ? { "data-label": attributes.label } : {},
      },
    };
  },

  parseHTML() {
    return [
      { tag: "span[data-variable]" },
      { tag: `span[data-type="${this.name}"]` },
    ];
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "span",
      mergeAttributes(
        { "data-type": this.name },
        this.options.HTMLAttributes,
        HTMLAttributes,
      ),
      `{{${node.attrs.label ?? node.attrs.variable}}}`,
    ];
  },

  renderText({ node }) {
    return `{{${node.attrs.label ?? node.attrs.variable}}}`;
  },

  addCommands() {
    return {
      insertVariable:
        (variable: string) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: { variable },
          });
        },
    };
  },
});
