import React, { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import API, { createPortfolioItem, Hub, Team } from "~/src/api/withApi";
import { Button, buttonVariants } from "~/src/primitives/button";
import { RootState } from "~/src/store";
import { useDispatch } from "react-redux";
import { setUser } from "~/src/reducers/user";
import { setError, setLoading, setSuccess } from "~/src/reducers/toolkit";
import { useNavigate } from "react-router-dom";
import mindbodyIcon from "~/src/assets/MindbodyIcon.png";
import luminIcon from "~/src/assets/LuminIcon.png";
import canvaIcon from "~/src/assets/CanvaIcon.png";
import { Check, ExternalLink, Copy } from "lucide-react";
import { Input } from "~/src/primitives/input";
import { cn } from "../../../util/reusables";
import {
  connectLumin,
  disconnectLumin,
} from "../../../api/integrations/lumin.api";
import "../account.style.css";
import { Module } from "~/src/api/auth.api";
import { deleteCanvaConfiguration } from "../../../api/canva.api";
import {
  activateMindbody,
  connectMindbody,
} from "../../../api/integrations/mindbody.api";
import { url } from "../../../routes";
import { Separator } from "../../../primitives/separator";
import { useMutation } from "@tanstack/react-query";

type IntegrationsProps = {
  className?: string;
  workspace?: Team;
  onUpdate?: () => void | Promise<void>; // Custom logic to be called after an update occurs
};

export default function Integrations({
  className,
  workspace,
  onUpdate = () => {},
}: IntegrationsProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user);
  const isPaidModule =
    useSelector((state: RootState) => state.user?.module) === Module.Paid;
  const hub = useSelector((state: RootState) => state.user?.hub);

  const mindbody = hub ? hub.integrations?.mindbody : workspace?.mindbody;
  const lumin = hub ? hub.integrations?.lumin : workspace?.lumin;

  // Canva connection is directly tied to the user, so we don't need to check the workspace or hub
  const userCanva = useSelector((state: RootState) => state.user?.canva);

  const [siteId, setSiteId] = useState<string>();
  const [locationId, setLocationId] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();

  const activateMutation = useMutation({
    mutationFn: (siteId: string) => activateMindbody(siteId),
    onSuccess: async () => {
      await onUpdate();
      const { user } = await API.getSession("?update=true");
      dispatch(setUser(user));
      dispatch(setSuccess("Activation code regenerated"));
    },
    onError: () => {
      dispatch(setError("An error occurred while activating Mindbody"));
    },
  });

  const completeMutation = useMutation({
    mutationFn: () => connectMindbody({ username, password }),
    onMutate: () => {
      dispatch(setLoading("Completing the Mindbody Integration"));
    },
    onSuccess: async () => {
      await onUpdate();
      const { user } = await API.getSession("?update=true");
      dispatch(setUser(user));
      dispatch(setSuccess("Connected Mindbody"));
    },
    onError: (err: Error) => {
      dispatch(
        setError(err?.message ?? "An error occurred while connecting Mindbody"),
      );
    },
  });

  const handleActivateMindbody = () => {
    if (!mindbody?.siteId && !siteId) {
      return dispatch(setError("Site ID cannot be empty"));
    }
    activateMutation.mutate(siteId ?? mindbody?.siteId);
  };

  const handleCompleteActivation = () => {
    completeMutation.mutate();
  };

  const handleNavigateToMindbodyAutomation = async () => {
    if (isPaidModule) {
      navigate(url(user, "/paid/mindbody", undefined, true));
    } else {
      const blank = await createPortfolioItem({});
      navigate(
        url(
          user,
          `/edit/${blank._id}`,
          new URLSearchParams({
            ctab: "ai",
            att: "caption",
            mb: "1",
          }),
          true,
        ),
      );
    }
  };

  const handleDisconnectMindbody = async () => {
    try {
      await fetch("/api/mindbody/disconnect");
      await API.getSession("?update=true").then(({ user }) =>
        dispatch(setUser(user)),
      );
      await onUpdate();
      dispatch(setSuccess("Disconnected Mindbody"));
    } catch {
      dispatch(setError("An error occured while disconnecting Mindbody"));
    }
  };

  const handleDisconnectCanva = async () => {
    try {
      await deleteCanvaConfiguration();
      await API.getSession("?update=true").then(({ user }) =>
        dispatch(setUser(user)),
      );
      await onUpdate();
      dispatch(setSuccess("Disconnected Canva"));
    } catch {
      dispatch(setError("An error occured while disconnecting Canva"));
    }
  };

  const handleConnectLumin = async () => {
    if (!lumin?.locationId && !locationId)
      return dispatch(setError("Location ID cannot be empty"));
    try {
      dispatch(setLoading("Connecting Lumin..."));
      await connectLumin(locationId ?? lumin?.locationId);
      await onUpdate();
      await API.getSession("?update=true").then(({ user }) =>
        dispatch(setUser(user)),
      );
      dispatch(setSuccess("Lumin connected"));
    } catch {
      dispatch(setError("An error occured while connecting Lumin"));
    }
  };

  const handleDisconnectLumin = async () => {
    try {
      await disconnectLumin();
      await API.getSession("?update=true").then(({ user }) =>
        dispatch(setUser(user)),
      );
      await onUpdate();
      dispatch(setSuccess("Lumin disconnected"));
    } catch {
      dispatch(setError("An error occured while disconnecting Lumin"));
    }
  };

  const currentHub =
    hub ?? (workspace?.primaryHub as Hub) ?? (workspace?.hubs?.[0] as Hub);
  const isHubMissingKey = currentHub && !currentHub?.apiKeys?.length;

  return (
    <div className={cn("container flex w-full flex-col py-12", className)}>
      <div className="mx-auto w-full max-w-3xl @container">
        <h5 className="pb-2 text-sm font-bold text-gray-700">
          Integrations
          {!hub && (
            <span className="ml-2 font-normal italic">
              (Workspace: {workspace?.name})
            </span>
          )}
        </h5>

        {/* Mindbody */}
        {!user?.hub && (
          <Fragment>
            <div className="flex flex-col items-start justify-between gap-4 py-2.5 @md:flex-row @md:items-center @md:gap-2">
              <div className="flex items-center gap-2">
                <img
                  crossOrigin="anonymous"
                  src={mindbodyIcon}
                  alt=""
                  className="h-12 w-12 rounded-full border-2 border-white shadow-sm"
                />
                <div className="w-full">Mindbody</div>
              </div>
              {mindbody?.isConnected ? (
                <div className="flex w-full flex-col gap-4 @md:w-auto @md:flex-row @md:items-center">
                  <div className="flex flex-wrap items-center justify-end gap-4">
                    <div className="flex items-center font-semibold">
                      {mindbody?.site?.name}&nbsp;
                      <span className="text-xs font-normal text-muted-foreground">
                        (ID: #{mindbody?.siteId})
                      </span>
                    </div>
                    <Button
                      className="h-8 shrink-0 bg-accent-blue px-3 text-xs font-semibold hover:bg-accent-blue/90"
                      onClick={handleNavigateToMindbodyAutomation}
                      size="sm"
                    >
                      Start Integration
                    </Button>
                    <Button
                      className="h-8 shrink-0 px-3 text-xs font-semibold"
                      variant="secondary"
                      size="sm"
                      onClick={handleDisconnectMindbody}
                    >
                      Disconnect
                    </Button>
                  </div>
                </div>
              ) : mindbody?.isPendingActivation ? (
                <div className="flex w-full flex-col items-center gap-2 @md:w-auto @md:flex-row">
                  <span className="text-balance text-right text-xs font-semibold text-red-500">
                    Complete the steps below to activate your Mindbody
                    connection.
                  </span>
                  <span className="text-xs font-semibold">-or-</span>
                  <Button
                    className="h-8 shrink-0 px-3 text-xs font-semibold"
                    variant="secondary"
                    size="sm"
                    onClick={handleDisconnectMindbody}
                  >
                    Start over
                  </Button>
                </div>
              ) : (
                <div className="flex w-full max-w-[200px] items-center gap-2">
                  <Input
                    type="text"
                    minLength={1}
                    maxLength={16}
                    onChange={(e) => {
                      setSiteId(e.target.value);
                    }}
                    placeholder="Site ID"
                    className="bg-background"
                  />
                  <Button
                    onClick={handleActivateMindbody}
                    className="text-xs font-bold"
                    size="sm"
                  >
                    Activate
                  </Button>
                </div>
              )}
            </div>
            {mindbody?.activation?.ActivationLink &&
              mindbody?.activation?.ActivationCode && (
                <div className="flex flex-col gap-4">
                  <div className="relative divide-y divide-blue-200/60 rounded-lg border border-blue-200 bg-blue-50/50 shadow-sm">
                    {/* Help Article Preview */}
                    <section className="flex flex-col gap-3 p-4">
                      <div className="flex items-center gap-2">
                        <h3 className="text-xl font-semibold text-blue-950">
                          Important: Read Before Starting
                        </h3>
                        <span className="rounded-full bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-700">
                          Required
                        </span>
                      </div>
                      <div className="space-y-2 text-sm text-blue-700">
                        <p>
                          Before proceeding with activation, please ensure that
                          you have owner-level access to your Mindbody site.
                        </p>
                        <p className="mt-3">
                          <strong>Note:</strong> The activation process requires
                          you to log in to your Mindbody Business site and
                          approve the integration. This grants secure access to
                          your business data while maintaining your
                          account&apos;s security.
                        </p>
                      </div>
                    </section>
                    <Separator className="bg-blue-200/60" />

                    {/* Quick Activation Section */}
                    <section className="flex flex-col gap-3 p-4">
                      <h3 className="flex flex-wrap items-center gap-2 text-xl font-semibold">
                        <span className="rounded-sm border bg-background px-2 py-0.5 text-xl text-foreground shadow-sm">
                          Step 1
                        </span>{" "}
                        Site Activation for{" "}
                        <span className="font-semibold">
                          {mindbody?.siteId}
                        </span>
                      </h3>
                      <div className="flex items-center gap-2">
                        <h4 className="flex items-center gap-2 text-sm font-semibold text-blue-950">
                          <span className="rounded-sm border bg-background px-2 py-0.5 text-xs text-foreground shadow-sm">
                            Option 1
                          </span>{" "}
                          Quick Activation via Link
                        </h4>
                        <span className="rounded-full bg-blue-100 px-2 py-0.5 text-xs font-medium text-blue-700">
                          Recommended
                        </span>
                      </div>
                      <p className="text-center text-sm text-blue-700">
                        Click the button below to automatically connect your
                        Mindbody site:
                      </p>
                      <Button
                        asChild
                        className="w-full @md:w-auto"
                        variant="default"
                      >
                        <a
                          href={mindbody.activation.ActivationLink}
                          target="_blank"
                          rel="noreferrer"
                          className="inline-flex items-center justify-center gap-2"
                        >
                          Activate via Mindbody
                          <ExternalLink className="h-4 w-4" />
                        </a>
                      </Button>

                      {/* Manual Activation Section */}
                      <h4 className="mt-4 flex items-center gap-2 text-sm font-semibold text-blue-950">
                        <span className="rounded-sm border bg-background px-2 py-0.5 text-xs text-foreground shadow-sm">
                          Option 2
                        </span>{" "}
                        Manual Activation via Activation Code
                      </h4>

                      {/* Step 1 */}
                      <div className="flex flex-col gap-2">
                        <div className="flex w-full flex-col items-start justify-between gap-2 @md:flex-row @md:items-center @md:gap-4">
                          <h5 className="font-medium text-blue-900">
                            - Access API Integration Page
                          </h5>
                          <a
                            href="https://support.mindbodyonline.com/s/article/Setting-up-an-API-integration?language=en_US#Activation_Code"
                            target="_blank"
                            rel="noreferrer"
                            className={buttonVariants({
                              variant: "default",
                              size: "sm",
                            })}
                          >
                            View Detailed Instructions
                            <ExternalLink className="h-3 w-3" />
                          </a>
                        </div>
                        <div className="rounded-lg bg-blue-100/50 p-3">
                          <ol className="mt-2 flex list-decimal flex-col gap-1 pl-4 text-sm text-blue-700">
                            <li>
                              Log in to your Mindbody site with owner
                              credentials
                            </li>
                            <li>
                              Navigate to{" "}
                              <span className="font-medium">Settings</span> in
                              the left menu
                            </li>
                            <li>
                              Find{" "}
                              <span className="font-medium">
                                &apos;API Integrations&apos;
                              </span>{" "}
                              or follow:{" "}
                              <i>
                                Settings → Mindbody Add-ons → API Integrations
                              </i>
                            </li>
                          </ol>
                        </div>
                      </div>

                      {/* Step 2 */}
                      <div className="flex flex-col gap-2">
                        <h5 className="font-medium text-blue-900">
                          - Enter Activation Code
                        </h5>
                        <p className="text-sm text-blue-700">
                          Locate the &apos;Have an activation code?&apos;
                          section and enter:
                        </p>
                        <div className="flex flex-col gap-2 @md:flex-row @md:items-center">
                          <code className="flex-1 rounded-lg bg-background px-4 py-2 font-mono text-sm text-blue-950 shadow-sm ring-1 ring-inset ring-blue-200">
                            {mindbody.activation.ActivationCode}
                          </code>
                          <Button
                            variant="outline"
                            size="sm"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                mindbody.activation.ActivationCode,
                              );
                              dispatch(setSuccess("Copied activation code"));
                            }}
                            className="shrink-0"
                          >
                            <Copy className="mr-2 h-4 w-4" />
                            Copy Code
                          </Button>
                        </div>
                      </div>

                      {/* Step 3 */}
                      <div className="flex flex-col gap-2">
                        <h5 className="font-medium text-blue-900">
                          - Verify Connection
                        </h5>
                        <div className="rounded-lg bg-green-50 p-3 text-sm text-green-800">
                          <p>After clicking Submit, you should see:</p>
                          <ul className="mt-1 flex list-disc flex-col gap-1 pl-4">
                            <li>&apos;Activation successful&apos; message</li>
                            <li>
                              New developer account listed under &apos;Who has
                              access to your API section?&apos;
                            </li>
                          </ul>
                        </div>
                      </div>
                    </section>

                    <section className="flex flex-col gap-3 p-4">
                      <div className="flex flex-col gap-4 @md:flex-row @md:items-center @md:justify-between">
                        <h3 className="flex items-center gap-2 text-xl font-semibold text-blue-950">
                          <span className="rounded-sm border bg-background px-2 py-0.5 text-xl text-foreground shadow-sm">
                            Step 2
                          </span>
                          Finalize Integration
                        </h3>
                        <p className="text-sm text-blue-700">
                          Enter your Mindbody staff credentials to complete the
                          integration.
                        </p>
                      </div>
                      <div className="flex flex-col gap-4 @md:flex-row @md:items-end">
                        <div className="flex-1 space-y-4">
                          <div className="grid grid-cols-1 gap-4 @sm:grid-cols-2">
                            <div className="space-y-2">
                              <label
                                htmlFor="username"
                                className="text-sm font-medium text-blue-900"
                              >
                                Staff Username
                              </label>
                              <Input
                                id="username"
                                type="text"
                                placeholder="Enter staff username"
                                className="h-9 bg-background"
                                value={username}
                                onChange={(e) => setUsername(e.target.value)}
                              />
                            </div>
                            <div className="space-y-2">
                              <label
                                htmlFor="password"
                                className="text-sm font-medium text-blue-900"
                              >
                                Staff Password
                              </label>
                              <Input
                                id="password"
                                type="password"
                                placeholder="Enter staff password"
                                className="h-9 bg-background"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                            </div>
                          </div>
                        </div>
                        <Button
                          className="w-full bg-green-600 hover:bg-green-700 @md:w-auto"
                          variant="default"
                          onClick={handleCompleteActivation}
                          disabled={
                            activateMutation.isPending ||
                            completeMutation.isPending
                          }
                        >
                          <Check className="mr-2 h-4 w-4" />
                          Complete Mindbody Activation
                        </Button>
                      </div>
                    </section>
                  </div>
                </div>
              )}

            <Separator className="my-8" />
          </Fragment>
        )}

        {/* Canva */}
        {userCanva?.userId && (
          <>
            <div className="flex flex-col items-start justify-between gap-4 py-2.5 @md:flex-row @md:items-center">
              <div className="flex items-center gap-2">
                <img
                  crossOrigin="anonymous"
                  src={canvaIcon}
                  alt=""
                  className="h-12 w-12 rounded-full border-2 border-white shadow-sm"
                />
                <span>Canva</span>
              </div>
              {userCanva?.userId ? (
                <div className="flex w-full items-center gap-2 @md:w-auto">
                  <Button
                    asChild
                    className="h-7 rounded-full text-xs font-semibold"
                  >
                    <a
                      href="https://www.canva.com/login/?redirect=%2Fdesign%3Fcreate%26type%3DTABQqs5Kbyc%26ui%3DeyJFIjp7IkE_IjoiTiIsIkEiOiJBQUZzTXNoMDFjQV9nUG9IV0sifX0"
                      target="_blank"
                      rel="noreferrer"
                      className="inline-flex items-center"
                    >
                      Go to Canva
                      <ExternalLink className="ml-2 h-3 w-3" strokeWidth={3} />
                    </a>
                  </Button>
                  <Button
                    className="h-7 rounded-full text-xs font-semibold"
                    variant="secondary"
                    size="sm"
                    onClick={handleDisconnectCanva}
                  >
                    Disconnect
                  </Button>
                </div>
              ) : (
                <Button
                  asChild
                  className="h-7 rounded-full text-xs font-semibold"
                >
                  <a
                    href="https://www.canva.com/login/?redirect=%2Fdesign%3Fcreate%26type%3DTABQqs5Kbyc%26ui%3DeyJFIjp7IkE_IjoiTiIsIkEiOiJBQUZzTXNoMDFjQV9nUG9IV0sifX0"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Connect Canva
                  </a>
                </Button>
              )}
            </div>
            <Separator className="my-8" />
          </>
        )}

        {/* Lumin */}
        <div className="flex flex-col items-start justify-between gap-4 py-2.5 @md:flex-row @md:items-center">
          <div className="flex items-center gap-2">
            <img
              crossOrigin="anonymous"
              src={luminIcon}
              alt=""
              className="h-12 w-12 rounded-full border-2 border-white shadow-sm"
            />
            <span>Lumin</span>
          </div>
          {lumin?.locationId ? (
            <div className="flex w-full flex-col items-start gap-4 @md:w-auto @md:flex-row @md:items-center @md:justify-end">
              <span className="text-sm font-semibold">{lumin?.locationId}</span>
              {currentHub ? (
                isHubMissingKey ? (
                  <p className="max-w-24 text-xs text-destructive">
                    Please generate an API key for hub: {currentHub.name}...
                  </p>
                ) : (
                  <Button
                    onClick={() => {
                      navigator.clipboard.writeText(
                        `${window.location.origin}/api/lumin/login/${lumin?.locationId}/${currentHub?.apiKeys?.[0]?.key}`,
                      );
                      dispatch(setSuccess("Embed URL copied to clipboard!"));
                    }}
                    className="h-7 rounded-full text-xs font-semibold"
                  >
                    Copy Embed URL
                    <Copy className="ml-2 h-3 w-3" strokeWidth={3} />
                  </Button>
                )
              ) : (
                <p className="text-xs text-destructive">
                  Please connect this workspace to a hub...
                </p>
              )}
              <Button
                className="h-7 rounded-full text-xs font-semibold"
                variant="secondary"
                size="sm"
                onClick={handleDisconnectLumin}
              >
                Disconnect
              </Button>
            </div>
          ) : (
            <div className="flex w-full max-w-[200px] items-center gap-2">
              <Input
                type="text"
                minLength={1}
                onChange={(e) => {
                  setLocationId(e.target.value);
                }}
                placeholder="Location ID"
                className="bg-background"
              />
              <Button
                onClick={handleConnectLumin}
                className="text-xs font-bold"
                size="sm"
              >
                Connect
              </Button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
