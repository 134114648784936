import { Team } from "../api/withApi";
import { PlatformType } from "./platforms";
import { Editor } from "@tiptap/core";
import {
  captionExtensions,
  parseTiptapContent,
} from "../views/Post/components/editors/CaptionEditor";
import { Node as ProseMirrorNode } from "@tiptap/pm/model";

export const characterLimits = {
  twitter: 280,
  linkedin: 3000,
  instagram: 2200,
  facebook: 63206,
} as const;

export const getPlatformWithLowestCharacterLimit = (
  platformsToEvaluate: PlatformType[],
): PlatformType | undefined => {
  if (!platformsToEvaluate?.length) return PlatformType.Twitter;
  // Filter the limits based on platforms to evaluate
  const filteredLimits = Object.entries(characterLimits).filter(([platform]) =>
    platformsToEvaluate.includes(platform as PlatformType),
  );

  if (filteredLimits.length === 0) {
    return undefined; // Return undefined if no platforms match
  }

  // Find the platform with the minimum character limit
  const minLimitEntry = filteredLimits.reduce((minEntry, currentEntry) =>
    currentEntry[1] < minEntry[1] ? currentEntry : minEntry,
  );

  return minLimitEntry[0] as PlatformType;
};

/**
 * Get the plain text version of a caption
 * @param caption - any caption, plain text or json stringified
 * @returns the parsed version with mentions and such as plain text
 */
export const captionToPlainText = (caption: string) => {
  const parsedContent = parseTiptapContent(caption);
  const editor = new Editor({
    extensions: captionExtensions({ viewOnly: true }),
    content: parsedContent,
  });

  const plainText = editor.getText({
    textSerializers: {
      mention: ({ node }: { node: ProseMirrorNode }) => {
        // Customize how you want to represent mentions in plain text
        return `@${node.attrs.label || node.attrs.id}`;
      },
    },
  });

  editor.destroy();

  return plainText;
};

export const getConnectedPlatforms = (workspace: Team) =>
  ["instagram", "facebook", "twitter", "linkedin"].filter(
    (p) => workspace[p] !== undefined,
  );
