import { Loader, X } from "lucide-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Navbar from "~/src/layouts/components/Navbar";
import { useBounce } from "~/src/gates";
import CheckSession from "~/src/partials/CheckSession/CheckSession";
import {
  setError,
  setLoading,
  setPendingRequestController,
  setSuccess,
  setUnconnectedPlatform,
} from "~/src/reducers/toolkit";
import { RootState } from "~/src/store";
import { cn } from "~/src/util/reusables";
import {
  HiOutlineExclamationTriangle,
  HiOutlineCheckCircle,
} from "react-icons/hi2";
import Luna from "~/src/lib/Luna/Luna";
import ConnectPlatformModal from "~/src/partials/SelectPlatform/ConnectPlatformModal";
import backfill from "~/src/util/backfill";
import { Badge } from "../primitives/badge";
import FlamelFlame from "../assets/FlamelFlame";
import { Toaster } from "../primitives/toaster";
import AlertBanner from "./components/AlertBanner";
// import { CopilotKit } from "@copilotkit/react-core";
// import { WebSocketProvider } from "~/src/WebSocketContext";

let hideErrorTimeout: any;
let hideSuccessTimeout: any;

const RESERVED_PROFILE_PATHS = ["/profile", "/help", "/billing", "/admin"];

export default function AppLayout() {
  const user = useSelector((state: RootState) => state.user);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  // Bounce user in not authorized
  useBounce(user);

  // Handle global toasts
  const dispatch = useDispatch();
  const toolkit = useSelector((state: RootState) => state.toolkit);
  const pill = `max-w-[400px] fixed bottom-6 right-6 z-[10000] flex flex-row items-center gap-3 rounded-lg border shadow-fl bg-background py-3 px-5`;
  const error = `border-destructive text-destructive shadow-fl bg-red-50`;
  const success = `border-green-500 text-green-600 shadow-fl bg-green-50`;

  // Hide error after n seconds
  useEffect(() => {
    if (hideErrorTimeout) clearTimeout(hideErrorTimeout);

    hideErrorTimeout = setTimeout(() => {
      dispatch(setError(undefined));
    }, 5000);
  }, [toolkit?.error]);

  // Hide success after n seconds
  useEffect(() => {
    if (hideSuccessTimeout) clearTimeout(hideSuccessTimeout);

    hideSuccessTimeout = setTimeout(() => {
      dispatch(setSuccess(undefined));
    }, 5000);
  }, [toolkit?.success]);

  // Workspace Change Hooks
  useEffect(() => {
    if (user.workspace) {
      backfill({
        before: console.log,
        success: console.log,
        fail: console.log,
      });
    }

    // Reset the user to a hub session - they navigated to a non-hub page by mistake
    if (
      user?.hub &&
      !pathname.startsWith("/hub") &&
      !RESERVED_PROFILE_PATHS.some((p) => pathname.endsWith(p))
    ) {
      const currentUrl = pathname;
      const newUrl = `/hub/${user?.hub?._id}${currentUrl}`;
      if (currentUrl !== newUrl) {
        navigate(newUrl, { replace: true });
      }
    }

    // Reset the user to a workspace session - they navigated to a hub page by mistake
    if (!user?.hub && pathname.startsWith("/hub")) {
      const currentUrl = location.pathname;
      const newUrl = currentUrl.replace(/\/hub\/[^/]+/, "");
      if (currentUrl !== newUrl) {
        navigate(newUrl, { replace: true });
      }
    }

    // Redirect to paid dashboard if user has paid module
    if (user?.module === "paid" && pathname === "/") {
      navigate("/paid", { replace: true });
    }
  }, [user.workspace, user.hub, user.module]);

  return (
    <CheckSession>
      {/* <WebSocketProvider> */}
      <AlertBanner />
      <Navbar />
      <main className="flex h-full max-h-[calc(100vh-4rem)] min-h-0 w-full flex-1 flex-col overflow-hidden bg-muted">
        {/* <CopilotKit runtimeUrl="/api/copilot"> */}
        <Outlet />
        {/* </CopilotKit> */}
      </main>
      {user?.overrides?.logo && (
        <div className="fixed bottom-2 left-2 z-[999] flex items-center gap-2">
          <a
            href={user?.overrides?.poweredByLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Badge
              variant="subtle"
              className="flex items-center gap-1 bg-background/80 shadow-fl"
            >
              <FlamelFlame className="h-6 w-6" />
              Powered by Flamel.ai
            </Badge>
          </a>
        </div>
      )}
      {toolkit?.loading && (
        <div className={cn(pill, "animate-pulse")}>
          <Loader className="size-5 animate-spin" />
          <div>{toolkit.loading ?? "Loading"}</div>
          {toolkit.pendingRequestController?.abort && (
            <div>
              <X
                className="h-4 w-4 animate-none cursor-pointer"
                onClick={() => {
                  dispatch(setLoading(undefined));
                  toolkit.pendingRequestController.abort();
                  dispatch(setPendingRequestController(undefined));
                }}
              />
            </div>
          )}
        </div>
      )}
      {toolkit?.success && (
        <div className={cn(pill, success)}>
          <div>
            <HiOutlineCheckCircle fontSize={20} />
          </div>
          <div>{toolkit.success ?? "Success"}</div>
        </div>
      )}
      {toolkit?.error && (
        <div className={cn(pill, error)}>
          <div>
            <HiOutlineExclamationTriangle fontSize={20} />
          </div>
          <div>{toolkit.error ?? "Operation failed"}</div>
        </div>
      )}
      <Luna />
      <ConnectPlatformModal
        platform={toolkit?.unconnectedPlatform}
        show={toolkit?.unconnectedPlatform !== undefined}
        onHide={() => {
          dispatch(setUnconnectedPlatform(undefined));
          sessionStorage.removeItem("flamel-redirect");
        }}
      />
      <Toaster
        className="data-[state=closed]:!animate-out data-[state=closed]:!fade-out-80 data-[state=closed]:!slide-out-to-left-full"
        viewPortClassName="sm:left-0"
        swipeDirection="left"
      />
      {/* </WebSocketProvider> */}
    </CheckSession>
  );
}
