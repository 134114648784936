import React, { Fragment, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  Link,
  useNavigate,
  useSearchParams,
  useLocation,
} from "react-router-dom";
import {
  Sheet,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "~/src/primitives/sheet";
import { RootState } from "~/src/store";
import Main, { type MainRoute } from "~/src/routes";
import { Button } from "~/src/primitives/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "~/src/primitives/dropdown-menu";
import { cn } from "~/src/util/reusables";
import Tinybadge from "~/src/primitives/tinybadge";
import { ChevronDown, Menu } from "lucide-react";
import { focusedNavItemStyles } from "./DesktopNav";

export default function MobileNav({ isPaidMode }: { isPaidMode: boolean }) {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector((state: RootState) => state.user);
  const [openDropdowns, setOpenDropdowns] = useState<Record<string, boolean>>(
    {},
  );
  const [searchParams] = useSearchParams();

  const MainRoutes = useMemo(
    () => Main(user, navigate, searchParams),
    [user, navigate, searchParams],
  );

  const memoizedRoutes = useMemo(() => {
    return Object.entries(MainRoutes).map(([name, route]) => {
      let childPaths: string[] = [];
      if (route.routes) {
        const intermediate: MainRoute = Object.assign({}, ...route.routes);
        childPaths = Object.values(intermediate)
          .map((childRoute) =>
            typeof childRoute !== "string" ? childRoute.to : undefined,
          )
          .filter(Boolean) as string[];
      }
      return { name, route, childPaths };
    });
  }, [MainRoutes]);

  const renderDropdownMenuItems = (route: MainRoute) => {
    return (
      route.routes?.flatMap(({ title, ...rest }, idx) => {
        const elems = [];
        if (typeof title === "string") {
          elems.push(
            <Fragment key={`mobile-${title}`}>
              {idx !== 0 && <DropdownMenuSeparator className="w-full" />}
              <DropdownMenuLabel className="text-2xs uppercase">
                {title}
              </DropdownMenuLabel>
            </Fragment>,
          );
        }
        Object.keys(rest).forEach((s, i) => {
          const routeItem = (rest as Record<string, MainRoute>)[s];
          if (routeItem.disabled) {
            elems.push(
              <Fragment key={`mobile-${routeItem?.to}`}>
                {i !== 0 && <DropdownMenuSeparator className="w-full" />}
                <SheetClose asChild>
                  <DropdownMenuItem
                    disabled
                    className="flex w-full items-center justify-between gap-3"
                  >
                    <span className="flex items-center">
                      {routeItem?.icon} {s}
                    </span>{" "}
                    {routeItem?.badge && (
                      <Tinybadge
                        text={routeItem.badge}
                        className={routeItem.badgeClassName}
                      />
                    )}
                  </DropdownMenuItem>
                </SheetClose>
              </Fragment>,
            );
          } else if (routeItem?.to) {
            elems.push(
              <Fragment key={`mobile-${s}`}>
                {i !== 0 && <DropdownMenuSeparator className="w-full" />}
                <SheetClose asChild>
                  <Link to={routeItem.to} draggable="false">
                    <DropdownMenuItem className="flex w-full items-center justify-between gap-3">
                      <span className="flex items-center">
                        {routeItem?.icon} {s}
                      </span>{" "}
                      {routeItem?.badge && (
                        <Tinybadge
                          text={routeItem.badge}
                          className={routeItem.badgeClassName}
                        />
                      )}
                    </DropdownMenuItem>
                  </Link>
                </SheetClose>
              </Fragment>,
            );
          } else if (routeItem?.onClick) {
            elems.push(
              <Fragment key={`mobile-${s}`}>
                {i !== 0 && <DropdownMenuSeparator className="w-full" />}
                <SheetClose asChild>
                  <DropdownMenuItem
                    className="flex w-full items-center justify-between gap-3"
                    onClick={async () => await routeItem.onClick()}
                  >
                    <span className="flex items-center">
                      {routeItem?.icon} {s}
                    </span>{" "}
                    {routeItem?.badge && (
                      <Tinybadge
                        text={routeItem.badge}
                        className={routeItem.badgeClassName}
                      />
                    )}
                  </DropdownMenuItem>
                </SheetClose>
              </Fragment>,
            );
          }
        });
        return elems;
      }) || []
    );
  };

  return (
    <div id="mobile-nav" className="block xl:hidden">
      <Sheet>
        <SheetTrigger asChild>
          <Button variant={isPaidMode ? "ghostDark" : "ghost"} size="icon">
            <Menu className={isPaidMode ? "text-white" : "text-black"} />
          </Button>
        </SheetTrigger>
        <SheetContent
          className={cn(
            "w-48 overflow-hidden px-0",
            isPaidMode
              ? "border-transparent bg-[#093C3E] text-[#F3F9F7]"
              : "bg-background text-foreground",
          )}
        >
          <SheetHeader className="px-4 text-left">
            <SheetTitle
              className={
                isPaidMode ? "text-primary-foreground" : "text-foreground"
              }
            >
              Menu
            </SheetTitle>
          </SheetHeader>
          <div className="flex w-full flex-col items-start justify-center">
            {memoizedRoutes.map(({ name, route /* childPaths */ }, i) => {
              const icon = route.icon;
              const isOpen = openDropdowns[name] || false;
              const setIsOpen = (open: boolean) => {
                setOpenDropdowns((prev) => ({ ...prev, [name]: open }));
              };

              if (route.routes) {
                return (
                  <Fragment key={`mobile-menu-item-${i}`}>
                    {i !== 0 && <DropdownMenuSeparator className="w-full" />}
                    <DropdownMenu onOpenChange={setIsOpen}>
                      <DropdownMenuTrigger asChild>
                        <Button
                          variant="ghost"
                          className={cn(
                            "w-full min-w-full justify-start rounded-none font-bold transition-colors",
                            isPaidMode
                              ? "bg-[#093C3E] text-primary-foreground/90  hover:text-primary-foreground"
                              : "bg-background text-primary/90 hover:text-primary",
                            route.isActive?.(
                              location.pathname,
                              location.search,
                            ) && focusedNavItemStyles(isPaidMode),
                          )}
                        >
                          {icon} {name}{" "}
                          <ChevronDown
                            className={cn(
                              "ml-1 h-3 w-3 rotate-0 transition-transform duration-200 ease-in-out",
                              isOpen && "rotate-180",
                            )}
                          />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent
                        className={cn(
                          "flex min-w-48 flex-col shadow-fl",
                          isPaidMode
                            ? "bg-[#093C3E] text-primary-foreground/90 hover:text-primary-foreground"
                            : "bg-card text-primary/90 hover:text-primary",
                        )}
                      >
                        {renderDropdownMenuItems(route)}
                      </DropdownMenuContent>
                    </DropdownMenu>
                  </Fragment>
                );
              } else if (route.to) {
                return (
                  <Fragment key={`mobile-menu-item-${i}`}>
                    {i !== 0 && <DropdownMenuSeparator className="w-full" />}
                    <Link to={route.to} draggable="false" className="w-full">
                      <Button
                        variant="ghost"
                        className={cn(
                          "w-full min-w-full justify-start rounded-none font-bold transition-colors hover:bg-accent",
                          isPaidMode
                            ? "bg-[#093C3E] text-primary-foreground/90 hover:bg-background/10 hover:text-primary-foreground"
                            : "bg-primary-foreground/10 text-primary/90 hover:bg-black/10 hover:text-primary",
                          route.isActive?.(
                            location.pathname,
                            location.search,
                          ) && focusedNavItemStyles(isPaidMode),
                        )}
                      >
                        {icon} {name}
                      </Button>
                    </Link>
                  </Fragment>
                );
              }

              return null;
            })}
          </div>
        </SheetContent>
      </Sheet>
    </div>
  );
}
