/**
 * Gallery component displays a list of media (images/videos) in a carousel format.
 * Includes navigation arrows, expand functionality, and optional video indicators.
 */
import { ChevronLeft, ChevronRight, Expand } from "lucide-react";
import React, { Dispatch, SetStateAction, useState } from "react";
import { Media } from "~/src/api/withApi";
import { cn } from "~/src/util/reusables";
import DisplayMedia from "~/src/views/Library/components/DisplayMedia";
import LoadMedia from "../../partials/Load/LoadMedia";

interface IGalleryProps {
  /**
   * An array of media items to display.
   */
  media: Media[];
  /**
   * Optional additional Tailwind classes for the container.
   */
  className?: string;
  /**
   * Optional additional Tailwind classes for each media item.
   */
  mediaClassName?: string;
  /**
   * The current index for the carousel.
   */
  carouselIndex: number;
  /**
   * A function to update the carousel index state in the parent.
   */
  setCarouselIndex: Dispatch<SetStateAction<number>>;
  /**
   * Hide default video controls if true.
   */
  hideVideoControls?: boolean;
  /**
   * Display a video indicator icon even if media looks like an image.
   */
  showVideoIndicator?: boolean;
}

export default function Gallery({
  media,
  className,
  mediaClassName = "max-w-80",
  carouselIndex = 0,
  setCarouselIndex,
  hideVideoControls,
  showVideoIndicator,
}: IGalleryProps) {
  // Hooks must be called unconditionally at the top level
  const [showDisplay, setShowDisplay] = useState<boolean>(false);
  const [page, setPage] = useState<number>(carouselIndex);

  // Early return must come after all hooks to avoid "Rendered fewer hooks" error
  if (!media?.length) {
    return null;
  }

  /**
   * Safely update the parent's carousel index if the new value is valid.
   */
  function updatePage(elements: Media[], pageIndex: number) {
    if (pageIndex < 0 || pageIndex >= elements.length) return;
    setCarouselIndex?.(pageIndex);
  }

  const hasNext = page < media.length - 1;
  const hasPrev = page > 0;

  /**
   * Go to the next media item if available.
   */
  function next() {
    if (!hasNext) return;
    setPage((prevPage) => {
      const newPage = prevPage + 1;
      updatePage(media, newPage);
      return newPage;
    });
  }

  /**
   * Go to the previous media item if available.
   */
  function prev() {
    if (!hasPrev) return;
    setPage((prevPage) => {
      const newPage = prevPage - 1;
      updatePage(media, newPage);
      return newPage;
    });
  }

  return (
    <div
      className={cn("relative h-auto", className)}
      key={media[carouselIndex]?.editUri ?? media[carouselIndex]?.uri}
    >
      <LoadMedia
        showVideoIndicator={showVideoIndicator}
        mimeType={!media[page]?.uri ? "image/jpeg" : media[page]?.mimeType}
        src={
          media[page]?.mimeType?.includes("video") &&
          !media[page]?.editUri &&
          !media[page]?.uri
            ? media[page]?.thumbnail
            : (media[page]?.editUri ?? media[page]?.uri)
        }
        className={cn(
          "h-full w-full rounded-none object-cover object-top",
          mediaClassName,
        )}
        hideVideoControls={hideVideoControls}
      />

      {hasPrev && (
        <div className="absolute left-2 top-1/2 z-30 flex h-6 w-6 cursor-pointer flex-row items-center justify-center rounded-full bg-gray-500/30 text-white shadow-fl backdrop-blur-sm transition-colors hover:bg-gray-500/50">
          <ChevronLeft
            className="h-4 w-4"
            onClick={(e) => {
              e.stopPropagation();
              prev();
            }}
          />
        </div>
      )}

      {hasNext && (
        <div className="absolute right-2 top-1/2 z-30 flex h-6 w-6 cursor-pointer flex-row items-center justify-center rounded-full bg-gray-500/30 text-white shadow-fl backdrop-blur-sm transition-colors hover:bg-gray-500/50">
          <ChevronRight
            className="h-4 w-4"
            onClick={(e) => {
              e.stopPropagation();
              next();
            }}
          />
        </div>
      )}

      <div className="absolute left-2 top-2 z-30 flex h-6 w-6 cursor-pointer flex-row items-center justify-center rounded-full bg-gray-500/30 text-white shadow-fl backdrop-blur-sm transition-colors hover:bg-gray-500/50">
        <Expand
          className="h-4 w-4"
          onClick={(e) => {
            e.stopPropagation();
            setShowDisplay(true);
          }}
        />
      </div>

      <DisplayMedia
        show={showDisplay}
        onHide={() => setShowDisplay(false)}
        media={media[page]}
      />
    </div>
  );
}
