import React, { FormEvent, useState } from "react";
import { APIComponent } from "../../api/withApi";
import { Button } from "~/src/primitives/button";
import AuthLayout from "~/src/layouts/AuthLayout";
import { useToast } from "~/src/primitives/use-toast";
import { Label } from "~/src/primitives/label";
import { Input } from "~/src/primitives/input";
import { ArrowLeft } from "lucide-react";
import { formatEmail } from "~/src/util/reusables";

type ForgotPasswordProps = APIComponent;

const ForgotPassword = ({ requestMagicLink }: ForgotPasswordProps) => {
  const { toast } = useToast();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleCreateMagicLink = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    const { target } = event ?? {};
    const [email] = (target as any) ?? [];
    const formattedEmail = formatEmail(email?.value);
    try {
      requestMagicLink(formattedEmail)
        .then((data) => data.json())
        .then((data) => {
          if (data.error) throw new Error(data.error);

          toast({
            title: "Success!",
            description: data.success,
          });

          // Reset the form value
          (target as any)?.reset();
        });
    } catch (err) {
      toast({
        title: "Error!",
        description: err.message,
        variant: "destructive",
      });
    }
    setIsLoading(false);
  };

  return (
    <AuthLayout
      topLink="/login"
      topLinkText="Back to Sign in"
      topLinkIcon={ArrowLeft}
      heading="Forgot your password?"
      description="Request a login link. Just enter your email."
      imageSrc="https://flamel-misc.s3.amazonaws.com/SignInBackground.webp"
    >
      <form onSubmit={handleCreateMagicLink}>
        <div className="grid gap-4">
          <div className="grid gap-1.5">
            <Label htmlFor="email">Email</Label>
            <Input
              required
              id="email"
              placeholder="name@example.com"
              type="email"
              autoCapitalize="none"
              autoComplete="email"
              autoCorrect="off"
              disabled={isLoading}
              className="bg-background"
            />
          </div>
          <Button disabled={isLoading}>
            {isLoading && (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="mr-2 h-4 w-4 animate-spin"
              >
                <path d="M21 12a9 9 0 1 1-6.219-8.56" />
              </svg>
            )}
            Send Me a Link
          </Button>
        </div>
      </form>
    </AuthLayout>
  );
  // <div className="top-spaced flex h-full w-full items-center justify-center p-8">
  //   <div className="fl-card fl-shadow-flw-full max-w-2xl text-center">
  //     <h2>Request a login link.</h2>

  //     {error ? (
  //       <div className="auth-error" data-testid="magiclink-error">
  //         <IoMdAlert /> {error}
  //       </div>
  //     ) : (
  //       <></>
  //     )}

  //     {success ? (
  //       <div className="auth-success">
  //         <AiFillCheckCircle /> {success}
  //       </div>
  //     ) : (
  //       <></>
  //     )}

  //     <br />
  //     <label className="fl-label">Enter your email for a login link</label>
  //     <br />
  //     <input
  //       className="fl-input"
  //       data-testid="forgotpwd-email"
  //       type="text"
  //       placeholder="john@example.com"
  //       onChange={(e) => setEmail(e.target.value)}
  //     />
  //     <br />
  //     <br />
  //     <button
  //       className="fl-button pill mx-auto flex items-center space-x-1"
  //       onClick={handleCreateMagicLink}
  //       data-testid="create-magiclink-button"
  //     >
  //       <AiOutlineMail />
  //       <span>Send me a link</span>
  //     </button>
  //     <br />
  //     <br />
  //     <Link
  //       to="/login"
  //       data-testid="return"
  //       className="text-xs text-[#666666] underline"
  //     >
  //       Return to login...
  //     </Link>
  //   </div>
  // </div>
};

export default ForgotPassword;
