import capitalize from "lodash/capitalize";
import { Team } from "../api/team.api";
import { platforms } from "../partials/SelectPlatform/SelectPlatform";
import { ObjectValues, platformIcon } from "./reusables";

export const META_GRAPH_API_VERSION = "v22.0";

export const PlatformType = {
  Instagram: "instagram",
  Facebook: "facebook",
  Twitter: "twitter",
  LinkedIn: "linkedin",
} as const;

export type PlatformType = ObjectValues<typeof PlatformType>;

export const ALL_PLATFORMS: PlatformType[] = Object.values(PlatformType);

export const getPlatformName = (platform: PlatformType) =>
  ({
    instagram: "Instagram",
    facebook: "Facebook",
    twitter: "X",
    linkedin: "LinkedIn",
  })?.[platform] ?? capitalize(platform);
export const getPlatformContentTypes = (platform: PlatformType) =>
  ({
    instagram: ["post", "reel", "story"],
    facebook: ["post"],
    twitter: ["post"],
    linkedin: ["post"],
  })?.[platform] ?? ["post"];
export const getPlatformColorFromName = (platformName: string) =>
  ({
    Instagram: "pink",
    Facebook: "blue",
    X: "zinc",
    LinkedIn: "indigo",
  })?.[platformName];
export type PlatformWithType = {
  platform: PlatformType;
  platformContentType: string;
};

export const withSocialPfp = (pfp?: string, seed?: string): string => {
  if (seed?.includes("undefined")) {
    seed = "";
  }

  const usedPfp =
    pfp ??
    `https://api.dicebear.com/7.x/thumbs/png?seed=${encodeURIComponent(
      seed ?? "",
    )}`;
  return usedPfp;
};

export const getActiveAccountForPlatform = (
  platform: PlatformType,
  workspace: Team,
  shouldFallbackToPlatform?: boolean,
) => {
  let lastIngested: number | undefined;
  let pfp = undefined;
  let name = undefined;
  let id = undefined;
  let platformContentTypes = ["post"];

  const currentPlatform: any = workspace?.[platform] ?? {};
  const { accounts, selectedAccount, orgs } = currentPlatform;
  switch (platform) {
    case PlatformType.Facebook:
      if (
        selectedAccount !== undefined &&
        accounts &&
        Array.isArray(accounts) &&
        accounts[selectedAccount]
      ) {
        pfp = accounts[selectedAccount].pfp;
        name = accounts[selectedAccount].name;
        id = accounts[selectedAccount].id;
        lastIngested =
          workspace?.ingestedProfiles?.[PlatformType.Facebook]?.[id];
      } else if (shouldFallbackToPlatform) {
        id = platform;
        name = platforms?.[platform]?.text;
      }
      break;
    case PlatformType.Instagram:
      if (
        selectedAccount !== undefined &&
        accounts &&
        Array.isArray(accounts) &&
        accounts[selectedAccount]
      ) {
        pfp = accounts[selectedAccount].profile_picture_url;
        name = accounts[selectedAccount].username;
        id = accounts[selectedAccount]?.id?.toString();
        lastIngested =
          workspace?.ingestedProfiles?.[PlatformType.Instagram]?.[id];
      } else if (shouldFallbackToPlatform) {
        id = platform;
        name = platforms?.[platform]?.text;
      }
      platformContentTypes = [...platformContentTypes, "reel", "story"];
      break;
    case PlatformType.LinkedIn:
      if (
        selectedAccount !== undefined &&
        orgs &&
        Array.isArray(orgs) &&
        orgs[selectedAccount]
      ) {
        pfp = orgs[selectedAccount].pfp;
        name = orgs[selectedAccount].name;
        id = orgs[selectedAccount].id;
        lastIngested =
          workspace?.ingestedProfiles?.[PlatformType.LinkedIn]?.[
            `urn:li:organization:${id}`
          ];
      } else if (Object.keys(currentPlatform)?.length) {
        pfp = currentPlatform?.pfp;
        name = currentPlatform?.name;
        id = currentPlatform?.id;
        lastIngested =
          workspace?.ingestedProfiles?.[PlatformType.LinkedIn]?.[
            `urn:li:person:${id}`
          ];
      } else if (shouldFallbackToPlatform) {
        id = platform;
        name = platforms?.[platform]?.text;
      }
      break;
    case PlatformType.Twitter:
      if (Object.keys(currentPlatform)?.length) {
        pfp = currentPlatform?.pfp;
        name = currentPlatform?.name;
        id = currentPlatform?.key?.split("-")?.[0];
      } else if (shouldFallbackToPlatform) {
        id = platform;
        name = platforms?.[platform]?.text;
      }
      break;
  }

  return {
    name,
    pfp: pfp || name ? withSocialPfp(pfp, name) : platformIcon(platform),
    id,
    lastIngested,
    platform,
    platformContentTypes,
  };
};

export function extractConnectedAccountsInfo(
  workspace: Team,
  shouldFallbackToPlatform?: boolean,
) {
  const platforms = [
    PlatformType.Facebook,
    PlatformType.Instagram,
    PlatformType.LinkedIn,
    PlatformType.Twitter,
  ];
  return platforms
    ?.map((p) =>
      getActiveAccountForPlatform(p, workspace, shouldFallbackToPlatform),
    )
    ?.filter((p) => p.id)
    ?.filter(Boolean);
}
