import { useState } from "react";
import { useDispatch } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import API from "~/src/api/withApi";
import { setError, setLoading, setSuccess } from "~/src/reducers/toolkit";
import { setUser } from "~/src/reducers/user";

/**
 * Hook for changing the active workspace
 * @returns Functions and state for workspace switching
 */
export const useChangeWorkspace = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryClient = useQueryClient();
  const [isChangingWorkspace, setIsChangingWorkspace] = useState(false);

  const changeWorkspaceMutation = useMutation({
    mutationFn: (workspaceId: string) => {
      setIsChangingWorkspace(true);
      dispatch(setLoading("Switching active workspace..."));
      return API.updateWorkspace(workspaceId);
    },
    onSuccess: ({ name }) => {
      dispatch(setSuccess(`Workspace - ${name}`));
      return API.getSession("?update=true")
        .then(({ user }) => {
          dispatch(setUser(user));
          queryClient.invalidateQueries({ queryKey: ["workspaces"] });

          // Handle URL changes if needed
          const newUrl = location.pathname
            .replace(/\/hub\/[^/]+/, "")
            .replace(/\/campaigns\/([^/]+)\/.*/, "/campaigns/$1");

          if (location.pathname !== newUrl) {
            navigate(newUrl);
          }
        })
        .finally(() => {
          setIsChangingWorkspace(false);
        });
    },
    onError: (err) => {
      console.error(err);
      dispatch(setError("Error switching workspace"));
      setIsChangingWorkspace(false);
    },
  });

  return {
    changeWorkspace: changeWorkspaceMutation.mutate,
    isChangingWorkspace,
  };
};
