import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Campaign } from "../api/withApi";
import type { PlatformType } from "../util/platforms";

export type Presets = {
  style: string;
  audience: string[];
  tone: string;
};

export type ToolkitStateSlice = {
  campaign?: Campaign;
  loading?: string;
  pendingRequestController?: AbortController;
  success?: string;
  error?: string;
  presets: Presets;
  prompt?: string;
  unconnectedPlatform?: PlatformType;
};

const initialState: ToolkitStateSlice = {
  campaign: undefined,
  loading: undefined,
  pendingRequestController: undefined,
  success: undefined,
  error: undefined,
  presets: {
    style: undefined,
    audience: [],
    tone: undefined,
  },
  prompt: undefined,
  unconnectedPlatform: undefined,
};
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const { loading, success, error, ...initialStateWithoutStatus } = initialState;

const toolkitSlice = createSlice({
  name: "toolkit",
  initialState,
  reducers: {
    setCampaign(state, action: PayloadAction<Campaign>) {
      return { ...state, campaign: action.payload };
    },
    setLoading(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: action.payload,
      };
    },
    setPendingRequestController(state, action: PayloadAction<AbortController>) {
      return {
        ...state,
        pendingRequestController: action.payload,
      };
    },
    setSuccess(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: undefined,
        success: action.payload,
      };
    },
    setError(state, action: PayloadAction<string>) {
      return {
        ...state,
        loading: undefined,
        error: action.payload,
      };
    },
    setPresets(state, action: PayloadAction<Presets>) {
      return { ...state, presets: action.payload };
    },
    setPrompt(state, action: PayloadAction<string>) {
      return { ...state, prompt: action.payload };
    },
    setUnconnectedPlatform(state, action: PayloadAction<PlatformType>) {
      return { ...state, unconnectedPlatform: action.payload };
    },
    resetPlatformState(state) {
      return { ...state, ...initialStateWithoutStatus };
    },
  },
});

const { actions, reducer } = toolkitSlice;
export const {
  setCampaign,
  setLoading,
  setPendingRequestController,
  setSuccess,
  setError,
  setPresets,
  setPrompt,
  setUnconnectedPlatform,
  resetPlatformState,
} = actions;
export default reducer;
