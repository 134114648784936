import axios from "axios";
import { Presets } from "../reducers/toolkit";
import { PlatformType } from "../util/platforms";
import { PostParams } from "../views/Post/actions/ActionSelector";
import { Tag } from "../views/Post/components/metadata/ImageTagger";
import { UserAccount } from "./auth.api";
import { BrandCheck } from "./brandcheck.api";
import { Campaign } from "./campaign.api";
import { Guest } from "./guest.api";
import { LocationAccountInfo } from "./locations.api";
import { Media } from "./media.api";

export type OverridesForPlatform = {
  caption?: string;
  prompt?: string;
  presets?: Presets;
  platformContentType?: string;
  mediaMetadata?: {
    _id: string;
    altText: string;
    accountTags: string[];
  }[];
  metadata?: any;
};

export interface PortfolioItem {
  owner?: string;
  _id?: string;
  campaign?: string | Campaign;
  media?: Media[];
  description?: string;
  caption?: string;
  metadata?: any;
  prompt?: string;
  presets?: Presets;
  posts?: Post[];
  larvalPosts?: LarvalPost[];
  team?: {
    _id: string;
    name: string;
  };
  hub?: string;
  updatedAt?: string;
  createdAt?: string;
  overrides?: Record<PlatformType, OverridesForPlatform>;
  alt?: Record<string /* mediaId */, string>;
  accountTags?: Record<PlatformType, Record<string, Tag[]>>;
  accounts?: LocationAccountInfo[];
  name?: string;
  topics?: string[];
  brandChecks?: BrandCheck[];
  comments?: Comment[];
  approvalProcess?: {
    _id: string;
    approvalWorkflow: {
      _id: string;
      name: string;
    };
    status: "pending" | "approved" | "rejected";
    currentStep?: {
      _id: string;
      approver: {
        _id: string;
        email: string;
        firstname: string;
        lastname: string;
        pfp: string;
      };
      notes?: string;
      status: "pending" | "approved" | "rejected";
    };
    steps: Array<{
      approver: {
        _id: string;
        email: string;
        firstname: string;
        lastname: string;
        pfp: string;
      };
      status: "pending" | "completed" | "rejected";
      notes?: string;
      completedAt?: string;
      completedBy?: {
        _id: string;
        email: string;
        firstname: string;
        lastname: string;
        pfp: string;
      };
      _id: string;
    }>;
    createdAt: string;
    updatedAt: string;
  };
}

export interface LarvalPost {
  _id: string;
  portfolioItem: PortfolioItem;
  awaitingApproval?: string;
  approvalOverrides?: {
    awaitingApprovalBy: string;
    overriddenBy: string;
  }[];
  comments?: Comment[];
  userAccount: string;
  team?: {
    _id: string;
    name: string;
  };
  campaign?: string | Campaign;
  isDraft?: boolean;
  scheduled: {
    timestamp?: number;
  };
  broadcast?: string;
  broadcastParentHub?: {
    _id: string;
    name: string;
  };

  // Copied Post Data
  caption?: string;
  metadata?: any;
  media?: Media[];
  platform: PlatformType;
  /**
   * @deprecated Use `overrides` instead to support multiple platforms + content type combos...
   */
  platformContentType?: string;
  prompt?: string;
  presets?: Presets;
  overrides?: Record<PlatformType, OverridesForPlatform>;
  alt?: Record<string /* mediaId */, string>;
  accountTags?: Record<PlatformType, Record<string, Tag[]>>;
}

export interface Post<T = Record<string, any>> {
  _id: string;
  userAccount?: string;
  portfolioItem?: PortfolioItem;
  style?: string;
  tone?: string;
  audience?: string;
  platformPostId?: string;
  link: string;
  createdAt?: string;
  updatedAt?: string;
  raw?: T;
  isExternal?: boolean;
  tags?: string[];
  sentiment?: string;
  comments?: any[];

  // Post Related
  caption?: string;
  media?: Media[];
  platform: PlatformType;
  platformContentType?: string;
  prompt?: string;
  broadcastParentHub?: {
    _id: string;
    name: string;
  };
  broadcast?: string | LarvalPost;
  campaign?: string | Campaign;
  team?: {
    _id: string;
    name: string;
  };
}

export interface Comment {
  _id: string;
  user: Partial<UserAccount>;
  guest: Partial<Guest>;
  comment: string;
  createdAt: Date;
}

export const createPortfolioItem = async (
  portfolioItem: PortfolioItem,
): Promise<PortfolioItem> =>
  (await axios.post(`/api/portfolio`, portfolioItem)).data;

export const updatePortfolioItemSchedule = async (
  params: Required<PostParams>,
): Promise<PortfolioItem> =>
  (await axios.post(`/api/portfolio/schedule`, params)).data;

export const updatePortfolioItem = async (
  _id: string,
  diff: any,
): Promise<PortfolioItem> =>
  (await axios.patch(`/api/portfolio`, { _id, diff })).data;

export const setPortfolioItemMedia = async (
  _id: string,
  mediaIds: string[],
): Promise<PortfolioItem> =>
  (
    await axios.post<PortfolioItem>(`/api/portfolio/media`, {
      id: _id,
      mediaIds,
    })
  ).data;

export const deletePortfolioItem = async (
  portfolioItem: PortfolioItem,
): Promise<any> =>
  (await axios.delete(`/api/portfolio/${portfolioItem._id}`)).data;

export const getPortfolioItem = async (_id: string): Promise<PortfolioItem> =>
  (await axios.get(`/api/portfolio/${_id}`)).data;

export const duplicatePortfolioItem = async (portfolioItem: PortfolioItem) =>
  (
    await axios.post<PortfolioItem>(
      `/api/portfolio/${portfolioItem._id}/duplicate`,
    )
  ).data;

/**
 * Get drafts
 */
export const getDrafts = async (limit?: number) =>
  (
    await axios.get<PortfolioItem[]>("/api/portfolio/drafts", {
      params: {
        limit,
      },
    })
  ).data;

/**
 * Get all portfolio items with scheduled larval posts
 * @param from - Start timestamp
 * @param to - End timestamp
 * @param withDrafts - Include draft posts (defaults to true)
 */
export const getScheduledPortfolioItems = async (
  from: number,
  to: number,
  withDrafts = true,
) =>
  (
    await axios.get<PortfolioItem[]>(`/api/portfolio`, {
      params: {
        from,
        to,
        withDrafts,
      },
    })
  ).data;

/**
 * Add a comment to a portfolio item
 * @param portfolioItemId - ID of the portfolio item
 * @param comment - Comment text to add
 */
export const addCommentToPortfolioItem = async (
  portfolioItemId: string,
  comment: string,
): Promise<Comment> =>
  (
    await axios.post<Comment>(`/api/portfolio/${portfolioItemId}/comment`, {
      comment,
    })
  ).data;

/**
 * Get all portfolio items that are awaiting approval for the current user
 */
export const getPendingApprovalPortfolioItems = async () =>
  (
    await axios.get<{
      portfolioItems: PortfolioItem[];
      hasApprovalWorkflows: boolean;
    }>(`/api/portfolio/pending-approval`)
  ).data;
