"use client";

import { Check } from "lucide-react";
import React, { useState } from "react";
import { UserAccount } from "~/src/api/auth.api";
import { withPfp } from "~/src/util/reusables";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/src/primitives/command";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/src/primitives/popover";

interface UserSearchProps {
  seats: Partial<UserAccount>[];
  selected: string[];
  onSelect: (user: Partial<UserAccount>) => void;
  onDeselect: (user: Partial<UserAccount>) => void;
  children?: React.ReactNode;
}

const UserSearch = ({
  seats,
  selected,
  onSelect,
  onDeselect,
  children,
}: UserSearchProps) => {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");

  const filteredSeats = seats
    ?.sort((a, b) => a.email.localeCompare(b.email))
    ?.filter((seat) => {
      const searchTerm = search.toLowerCase();
      const fullName = `${seat.firstname} ${seat.lastname}`.toLowerCase();
      const email = seat.email?.toLowerCase() || "";

      return fullName.includes(searchTerm) || email.includes(searchTerm);
    });

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent className="w-[300px] p-0">
        <Command>
          <CommandInput
            placeholder="Search users..."
            value={search}
            onValueChange={setSearch}
          />
          <CommandList>
            <CommandEmpty>No users found.</CommandEmpty>
            <CommandGroup>
              {filteredSeats?.map((user) => (
                <CommandItem
                  key={user._id}
                  value={user.email}
                  onSelect={() => {
                    selected?.includes(user._id)
                      ? onDeselect(user)
                      : onSelect(user);
                  }}
                >
                  <div className="flex items-center gap-2">
                    <img
                      src={withPfp(
                        user.pfp,
                        `${user?.firstname} ${user?.lastname}`,
                      )}
                      className="h-5 w-5 rounded-full shadow-fl"
                      crossOrigin="anonymous"
                    />
                    <div className="flex flex-col">
                      <span>
                        {user.firstname && user.lastname
                          ? `${user.firstname} ${user.lastname}`
                          : user.email}
                      </span>
                      {user.email && user.firstname && user.lastname && (
                        <span className="text-xs text-muted-foreground">
                          {user.email}
                        </span>
                      )}
                    </div>
                    {selected?.includes(user._id) && (
                      <Check className="ml-auto h-4 w-4 text-green-400" />
                    )}
                  </div>
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};

export default UserSearch;
