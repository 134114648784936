import React from "react";
import { Heart, MessageCircle, Send } from "lucide-react";
import { BsPatchCheckFill } from "react-icons/bs";
import "./instagram-template.css";
import LoadMedia from "../../Load/LoadMedia";
import { useSelector } from "react-redux";
import { RootState } from "~/src/store";
import { cn, withPfp, username as getUsername } from "~/src/util/reusables";
import { getActiveAccountForPlatform } from "~/src/views/CommunityManagement/lib/utils";
import { PlatformType } from "~/src/util/platforms";
import { InstagramPost } from "~/src/api/inbox.api";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "~/src/primitives/hover-card";
import { Team } from "~/src/api/team.api";
import ViewOnlyCaptionWithFallback from "../../../views/Post/components/editors/ViewOnlyCaptionWithFallback";
import { LocationAccountInfo } from "../../../api/locations.api";

export interface InstagramReelTemplateProps {
  className?: string;
  display?: React.ReactNode;
  media?: string;
  mimeType?: string;
  caption?: string;
  selected?: boolean;
  style?: any;
  onSelectAccount?: () => void;
  raw?: InstagramPost;
  forceWorkspace?: Team; // Allows you to override the PFP and Name per: https://gitlab.com/flamel-ai/flamel-ai/-/issues/905
  forceDisplayPfp?: string; // Allows absolute control over pfp
  forceDisplayName?: string; // Allows absolute control over name
  account?: LocationAccountInfo;
}

const InstagramReelTemplate = ({
  className,
  display,
  media,
  mimeType,
  caption,
  selected,
  style,
  onSelectAccount,
  raw,
  forceWorkspace,
  forceDisplayPfp,
  forceDisplayName,
  account,
}: InstagramReelTemplateProps) => {
  const user = useSelector((state: RootState) => state.user);
  const { name: username, pfp: profile_picture_url } =
    getActiveAccountForPlatform(
      forceWorkspace ?? user?.workspace,
      PlatformType.Instagram,
    );

  const { owner } = raw ?? {};
  const { profile_picture_url: dynamicPfp, username: dynamicUsername } =
    owner ?? {};

  return (
    <div
      className={cn(
        "relative flex h-auto min-w-full animate-fadein overflow-hidden rounded-lg bg-background",
        className,
      )}
    >
      <div
        className="relative flex aspect-[9/16] h-full w-full max-w-[300px] flex-col items-center justify-center overflow-y-auto bg-black"
        style={style ?? {}}
      >
        {!!display && display}
        {!display && media && (
          <LoadMedia
            mimeType={mimeType}
            src={media}
            className="w-full rounded-lg"
          />
        )}
        <div className="absolute bottom-0 flex h-full min-h-full w-full justify-between gap-2">
          <div
            className={cn(
              "space-2 flex flex-col justify-end",
              display || media ? "text-white" : "text-black",
            )}
          >
            <div className="grid gap-2 whitespace-pre-wrap p-3">
              <div className="flex items-center" onClick={onSelectAccount}>
                <img
                  crossOrigin="anonymous"
                  src={withPfp(
                    forceDisplayName
                      ? forceDisplayPfp
                      : (dynamicPfp ?? profile_picture_url),
                    forceDisplayName ??
                      dynamicUsername ??
                      username ??
                      getUsername(user),
                  )}
                  alt=""
                  className="mr-2.5 inline-flex h-8 w-8 rounded-full bg-background align-middle"
                />
                <div className="inline-flex align-middle text-sm font-semibold">
                  {forceDisplayName ??
                    dynamicUsername ??
                    username ??
                    getUsername(user)}
                </div>
              </div>
              {!!caption && (
                <HoverCard>
                  <HoverCardTrigger>
                    <ViewOnlyCaptionWithFallback
                      platform={PlatformType.Instagram}
                      stringContent={caption}
                      className={cn(
                        "line-clamp-4 overflow-hidden break-words text-sm",
                        display || (media && "font-bold"),
                      )}
                      account={account}
                    />
                  </HoverCardTrigger>
                  <HoverCardContent side="top">
                    <ViewOnlyCaptionWithFallback
                      platform={PlatformType.Instagram}
                      stringContent={caption}
                      className={cn(
                        "break-words text-sm",
                        display || (media && "font-bold"),
                      )}
                      account={account}
                    />
                  </HoverCardContent>
                </HoverCard>
              )}
            </div>
          </div>
          <div
            className={cn(
              "text-l flex min-h-full flex-col items-center justify-end gap-5 p-3",
              display || media ? "text-white" : "text-black",
            )}
          >
            <Heart />
            <MessageCircle />
            <Send />
          </div>
        </div>
      </div>

      <BsPatchCheckFill
        width={40}
        height={40}
        className={`selected-checkmark ${selected ? "" : "hidden"}`}
      />
    </div>
  );
};

export default InstagramReelTemplate;
