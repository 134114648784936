import React, { useContext, useState } from "react";
import API, { Role, UserAccount } from "~/src/api/withApi";
import { useDispatch } from "react-redux";
import { setUser } from "~/src/reducers/user";
import FadeIn from "~/src/partials/Transitions/FadeIn";
import { Card } from "~/src/primitives/card";
import { OrganizationContext } from "../Organization";
import {
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableHeader,
  TableBody,
} from "~/src/primitives/table";
import capitalize from "lodash/capitalize";
import { Badge } from "~/src/primitives/badge";
import {
  MoreHorizontal,
  Send,
  Undo2,
  UserCog,
  UserMinus,
  UserPlus2,
} from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { Button } from "~/src/primitives/button";
import { Alert } from "~/src/primitives/alert";
import { Input } from "~/src/primitives/input";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "~/src/primitives/dropdown-menu";
import UpdateUserModal from "./components/UpdateUserModal";
import KickUserModal from "./components/KickUserModal";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
} from "~/src/primitives/select";
import { SelectValue } from "@radix-ui/react-select";
import { formatEmail, withPfp } from "~/src/util/reusables";
import { setError } from "~/src/reducers/toolkit";
import { useSelector } from "react-redux";
import { RootState } from "~/src/store";

export const unenumify = (value: string) =>
  value
    ?.split("_")
    .map((e) => capitalize(e?.toLowerCase()))
    .join(" ");

const UserSeats = () => {
  const dispatch = useDispatch();
  const canManageOrganization = useSelector(
    (state: RootState) => state.user?.manifest?.manage_organization,
  );
  const organization = useContext(OrganizationContext);
  const [showInviteModal, setShowInviteModal] = useState<boolean>();
  const [selectedUser, setSelectedUser] = useState<Partial<UserAccount>>();
  const [showUpdateUserModal, setShowUpdateUserModal] = useState<boolean>();
  const [showKickUserModal, setShowKickUserModal] = useState<boolean>();

  const onInvite = async (email: string) => {
    const formattedEmail = formatEmail(email);
    const res = await API.inviteToOrganization(formattedEmail);
    if (res.ok) {
      await API.getSession().then(({ user }) => {
        dispatch(setUser(user));
      });
    } else {
      const data = await res?.json();
      if (data?.error) dispatch(setError(data.error));
    }
  };

  const onRevokeInvite = async (email: string) => {
    await API.revokeOrganizationInvite(email);
    await API.getSession().then(({ user }) => dispatch(setUser(user)));
  };

  const onUpdateUser = async (userId: string, diff: Partial<UserAccount>) =>
    API.updateOrganizationUser(userId, diff)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  const seatsOccupied =
    organization?.seats?.length + organization?.invites?.length;

  return (
    <FadeIn show timeout={100}>
      <Card className="mt-2 w-full p-4">
        <div className="mb-4 flex flex-row items-center justify-between">
          <div className="flex items-center gap-2">
            <Badge variant="outline">{seatsOccupied} Seats Occupied</Badge>
          </div>
          <div className="flex flex-row justify-start gap-2">
            <Button
              variant="default"
              size="sm"
              disabled={!canManageOrganization}
              onClick={() => setShowInviteModal(true)}
            >
              <UserPlus2 className="h-4 w-4" /> Add User
            </Button>
          </div>
        </div>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-72">User</TableHead>
              <TableHead className="text-center">Role</TableHead>
              <TableHead className="text-center">Status</TableHead>
              <TableHead className="text-center"></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {organization?.seats?.map((seat) => (
              <TableRow key={`seat-user-${seat._id}`} className="align-middle">
                <TableCell className="flex w-72 items-center gap-2">
                  <img
                    src={withPfp(
                      seat.pfp,
                      `${seat?.firstname} ${seat?.lastname}`,
                    )}
                    className="block size-8 rounded-full shadow-fl"
                    crossOrigin="anonymous"
                  />
                  <div className="flex flex-col items-start">
                    <div className="text-sm font-bold">
                      {seat?.firstname
                        ? seat?.lastname
                          ? `${seat.firstname} ${seat.lastname}`
                          : seat.firstname
                        : ""}
                    </div>
                    <div className="text-xs text-muted-foreground">
                      {seat.email}
                    </div>
                  </div>
                </TableCell>
                <TableCell className="text-center">
                  <Select
                    onValueChange={(rv: Role) => {
                      onUpdateUser(seat._id, {
                        organizationRole: rv,
                      });
                    }}
                    disabled={
                      seat._id === organization.owner?._id ||
                      !canManageOrganization
                    }
                  >
                    <SelectTrigger
                      defaultValue={seat.organizationRole}
                      className="mx-auto max-w-48 text-center"
                    >
                      <SelectValue className="[&>*]:text-center">
                        {unenumify(seat.organizationRole)}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent className="max-w-48 text-center">
                      <SelectGroup>
                        {Object.keys(Role).map((rv) => (
                          <SelectItem value={Role[rv]} key={rv}>
                            {unenumify(Role[rv])}
                          </SelectItem>
                        ))}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </TableCell>
                <TableCell className="text-center">
                  <Badge variant="default">Active</Badge>
                </TableCell>
                <TableCell className="text-center">
                  {seat?._id !== organization?.owner?._id &&
                    canManageOrganization && (
                      <div className="flex h-full flex-row items-center justify-center">
                        <DropdownMenu>
                          <DropdownMenuTrigger>
                            <MoreHorizontal className="h-4 w-4" />
                          </DropdownMenuTrigger>
                          <DropdownMenuContent>
                            <DropdownMenuGroup>
                              <DropdownMenuItem
                                onClick={() => {
                                  setSelectedUser(seat);
                                  setShowUpdateUserModal(true);
                                }}
                              >
                                <UserCog className="mr-2 h-4 w-4" /> Configure
                              </DropdownMenuItem>
                              <DropdownMenuItem
                                onClick={() => {
                                  setSelectedUser(seat);
                                  setShowKickUserModal(true);
                                }}
                              >
                                <UserMinus className="mr-2 h-4 w-4" /> Kick User
                              </DropdownMenuItem>
                            </DropdownMenuGroup>
                          </DropdownMenuContent>
                        </DropdownMenu>
                      </div>
                    )}
                </TableCell>
              </TableRow>
            ))}
            {organization?.invites?.map((email, i) => (
              <TableRow key={`invite-user-${email}-${i}`}>
                <TableCell className="flex items-center gap-2">
                  <Send className="ml-2 size-5" />
                  <div className="text-sm font-bold">{email}</div>
                </TableCell>
                <TableCell></TableCell>
                <TableCell className="text-center">
                  <Badge variant="default">Pending Invite</Badge>
                </TableCell>
                <TableCell className="text-center">
                  {canManageOrganization && (
                    <div className="flex h-full flex-row items-center justify-center">
                      <DropdownMenu>
                        <DropdownMenuTrigger>
                          <MoreHorizontal className="h-4 w-4" />
                        </DropdownMenuTrigger>
                        <DropdownMenuContent>
                          <DropdownMenuGroup>
                            <DropdownMenuItem
                              onClick={() => onRevokeInvite(email)}
                            >
                              <Undo2 className="mr-2 h-4 w-4" /> Revoke Invite
                            </DropdownMenuItem>
                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                    </div>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>

      <InviteUserModal
        show={showInviteModal}
        onHide={() => setShowInviteModal(false)}
        onInvite={onInvite}
      />

      <UpdateUserModal
        show={showUpdateUserModal}
        onHide={() => setShowUpdateUserModal(false)}
        userSeat={selectedUser}
      />

      <KickUserModal
        show={showKickUserModal}
        onHide={() => setShowKickUserModal(false)}
        userSeat={selectedUser}
      />
    </FadeIn>
  );
};

interface InviteUserModalProps {
  show: boolean;
  onInvite: (email: string) => void;
  onHide: () => void;
}

const InviteUserModal = ({ show, onInvite, onHide }: InviteUserModalProps) => {
  const [email, setEmail] = useState<string>();

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-row items-center justify-center">
            <div>
              <UserPlus2 className="mr-2 h-4 w-4" />
            </div>
            <div>Add user by email</div>
          </div>
        </DialogHeader>
        <div>
          <Alert className="mb-4">
            <div className="flex flex-row items-center space-x-4">
              <div>
                <Send className="mr-2 h-4 w-4" />
              </div>
              <div className="text-sm">
                Send an invite email to your future teammate!
              </div>
            </div>
          </Alert>

          <Input
            placeholder="test@example.com"
            spellCheck={false}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <DialogFooter>
          <Button variant="outline" size="sm" onClick={onHide}>
            Close
          </Button>
          <Button
            variant="default"
            size="sm"
            onClick={() => {
              onInvite(email);
              onHide();
            }}
          >
            Invite
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default UserSeats;
