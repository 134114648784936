import { useEffect, useRef } from "react";
import { Editor } from "@tiptap/react";
import tippy from "tippy.js";
import { ReactRenderer } from "@tiptap/react";
import VariableSelector from "./VariableSelector";

export function useVariableTrigger(editor: Editor | null, disabled?: boolean) {
  const tippyInstanceRef = useRef<any>(null);
  const componentRef = useRef<ReactRenderer | null>(null);

  useEffect(() => {
    // Return early if editor is null or feature is disabled
    if (!editor || disabled) return;

    // Function to check for {{ pattern
    const checkForVariableTrigger = () => {
      if (!editor || !editor.state) return;

      const { selection } = editor.state;
      const { from } = selection;

      // Get the two characters before the cursor
      const textBefore = editor.state.doc.textBetween(
        Math.max(0, from - 2),
        from,
        "",
      );

      // If we have {{ and a tippy isn't already showing
      if (textBefore === "{{" && !tippyInstanceRef.current) {
        showVariableSelector(from);
      }
    };

    // Function to show the variable selector
    const showVariableSelector = (position: number) => {
      // Get cursor coordinates
      const coords = editor.view.coordsAtPos(position);

      // Create component
      componentRef.current = new ReactRenderer(VariableSelector, {
        props: {
          className: "border shadow-md rounded-lg bg-background z-50",
          onSelectVariable: (variable: string) => {
            // Delete the {{ trigger
            editor.commands.deleteRange({ from: position - 2, to: position });

            // Insert the variable node
            editor.commands.insertContent({
              type: "variable",
              attrs: { variable },
            });

            // Insert a space
            editor.commands.insertContent(" ");

            // Hide tippy
            tippyInstanceRef.current?.destroy();
            tippyInstanceRef.current = null;
          },
        },
        editor,
      });

      // Create temporary anchor element at cursor position
      const element = document.createElement("div");
      element.style.position = "absolute";
      element.style.left = `${coords.left}px`;
      element.style.top = `${coords.top}px`;
      document.body.appendChild(element);

      // Create tippy instance
      tippyInstanceRef.current = tippy(element, {
        content: componentRef.current.element,
        trigger: "manual",
        interactive: true,
        placement: "bottom-start",
        theme: "light",
        appendTo: document.body,
        showOnCreate: true,
        onShow: (instance) => {
          // Focus the VariableSelector component after a short delay
          setTimeout(() => {
            const inputElement = instance.popper.querySelector("input");
            if (inputElement) {
              inputElement.focus();
            }
          }, 0);
        },
        onHide() {
          componentRef.current?.destroy();
          componentRef.current = null;
          element.remove();
        },
        onDestroy() {
          // Clean up the element when tippy is destroyed
          element.remove();
        },
        onHidden() {
          // After hiding, set the cursor position after the inserted variable
          if (editor && editor.isEditable) {
            // Get the current position
            const { pos } = editor.view.state.selection.$anchor;
            // Set the cursor position
            editor.commands.setTextSelection(pos);
            // Focus the editor to continue typing
            editor.commands.focus();
          }
        },
      });
    };

    // Add event listeners for key presses and transaction updates
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "{") {
        // Wait a tiny bit for the character to be inserted
        setTimeout(checkForVariableTrigger, 10);
      } else if (event.key === "Escape" && tippyInstanceRef.current) {
        tippyInstanceRef.current.destroy();
        tippyInstanceRef.current = null;
      }
    };

    // Also check on document clicks
    const handleDocumentClick = (event: MouseEvent) => {
      if (
        tippyInstanceRef.current &&
        event.target &&
        !tippyInstanceRef.current.popper.contains(event.target as Node)
      ) {
        tippyInstanceRef.current.destroy();
        tippyInstanceRef.current = null;
      }
    };

    // Add our event listeners
    editor.view.dom.addEventListener("keydown", handleKeyDown);
    document.addEventListener("mousedown", handleDocumentClick);

    // Clean up
    return () => {
      editor.view.dom.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("mousedown", handleDocumentClick);

      if (tippyInstanceRef.current) {
        tippyInstanceRef.current.destroy();
        tippyInstanceRef.current = null;
      }
    };
  }, [editor, disabled]);

  return null;
}
