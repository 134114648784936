import React from "react";
import FacebookTemplate from "./FacebookTemplate/FacebookTemplate";
import InstagramTemplate from "./InstagramTemplate/InstagramTemplate";
import LinkedInTemplate from "./LinkedInTemplate/LinkedInTemplate";
import TwitterTemplate from "./TwitterTemplate/TwitterTemplate";
import { Dayjs } from "dayjs";
import InstagramReelTemplate from "./InstagramTemplate/InstagramReelTemplate";
import InstagramStoryTemplate from "./InstagramTemplate/InstagramStoryTemplate";
import type { Team } from "~/src/api/team.api";
import { PlatformType } from "~/src/util/platforms";
import { LocationAccountInfo } from "../../api/withApi";

interface IPreviewProps {
  platform: PlatformType;
  platformContentType?: string;
  display?: React.ReactNode;
  media?: string;
  when?: Dayjs;
  mimeType?: string;
  caption: string;
  style?: React.CSSProperties;
  selected?: boolean;
  typewriter?: boolean;
  onClick?: () => void;
  onSelectMedia?: () => void;
  onSelectAccount?: () => void;
  raw?: any;
  /**
   * @deprecated use direct `forceDisplayPfp` and `forceDisplayName` instead
   */
  forceWorkspace?: Team;
  likeCount?: number;
  forceDisplayPfp?: string;
  forceDisplayName?: string;
  account?: LocationAccountInfo;
}

const PLATFORM_TEMPLATES = {
  [PlatformType.Instagram]: {
    post: InstagramTemplate,
    reel: InstagramReelTemplate,
    story: InstagramStoryTemplate,
  },
  [PlatformType.Twitter]: {
    post: TwitterTemplate,
  },
  [PlatformType.LinkedIn]: {
    post: LinkedInTemplate,
  },
  [PlatformType.Facebook]: {
    post: FacebookTemplate,
  },
} as const;

const Preview: React.FC<IPreviewProps> = (props) => {
  const { platform, platformContentType = "post" } = props;

  // Get the template component for the specified platform and content type
  const TemplateComponent =
    PLATFORM_TEMPLATES[platform]?.[
      platformContentType as keyof (typeof PLATFORM_TEMPLATES)[typeof platform]
    ] || PLATFORM_TEMPLATES[platform]?.post;

  if (!TemplateComponent) return null;

  return <TemplateComponent {...props} />;
};

export type PreviewProps = IPreviewProps;
export default Preview;
