import React, { Fragment, memo } from "react";
import { LocationAccountInfo } from "../../../../api/locations.api";
import { PlatformType } from "../../../../util/platforms";
import { cn } from "../../../../util/reusables";
import CaptionEditor from "./CaptionEditor";

type ViewOnlyCaptionWithFallbackProps = {
  stringContent: string;
  className?: string;
  platform?: PlatformType;
  account?: LocationAccountInfo;
};

function ViewOnlyCaptionWithFallback({
  stringContent,
  className,
  platform,
  account,
}: ViewOnlyCaptionWithFallbackProps) {
  try {
    const c = JSON.parse(stringContent);
    return (
      <CaptionEditor
        key={stringContent}
        className={cn("break-words", className)}
        content={c}
        viewOnly={true}
        platform={platform}
        account={account}
      />
    );
  } catch {
    return (
      <div className={cn("break-words", className)}>
        {platform === PlatformType.LinkedIn
          ? parseLinkedInLittleText(stringContent)
          : applyCaptionHighlights(stringContent, className)}
      </div>
    );
  }
}

export default memo(ViewOnlyCaptionWithFallback);

const applyCaptionHighlights = (
  caption: string,
  className?: string,
  identifiers: string[] = ["#", "@", "http"],
): any => {
  if (typeof caption !== "string") return [];

  return caption.split(/([ \n])/).map((part, index) => {
    if (part === " ") return " ";
    if (part === "\n") return <br key={`br-${index}`} />;

    return (
      <FormattedWord
        key={`word-${index}`}
        word={part}
        identifiers={identifiers}
        className={className}
      />
    );
  });
};

type FormattedWordProps = {
  word: string;
  identifiers: string[];
  className?: string;
};

// Memoize FormattedWord to improve performance
const FormattedWord = React.memo(
  ({ word, identifiers, className }: FormattedWordProps) => {
    const requiresFormatting = identifiers.some((i) =>
      word.trim().startsWith(i),
    );

    if (!requiresFormatting) return <Fragment>{word}</Fragment>;

    // Link Formatting
    if (word.startsWith("http")) {
      return (
        <a
          className={className ?? "text-blue-600"}
          target="_blank"
          href={word}
          rel="noreferrer"
        >
          {word}
        </a>
      );
    }

    // Basic Mention and Hashtag formatting
    return <span className={className ?? "text-blue-600"}>{word}</span>;
  },
);

FormattedWord.displayName = "FormattedWord";

function parseLinkedInLittleText(input: string) {
  const elements = [];
  const regex =
    /(\{[^}]+\}|@\[[^\]]+\]\([^)]+\)|#\w+|https?:\/\/[^\s]+|\bhttps?:\/\/\S+|\n|(?!@|{|#)(\\(\([^)]+\))|[\s\S]))/g;
  const matches = input.match(regex);

  if (matches) {
    matches.forEach((match: string) => {
      if (match.startsWith("{")) {
        const parts = match.match(/\{([^|]+)\|([^|]+)\|([^}]+)\}/);
        if (parts) {
          elements.push({
            type: "hashtagTemplate",
            text: parts[3],
          });
        }
      } else if (match.startsWith("@[")) {
        const parts = match.match(/@\[([^\]]+)\]\(([^)]+)\)/);
        if (parts) {
          elements.push({
            type: "mention",
            text: parts[1],
            urn: parts[2],
          });
        }
      } else if (match.startsWith("#")) {
        elements.push({
          type: "hashtag",
          text: match.substring(1),
        });
      } else if (match.startsWith("http")) {
        elements.push({
          type: "link",
          url: match,
        });
      } else if (match === "\n") {
        elements.push({
          type: "newline",
        });
      } else if (match.startsWith("\\(") && match.endsWith("\\)")) {
        const formattedMatch = match.slice(2, -2);
        // TODO: Need to recursively handle other things like hashtags
        const parts = formattedMatch.match(/\{([^|]+)\|([^|]+)\|([^}]+)\}/);
        const hashTagToReplace = parts?.[0];
        const replaceWith = parts?.[3];
        if (hashTagToReplace && replaceWith) {
          const splitParts = formattedMatch.split(" ");
          let i = 0;
          for (const p of splitParts) {
            // Check for hashtag
            if (p === hashTagToReplace) {
              elements.push({
                type: "hashtag",
                text: replaceWith,
              });
            } else {
              elements.push({
                type: "italic",
                text: i === 0 ? `${p} ` : ` ${p}`,
              });
            }
            i += 1;
          }
        } else {
          // Normal italic text
          elements.push({
            type: "italic",
            text: formattedMatch,
          });
        }
      } else {
        elements.push({
          type: "text",
          text: match,
        });
      }
    });
  }

  const parsedElements = elements.map((element, i) => {
    if (element.type === "mention") {
      return (
        <a
          className="cursor-pointer font-semibold text-[#0a66c2]"
          key={`element-${i}`}
          href={`/api/linkedin/profile/${element.urn}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {element.text}
        </a>
      );
    } else if (
      element.type === "hashtag" ||
      element.type === "hashtagTemplate"
    ) {
      return (
        <a
          className="cursor-pointer font-semibold text-[#0a66c2]"
          key={`element-${i}`}
          href={`https://www.linkedin.com/feed/hashtag/${(
            element.text as string
          )?.toLowerCase()}/`}
          target="_blank"
          rel="noopener noreferrer"
        >
          #{element.text}
        </a>
      );
    } else if (element.type === "link") {
      return (
        <a
          className="cursor-pointer font-semibold text-[#0a66c2]"
          key={`element-${i}`}
          href={element.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          {element.url}
        </a>
      );
    } else if (element.type === "italic") {
      return (
        <span key={`element-${i}`} className="italic">
          {element.text}
        </span>
      );
    } else if (element.type === "newline") {
      return <br key={`element-${i}`} />;
    } else {
      return (
        <React.Fragment key={`element-${i}`}>{element.text}</React.Fragment>
      );
    }
  });

  return parsedElements;
}
