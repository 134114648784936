import React, { ReactNode } from "react";
import logo from "../assets/flamel-logo-horiz.png";
import { cn } from "~/src/util/reusables";
import { buttonVariants } from "~/src/primitives/button";
import { Toaster } from "~/src/primitives/toaster";
import { Link } from "react-router-dom";
import { Cable, type LucideIcon } from "lucide-react";

type AuthLayoutProps = {
  children: ReactNode;
  topLink?: string;
  topLinkIcon?: LucideIcon;
  topLinkText?: string;
  integrationLogo?: string;
  heading: string;
  description?: string;
  bottomLink?: string;
  bottomLinkText?: string;
  className?: string;
  signInOnly?: boolean;
  imageSrc?: string;
};

export default function AuthLayout({
  children,
  topLink,
  topLinkIcon,
  topLinkText,
  integrationLogo,
  heading,
  description,
  bottomLink,
  bottomLinkText,
  signInOnly = false,
  imageSrc,
}: AuthLayoutProps) {
  if (document?.referrer) {
    const referrer = document?.referrer;
    const referrerUrl = new URL(referrer);
    const marketingSiteOrigins = ["https://flamel.ai", "https://www.flamel.ai"];
    if (marketingSiteOrigins.includes(referrerUrl.origin)) {
      sessionStorage.setItem(
        "flamelai-referrer-pathname",
        referrerUrl.pathname,
      );
    }
  }

  const TopLinkIcon = topLinkIcon;
  return (
    <div className="relative flex h-full max-h-screen min-h-full flex-1 overflow-hidden bg-gradient-to-b from-[#F2F2F2] to-[#DFDFDF]/70">
      <div className="relative mx-auto flex h-full min-h-full w-full max-w-xl flex-1 flex-col items-center overflow-auto px-4 pb-12 pt-24 sm:px-6 lg:flex-none lg:px-16 2xl:max-w-3xl 2xl:px-24">
        {!signInOnly && topLink && topLinkText && (
          <Link
            to={topLink}
            className={cn(
              buttonVariants({ variant: "ghost" }),
              "absolute left-4 top-4 flex items-center gap-2 sm:left-6 sm:top-6 lg:left-16 2xl:left-24",
            )}
          >
            {!!topLinkIcon && <TopLinkIcon className="h-3 w-3" />}
            {topLinkText}
          </Link>
        )}
        <div className="flex h-fit w-full min-w-[300px] flex-col gap-8 rounded-2xl border-2 border-white bg-background/60 p-6 shadow-fl backdrop-blur">
          <div className="flex flex-col gap-4">
            <div className="flex items-center gap-8">
              <div className="flex h-full w-auto shrink items-center">
                <img src={logo} className="h-auto w-full max-w-[8rem]" />
              </div>
              {integrationLogo && (
                <>
                  <Cable className="mx-auto h-8 w-8 shrink" />
                  <div className="mx-auto h-auto w-full flex-1">
                    <img src={integrationLogo} className="h-12 w-auto" />
                  </div>
                </>
              )}
            </div>
            <div className="flex flex-col gap-2">
              <h1 className="text-2xl font-semibold tracking-tight">
                {heading}
              </h1>
              <p className="text-sm text-muted-foreground">{description}</p>
              {!signInOnly && bottomLink && bottomLinkText && (
                <p className="text-xs text-muted-foreground">
                  <Link
                    to={bottomLink}
                    className="underline underline-offset-4 hover:text-primary"
                  >
                    {bottomLinkText}
                  </Link>
                </p>
              )}
            </div>
          </div>
          <div className="relative flex h-full flex-col gap-8">{children}</div>
        </div>
      </div>
      {!!imageSrc && (
        <div className="relative hidden w-0 flex-1 lg:block">
          <img
            className="absolute inset-0 h-full w-full border-l-2 border-white object-cover shadow-fl"
            src={imageSrc}
            crossOrigin="anonymous"
            alt=""
          />
        </div>
      )}
      <Toaster />
    </div>
  );
}
