import React, { useEffect, useState } from "react";
import { Preview } from "~/src/lib/Preview/Preview";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "~/src/primitives/command";
import {
  Popover,
  PopoverClose,
  PopoverContent,
  PopoverTrigger,
} from "~/src/primitives/popover";
import { Button } from "~/src/primitives/button";
import { Check, ChevronsUpDown } from "lucide-react";
import { LocationAccountInfo } from "~/src/api/locations.api";
import capitalize from "lodash/capitalize";
import { PortfolioItem } from "~/src/api/portfolio.api";
import { getPostProperties } from "../utils";
import Tinybadge from "../../../primitives/tinybadge";
import { PfpWithPlatform } from "./accounts/PfpWithPlatform";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import { cn, pluralize } from "~/src/util/reusables";
import { PopoverPortal } from "@radix-ui/react-popover";

interface PostsPreviewProps {
  portfolioItem: PortfolioItem;
  onSelectAccount?: (account: LocationAccountInfo) => void;
  postClassName?: string;
}

export default function PostsPreview({
  portfolioItem,
  onSelectAccount,
  postClassName,
}: PostsPreviewProps) {
  const user = useSelector((state: RootState) => state.user);
  const [accounts, setAccounts] = useState<LocationAccountInfo[]>([]);
  const [focusedAccount, setFocusedAccount] = useState<
    LocationAccountInfo | undefined
  >();

  const handleSelectAccount = (account: LocationAccountInfo) => {
    setFocusedAccount(account);
    onSelectAccount?.(account);
  };

  useEffect(() => {
    const accountsForScope = portfolioItem?.accounts?.filter((sa) => {
      if (user?.hub) return true;
      return sa.teamId === user?.workspace?._id;
    });
    setAccounts(accountsForScope || []);
    if (accountsForScope?.length) {
      handleSelectAccount(accountsForScope[0]);
    }
  }, [portfolioItem?.accounts, user?.hub, user?.workspace?._id]);

  return (
    <div className="flex flex-col gap-1">
      {Boolean(accounts?.length > 1) && (
        <div className="relative">
          <Popover>
            <PopoverTrigger asChild>
              <Button
                variant="outline"
                role="combobox"
                className="h-auto min-h-12 w-full min-w-0 justify-between bg-background py-1 text-xs font-semibold"
                disabled={!portfolioItem?.accounts?.length}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {focusedAccount ? (
                  <div className="flex w-fit min-w-0 shrink-0 items-center gap-2">
                    <PfpWithPlatform
                      className="h-6 w-6 shrink-0"
                      platform={focusedAccount.platform}
                      platformAccountId={focusedAccount.platformAccountId}
                      platformAccountName={focusedAccount.platformAccountName}
                      platformAccountPfp={focusedAccount.platformAccountPfp}
                    />
                    <span className="truncate text-xs font-bold">
                      {focusedAccount.platformAccountName}
                    </span>
                  </div>
                ) : (
                  "Select an account to preview..."
                )}
                <ChevronsUpDown className="h-4 w-4 shrink-0 opacity-50" />
              </Button>
            </PopoverTrigger>
            <PopoverPortal>
              <PopoverContent className="w-full p-0">
                <Command
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <CommandInput placeholder="Search accounts..." />
                  <CommandList className="max-h-72 overflow-y-auto">
                    <CommandEmpty>No accounts found.</CommandEmpty>
                    <CommandGroup>
                      {accounts.map((account) => (
                        <CommandItem
                          key={`${account.platform}_${account.platformAccountId}`}
                          value={`${account.platform}_${account.platformAccountId}`}
                          onSelect={() => handleSelectAccount(account)}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                          className="p-0"
                        >
                          <PopoverClose>
                            <div className="flex w-full items-center gap-2 px-2 py-1.5 pt-4">
                              <Check
                                className={cn(
                                  "mr-2 h-4 w-4",
                                  focusedAccount?.platformAccountId ===
                                    account.platformAccountId &&
                                    focusedAccount?.platform ===
                                      account.platform
                                    ? "opacity-100"
                                    : "opacity-0",
                                )}
                              />
                              <PfpWithPlatform
                                className="h-6 w-6"
                                platform={account.platform}
                                platformAccountId={account.platformAccountId}
                                platformAccountName={
                                  account.platformAccountName
                                }
                                platformAccountPfp={account.platformAccountPfp}
                              />
                              <span className="text-xs font-bold">
                                {account.platformAccountName}
                              </span>
                              <Tinybadge
                                text={capitalize(
                                  getPostProperties(
                                    portfolioItem as any,
                                    account.platform,
                                  )?.platformContentType,
                                )}
                              />
                            </div>
                          </PopoverClose>
                        </CommandItem>
                      ))}
                    </CommandGroup>
                  </CommandList>
                </Command>
              </PopoverContent>
            </PopoverPortal>
          </Popover>
          <span className="absolute bottom-0.5 left-1/2 -translate-x-1/2 text-right text-[10px] text-muted-foreground">
            {portfolioItem?.posts?.length
              ? `Posted to ${portfolioItem?.posts?.length ?? "1"}/${pluralize(portfolioItem?.accounts?.length, "account")}`
              : `Posting to ${pluralize(accounts.length, "account")}`}
          </span>
        </div>
      )}
      <Preview
        item={portfolioItem}
        focusedAccount={focusedAccount}
        className={cn(postClassName, "min-w-[250px] max-w-none rounded-lg")}
        mediaClassName="w-full"
      />
    </div>
  );
}
