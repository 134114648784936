import axios from "axios";
import { AxiosResponse } from "axios";
import { UserAccount } from "./auth.api";

export interface ApprovalWorkflow {
  _id: string;
  organization: string;
  approvers: Partial<UserAccount>[];
  name: string;
}

// Get all approval workflows for the organization
export const getApprovalWorkflows = async (): Promise<ApprovalWorkflow[]> => {
  const response: AxiosResponse<ApprovalWorkflow[]> = await axios.get(
    "/api/approval-workflows",
  );
  return response.data;
};

// Get a specific approval workflow by ID
export const getApprovalWorkflow = async (
  workflowId: string,
): Promise<ApprovalWorkflow> => {
  const response: AxiosResponse<ApprovalWorkflow> = await axios.get(
    `/api/approval-workflows/${workflowId}`,
  );
  return response.data;
};

// Create a new approval workflow
export const createApprovalWorkflow = async (
  name: string,
  approvers: string[],
): Promise<ApprovalWorkflow> => {
  const response: AxiosResponse<ApprovalWorkflow> = await axios.post(
    "/api/approval-workflows",
    {
      name,
      approvers,
    },
  );
  return response.data;
};

// Update an existing approval workflow
export const updateApprovalWorkflow = async (
  workflowId: string,
  data: Partial<Pick<ApprovalWorkflow, "name" | "approvers">>,
): Promise<ApprovalWorkflow> => {
  const response: AxiosResponse<ApprovalWorkflow> = await axios.patch(
    `/api/approval-workflows/${workflowId}`,
    data,
  );
  return response.data;
};

// Delete an approval workflow
export const deleteApprovalWorkflow = async (
  workflowId: string,
): Promise<void> => {
  await axios.delete(`/api/approval-workflows/${workflowId}`);
};

// Apply workflow to workspace
export const applyApprovalWorkflowToWorkspace = async (
  workspaceId: string,
  workflowId: string,
): Promise<void> => {
  await axios.post(
    `/api/approval-workflows/${workflowId}/workspace/${workspaceId}`,
  );
};

// Remove workflow from workspace
export const removeApprovalWorkflowFromWorkspace = async (
  workspaceId: string,
): Promise<void> => {
  await axios.delete(`/api/approval-workflows/workspace/${workspaceId}`);
};
