import { X } from "lucide-react";
import React from "react";
import { Button } from "../../../../primitives/button";
import { getPlatformName, PlatformType } from "../../../../util/platforms";
import { cn, NewSocialToLogo, TooltipButton } from "../../../../util/reusables";

export const PfpWithPlatform = ({
  platform,
  platformAccountId,
  platformAccountName,
  platformAccountPfp,
  className,
  onRemove,
}: {
  platform: PlatformType;
  platformAccountId: string;
  platformAccountName: string;
  platformAccountPfp: string;
  platformContentType?: string;
  className?: string;
  onRemove?: (platform: PlatformType, platformAccountId: string) => void;
}) => (
  <div className="relative flex">
    <TooltipButton asChild text={getPlatformName(platform)}>
      <div className="group relative flex">
        {!!onRemove && (
          <Button
            variant="destructive"
            size="tinycircle"
            className="absolute left-1/2 top-1/2 hidden -translate-x-1/2 -translate-y-1/2 transform transition-all group-hover:block"
            onClick={(e) => {
              e.stopPropagation();
              onRemove(platform, platformAccountId);
            }}
          >
            <X className="h-3 w-3" />
          </Button>
        )}
        <img
          className={cn(
            "inline-block h-8 w-8 overflow-hidden rounded-full p-0",
            className,
          )}
          crossOrigin="anonymous"
          src={platformAccountPfp}
          alt={`${platformAccountName} on ${platform}`}
        />
      </div>
    </TooltipButton>
    <NewSocialToLogo
      platform={platform}
      className="absolute right-[-0.5rem] top-[-0.5rem] h-5 w-5"
    />
  </div>
);
