import {
  type Campaign,
  type LarvalPost,
  type Media,
  type PortfolioItem,
  type Post,
} from "~/src/api/withApi";
import { Presets } from "~/src/reducers/toolkit";
import { BrandCheck } from "../../api/brandcheck.api";
import { PlatformType } from "../../util/platforms";
import { Tag } from "./components/metadata/ImageTagger";

type PostProperties = {
  porfolioItemId?: string;
  name?: string;
  topics?: string[];
  caption?: string;
  media?: Media[];
  platformContentType: string;
  prompt?: string;
  presets?: Presets;
  campaign?: Campaign;
  accountTags?: Record<PlatformType, Record<string, Tag[]>>;
  recentBrandCheck?: BrandCheck;
  broadcastParentHub?: {
    _id: string;
    name: string;
  };
  isDraft?: boolean;
  scheduledTimestamp?: number;
  accountPlatforms?: PlatformType[];
};

/**
 *
 * @param item
 * @param platform
 */
export const getPostProperties = (
  item: PortfolioItem | LarvalPost | Post,
  platform?: PlatformType,
): PostProperties => {
  if (!item) return {} as any;

  const name =
    (item as PortfolioItem)?.name ??
    (item as LarvalPost)?.portfolioItem?.name ??
    "";

  const topics =
    (item as PortfolioItem)?.topics ??
    (item as LarvalPost)?.portfolioItem?.topics ??
    [];

  // [Backwards Compatability - 4/4/24] - fall back to parent pfi
  const campaign = (item?.campaign ??
    (item as LarvalPost)?.portfolioItem?.campaign) as Campaign;
  const media = ((item?.media?.length
    ? item.media
    : (item as LarvalPost)?.portfolioItem?.media) ??
    (item as any).raw?.media ?? // LinkedIn posts have media in raw
    []) as Media[];
  const caption =
    (item as PortfolioItem)?.overrides?.[platform]?.caption ||
    item?.caption ||
    (item as LarvalPost)?.portfolioItem?.caption ||
    (item as any).raw?.body || // LinkedIn posts have caption in raw
    "";

  const platformContentType =
    (item as LarvalPost)?.overrides?.[platform]?.platformContentType ??
    (item as PortfolioItem)?.overrides?.[platform]?.platformContentType ??
    (item as LarvalPost)?.platformContentType ??
    "post";

  const prompt =
    (item as PortfolioItem)?.overrides?.[platform]?.prompt ??
    item?.prompt ??
    (item as LarvalPost)?.portfolioItem?.prompt;
  const presets =
    (item as PortfolioItem)?.overrides?.[platform]?.presets ??
    (item as unknown as LarvalPost)?.presets ??
    (item as LarvalPost)?.portfolioItem?.presets;

  const accountTags =
    (item as LarvalPost)?.portfolioItem?.accountTags ??
    (item as PortfolioItem)?.accountTags ??
    ({} as Record<PlatformType, Record<string, Tag[]>>);

  const recentBrandCheck =
    (item as PortfolioItem)?.brandChecks?.[0] ??
    (item as LarvalPost)?.portfolioItem?.brandChecks?.[0];

  const porfolioItemId =
    (item as LarvalPost)?.portfolioItem?._id ?? (item as PortfolioItem)?._id;

  const broadcastParentHub =
    (item as LarvalPost)?.broadcastParentHub ??
    (item as PortfolioItem)?.hub ??
    (item as PortfolioItem)?.larvalPosts?.[0]?.broadcastParentHub ??
    ((item as PortfolioItem)?.posts?.[0] as any)?.broadcastParentHub;

  const isDraft =
    (item as PortfolioItem)?.larvalPosts?.[0]?.isDraft ??
    (item as LarvalPost)?.isDraft;

  const scheduledTimestamp =
    (item as PortfolioItem)?.larvalPosts?.[0]?.scheduled?.timestamp ??
    (item as LarvalPost)?.scheduled?.timestamp;

  const accountPlatforms = [
    ...new Set(
      (item as PortfolioItem)?.larvalPosts?.map((post) => post.platform) ?? [],
    ),
  ].sort();

  return {
    porfolioItemId,
    name,
    topics,
    caption,
    media,
    platformContentType,
    prompt,
    presets,
    campaign,
    accountTags,
    recentBrandCheck,
    broadcastParentHub,
    isDraft,
    scheduledTimestamp,
    accountPlatforms,
  };
};
