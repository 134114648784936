import { withQueryParams } from "../util/reusables";
import { PlatformType } from "~/src/util/platforms";
import { LinkedInPostResponse } from "../views/CommunityManagement/lib/utils";
import { Media, Post } from "./withApi";

const getAllPosts = async (refresh = false) =>
  await fetch(`/api/inbox/posts?refresh=${refresh}`).then((posts) =>
    posts.json(),
  );

// Filter by MAIN_FEED in order to filter out ads for now
const getLinkedInPosts = async (refresh = false) =>
  await fetch(`/api/inbox/posts?refresh=${refresh}&platform=linkedin`)
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return res.json().then(({ error }) => {
        throw new Error(error);
      });
    })
    .then((posts) =>
      posts.filter(
        (p: Post<LinkedInPostResponse>) =>
          p?.raw?.feedDistribution === "MAIN_FEED" || !p?.raw,
      ),
    );

const getPostInsights = async (postId: string) =>
  await fetch(`/api/inbox/posts/${postId}/insights`).then((data) =>
    data.json(),
  );

const getFreshPostById = async (postId: string, withComments = false) =>
  await fetch(`/api/inbox/posts/${postId}?withComments=${withComments}`).then(
    (data) => data.json(),
  );

const getCommentComments = async (commentId: string, platform: PlatformType) =>
  await fetch(`/api/inbox/comments/${commentId}?platform=${platform}`).then(
    (data) => data.json(),
  );

export type CommentMutation = {
  actor: string; // organization URN or person URN
  message: string; // Text of the comment. May contain attributes such as links to people and organizations.
  content?: {
    entity: {
      image: string;
    };
  }[]; // The content of the comment, such as an image.
  parentComment?: string; // Composite URN of the parent comment
};

const createComment = <T = Record<string, any>>(
  platform: PlatformType,
  platformPostId: string,
  raw?: T,
) =>
  fetch(`/api/inbox/posts/${platformPostId}/comments`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ platform, raw }),
  });

const toggleCommentVisibility = async (
  platform: PlatformType,
  commentId: string,
  hide: boolean,
) =>
  await fetch(
    `/api/inbox/comments/${commentId}?platform=${platform}&hide=${hide}`,
    {
      method: "POST",
    },
  );

const createLike = async (platform: PlatformType, objectId: string) =>
  await fetch(`/api/inbox/${objectId}/like?platform=${platform}`, {
    method: "POST",
  });

const deleteComment = async (
  platform: PlatformType,
  platformPostId: string,
  commentId: string,
) =>
  await fetch(
    `/api/inbox/posts/${platformPostId}/comments/${commentId}?platform=${platform}`,
    {
      method: "DELETE",
    },
  );

const getInstagramPosts = async (refresh = false) =>
  await fetch(`/api/inbox/posts?refresh=${refresh}&platform=instagram`).then(
    (res) => {
      if (res.ok) {
        return res.json();
      }
      return res.text().then((text) => {
        throw new Error(text);
      });
    },
  );

export type InstagramPost = {
  caption: string;
  comments_count: number;
  id: string;
  like_count: number;
  media_product_type: "AD" | "FEED" | "STORY" | "REELS";
  media_type: "CAROUSEL_ALBUM" | "IMAGE" | "VIDEO" | "REELS"; // `VIDEO` is deprecated as of 11/9/23
  media_url: string;
  permalink: string;
  timestamp: string;
  children?: {
    data: {
      media_type: "IMAGE" | "VIDEO";
      media_url: string;
    }[];
  };
  media: Media[];
  owner?: {
    name: string;
    profile_picture_url: string;
    username: string;
    id: string;
  };
};

export type Participant = {
  id: string;
  username: string;
  name: string;
  profile_picture_url: string;
};

export type Share = {
  link: string;
};

export type Reaction = {
  users: string[]; // user ids
  reaction: string;
};

export type Message = {
  id?: number; // PostgreSQL primary key
  platform: PlatformType;
  platform_id: string;
  conversation_id?: string;
  timestamp: number;
  data: {
    from: string; // user id
    shares?: Share[];
    reactions?: Reaction[];
    message: string;
    is_unsupported?: boolean;
    [key: string]: any;
  };
};

export type Conversation = {
  id?: number; // PostgreSQL primary key
  platform_id: string;
  platform: PlatformType;
  platform_account_id: string;
  workspace_id: string;
  updated_at: number;
  data: {
    name: string;
    participants: Participant[];
    [key: string]: any;
  };
  messages?: Message[];
};

const getFacebookPosts = async (refresh = false) =>
  await fetch(`/api/inbox/posts?refresh=${refresh}&platform=facebook`).then(
    (res) => {
      if (res.ok) {
        return res.json();
      }
      return res.text().then((text) => {
        throw new Error(text);
      });
    },
  );

const getConversations = async (
  platforms: PlatformType[],
  refresh = false,
): Promise<Conversation[]> =>
  await fetch(
    withQueryParams(`/api/inbox/conversations`, { refresh, platforms }),
  ).then(async (res) => {
    if (res.ok) {
      const { data } = await res.json();
      return data;
    }
    return res.text().then((text) => {
      throw new Error(text);
    });
  });

const getConversation = async (
  platform: PlatformType,
  conversationId: string,
): Promise<Conversation> =>
  await fetch(
    `/api/inbox/conversations/${conversationId}?platform=${platform}`,
  ).then((res) => {
    if (res.ok) {
      return res.json();
    }
    return res.text().then((text) => {
      throw new Error(text);
    });
  });

const sendChatMessage = async (
  platform: PlatformType,
  recipient: string,
  message: string,
): Promise<void> =>
  void (await fetch(`/api/inbox/messages?platform=${platform}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ recipient, message }),
  }));

const getFacebookScopes = async (): Promise<string[]> =>
  await fetch(`/api/facebook/scopes`).then((res) => res.json());
const getInstagramScopes = async (): Promise<string[]> =>
  await fetch(`/api/facebook/instagram/scopes`).then((res) => res.json());

export interface InboxAPI {
  getAllPosts: (refresh?: boolean) => Promise<any>;
  getLinkedInPosts: (
    refresh?: boolean,
  ) => Promise<Post<LinkedInPostResponse>[]>;
  getInstagramPosts: (refresh?: boolean) => Promise<Post<InstagramPost>[]>;
  getFacebookPosts: (refresh?: boolean) => Promise<any[]>;
  getPostInsights: (postId: string) => Promise<any>;
  getFreshPostById: (postId: string, withComments?: boolean) => Promise<any>;
  getCommentComments: (
    commentId: string,
    platform: PlatformType,
  ) => Promise<any>;
  createComment: <T = Record<string, any>>(
    platform: PlatformType,
    platformPostId: string,
    raw: T,
  ) => Promise<Response>;
  toggleCommentVisibility: (
    platform: PlatformType,
    commentId: string,
    hide: boolean,
  ) => Promise<any>;
  createLike: (platform: PlatformType, objectId: string) => Promise<any>;
  deleteComment: (
    platform: PlatformType,
    platformPostId: string,
    commentId: string,
  ) => Promise<any>;
  getConversations: (
    platforms: PlatformType[],
    refresh?: boolean,
  ) => Promise<Conversation[]>;
  getConversation: (
    platform: PlatformType,
    conversationId: string,
  ) => Promise<Conversation>;
  sendChatMessage: (
    platform: PlatformType,
    recipient: string,
    message: string,
  ) => Promise<void>;
  getFacebookScopes: () => Promise<string[]>;
  getInstagramScopes: () => Promise<string[]>;
}

export default {
  getAllPosts,
  getLinkedInPosts,
  getPostInsights,
  getFreshPostById,
  getCommentComments,
  getInstagramPosts,
  getFacebookPosts,
  createComment,
  deleteComment,
  toggleCommentVisibility,
  createLike,
  getConversations,
  getConversation,
  sendChatMessage,
  getFacebookScopes,
  getInstagramScopes,
} as InboxAPI;
