import React, { Fragment, useMemo, useState } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { Badge } from "~/src/primitives/badge";
import { Skeleton } from "~/src/primitives/skeleton";
import {
  CalendarIcon,
  BadgeCheck,
  Layers3,
  FlagIcon,
  Loader2,
  RefreshCw,
  Pencil,
  ShipWheel,
  Check,
  X,
  Clock,
  MessageCircle,
} from "lucide-react";
import { Button } from "~/src/primitives/button";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "~/src/primitives/accordion";
import { useNavigate, useParams } from "react-router-dom";
import { getPlatformName } from "../../util/platforms";
import dayjs from "../../util/dayjs";
import {
  getPortfolioItemWithBrandChecks,
  approveBrandCheck,
  rejectBrandCheck,
  addCommentToBrandCheck,
  createBrandCheck,
} from "../../api/brandcheck.api";
import { Textarea } from "~/src/primitives/textarea";
import { toast } from "~/src/primitives/use-toast";
import {
  Select,
  SelectItem,
  SelectContent,
  SelectValue,
  SelectTrigger,
} from "../../primitives/select";
import LoadMedia from "../../partials/Load/LoadMedia";
import TextWithSwatches from "../../partials/ColorSwatch/TextWithSwatches";
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import PostsPreview from "../Post/components/PostsPreview";
import { cn, pluralize, username, withPfp } from "../../util/reusables";
import { Separator } from "../../primitives/separator";
import { AxiosError } from "axios";

export const ACCORDION_ITEM_CLASS = "border-none bg-muted rounded-lg px-4";

export const BrandCheck = () => {
  const user = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const { portfolioItemId } = useParams();
  const queryClient = useQueryClient();
  const [comment, setComment] = useState("");
  const [approvalNotes, setApprovalNotes] = useState("");
  const [selectedBrandCheckIndex, setSelectedBrandCheckIndex] = useState(0);

  const { data, isLoading, error } = useQuery({
    queryKey: ["portfolioItemWithBrandChecks", portfolioItemId],
    queryFn: () => getPortfolioItemWithBrandChecks(portfolioItemId),
    enabled: !!portfolioItemId,
  });

  const isViewOnlyBroadcast = useMemo(
    () =>
      Boolean(
        data?.larvalPosts?.find((lp) => lp.broadcastParentHub)
          ?.broadcastParentHub ||
          data?.posts?.find((p) => p.broadcastParentHub)?.broadcastParentHub ||
          data?.hub,
      ) && !user?.hub,
    [data?.larvalPosts, data?.posts, data?.hub, user?.hub],
  );

  const hubName =
    data?.larvalPosts?.[0]?.broadcastParentHub?.name ||
    data?.posts?.[0]?.broadcastParentHub?.name;

  const accounts = (data?.accounts ?? []).filter((a) =>
    user?.hub ? true : a.teamId === user?.workspace?._id,
  );
  const larvalPlatforms =
    data?.larvalPosts
      ?.filter((lp) =>
        user?.hub ? true : lp.team?._id === user?.workspace?._id,
      )
      ?.map((lp) => getPlatformName(lp.platform)) ?? [];
  const postPlatforms =
    data?.posts
      ?.filter((p) => (user?.hub ? true : p.team?._id === user?.workspace?._id))
      ?.map((p) => getPlatformName(p.platform)) ?? [];
  const scheduledTimestamp = data?.larvalPosts?.[0]?.scheduled?.timestamp;
  const platforms = useMemo(
    () =>
      Array.from(
        new Set([
          ...(accounts?.map((a) => getPlatformName(a.platform)) ?? []),
          ...(larvalPlatforms ?? []),
          ...(postPlatforms ?? []),
        ]),
      ),
    [accounts, larvalPlatforms, postPlatforms],
  );

  const generateBrandCheckMutation = useMutation({
    mutationFn: createBrandCheck,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["portfolioItemWithBrandChecks", portfolioItemId],
      });
      toast({
        title: "Brand check created",
        description: "A new brand check has been created successfully.",
      });
    },
    onError: (error: AxiosError<{ error: string }>) => {
      toast({
        title: "Error",
        description:
          error?.response?.data?.error || "Failed to create brand check.",
        variant: "destructive",
      });
      console.error("Brand check creation error:", error);
    },
  });

  const approveMutation = useMutation({
    mutationFn: ({
      brandCheckId,
      notes,
    }: {
      brandCheckId: string;
      notes?: string;
    }) => approveBrandCheck(brandCheckId, notes),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["portfolioItemWithBrandChecks", portfolioItemId],
      });
      toast({
        title: "Brand check approved",
        description: "The brand check has been approved successfully.",
      });
      setApprovalNotes("");
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to approve brand check.",
        variant: "destructive",
      });
    },
  });

  const rejectMutation = useMutation({
    mutationFn: ({
      brandCheckId,
      notes,
    }: {
      brandCheckId: string;
      notes?: string;
    }) => rejectBrandCheck(brandCheckId, notes),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["portfolioItemWithBrandChecks", portfolioItemId],
      });
      toast({
        title: "Brand check rejected",
        description: "The brand check has been rejected.",
      });
      setApprovalNotes("");
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to reject brand check.",
        variant: "destructive",
      });
    },
  });

  const commentMutation = useMutation({
    mutationFn: ({
      brandCheckId,
      comment,
    }: {
      brandCheckId: string;
      comment: string;
    }) => addCommentToBrandCheck(brandCheckId, comment),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["portfolioItemWithBrandChecks", portfolioItemId],
      });
      toast({
        title: "Comment added",
        description: "Your comment has been added successfully.",
      });
      setComment("");
    },
    onError: () => {
      toast({
        title: "Error",
        description: "Failed to add comment.",
        variant: "destructive",
      });
    },
  });

  if (isLoading || generateBrandCheckMutation.isPending) {
    return <BrandCheckSkeleton />;
  }

  if (error) {
    return <div>Error loading brand check: {error.message}</div>;
  }

  if (!data) {
    return <div>No post data available</div>;
  }

  const hasBrandChecks = data.brandChecks && data.brandChecks.length > 0;
  const sortedBrandChecks = hasBrandChecks
    ? [...data.brandChecks].sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime(),
      )
    : [];

  const latestBrandCheck = hasBrandChecks
    ? sortedBrandChecks[selectedBrandCheckIndex]
    : null;

  const handleApprove = () => {
    if (!latestBrandCheck) return;
    approveMutation.mutate({
      brandCheckId: latestBrandCheck._id,
      notes: approvalNotes || undefined,
    });
  };

  const handleReject = () => {
    if (!latestBrandCheck) return;
    rejectMutation.mutate({
      brandCheckId: latestBrandCheck._id,
      notes: approvalNotes || undefined,
    });
  };

  const handleAddComment = () => {
    if (!latestBrandCheck || !comment.trim()) return;
    commentMutation.mutate({
      brandCheckId: latestBrandCheck._id,
      comment,
    });
  };

  return (
    <div className="flex h-full min-h-full flex-col gap-8 overflow-hidden bg-muted px-6">
      {/* Header */}
      <div className="flex items-center justify-between">
        <div className="flex flex-col gap-1">
          <div className="flex items-center gap-4">
            <h1 className="text-3xl font-semibold">Brand Check</h1>
            {hasBrandChecks && sortedBrandChecks.length > 1 && (
              <Select
                value={selectedBrandCheckIndex.toString()}
                onValueChange={(value) =>
                  setSelectedBrandCheckIndex(parseInt(value))
                }
              >
                <SelectTrigger className="h-8 w-auto whitespace-nowrap bg-background text-xs">
                  <SelectValue placeholder="Select version" />
                </SelectTrigger>
                <SelectContent>
                  {sortedBrandChecks.map((brandCheck, index) => (
                    <SelectItem
                      key={brandCheck._id}
                      value={index.toString()}
                      className="text-xs"
                    >
                      Version {sortedBrandChecks.length - index} -{" "}
                      {dayjs(brandCheck.createdAt).fromNow()}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          </div>
          <p className="text-sm font-normal text-muted-foreground">
            Post by {hubName || user?.workspace?.name}
          </p>
        </div>

        {data?.posts?.length ? (
          <div className="text-sm font-normal text-muted-foreground">
            This content has been posted
          </div>
        ) : isViewOnlyBroadcast ? (
          <div className="text-sm font-normal text-muted-foreground">
            Content broadcasted by hub
          </div>
        ) : (
          <div className="flex items-center gap-2">
            {hasBrandChecks && (
              <Button
                variant="ghost"
                size="sm"
                className="gap-2"
                onClick={() =>
                  generateBrandCheckMutation.mutate(portfolioItemId)
                }
                disabled={generateBrandCheckMutation.isPending}
              >
                {generateBrandCheckMutation.isPending ? (
                  <>
                    <Loader2 className="h-4 w-4 animate-spin" />
                    Regenerating...
                  </>
                ) : (
                  <>
                    <RefreshCw className="h-4 w-4" />
                    Regenerate Brand Check
                  </>
                )}
              </Button>
            )}
            <Button
              size="sm"
              onClick={() => navigate(`/edit/${portfolioItemId}`)}
            >
              <Pencil className="mr-2 h-4 w-4" /> Edit Post
            </Button>
          </div>
        )}
      </div>

      {/* Body */}
      <div className="flex min-h-0 w-full gap-x-4 gap-y-8">
        {/* Left column - Overview */}
        <div className="flex h-full w-[300px] flex-col gap-4 overflow-y-auto pb-8">
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold text-muted-foreground">
              Post {platforms.length > 1 ? "Previews" : "Preview"}
            </h3>
            <PostsPreview portfolioItem={data} postClassName="shadow-fl" />
          </div>
          <div className="flex flex-col gap-2">
            <h3 className="text-lg font-semibold text-muted-foreground">
              Details
            </h3>
            <div className="rounded-lg bg-background p-4 shadow-fl">
              <div className="flex flex-col gap-4">
                {hubName ? (
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-2">
                      <ShipWheel className="h-5 w-5 text-muted-foreground" />
                      <span className="text-sm text-muted-foreground">Hub</span>
                    </div>
                    <p className="font-medium">{hubName}</p>
                  </div>
                ) : (
                  <div className="flex flex-col gap-1">
                    <div className="flex items-center gap-2">
                      <Layers3 className="h-5 w-5 text-muted-foreground" />
                      <span className="text-sm text-muted-foreground">
                        Workspace
                      </span>
                    </div>
                    <p className="font-medium">{user?.workspace?.name}</p>
                  </div>
                )}

                <div className="flex flex-col gap-1">
                  <div className="flex items-center gap-2">
                    <Pencil className="h-5 w-5 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground">
                      Last updated
                    </span>
                  </div>
                  <p className="font-medium">
                    {dayjs(data.updatedAt).fromNow()}
                  </p>
                </div>

                <div className="flex flex-col gap-1">
                  <div className="flex items-center gap-2">
                    <CalendarIcon className="h-5 w-5 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground">
                      Scheduled to post
                    </span>
                  </div>
                  <p className="font-medium">
                    {dayjs(scheduledTimestamp).fromNow()}
                  </p>
                </div>

                <div className="flex flex-col gap-1">
                  <div className="flex items-center gap-2">
                    <BadgeCheck className="h-5 w-5 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground">
                      Socials
                    </span>
                  </div>
                  <p className="font-medium">{platforms.join(", ")}</p>
                </div>

                {/* TODO: Add support for past posts w/ metrics */}
                {/* <div className="flex flex-col gap-1">
                  <div className="flex items-center gap-2">
                    <BarChart className="h-5 w-5 text-muted-foreground" />
                    <span className="text-sm text-muted-foreground">Current Metrics</span>
                  </div>
                  <div className="flex items-center gap-4">
                    <div className="flex items-center gap-1">
                      <Eye className="h-4 w-4 text-muted-foreground" />
                      <span className="font-medium">45</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <HeartIcon className="h-4 w-4 text-muted-foreground" />
                      <span className="font-medium">12</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <MessageCircleIcon className="h-4 w-4 text-muted-foreground" />
                      <span className="font-medium">0</span>
                    </div>
                    <div className="flex items-center gap-1">
                      <Share2Icon className="h-4 w-4 text-muted-foreground" />
                      <span className="font-medium">3</span>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>

        {hasBrandChecks ? (
          <Fragment>
            {/* Middle column - Insights */}
            <div className="flex h-full flex-1 flex-col gap-4 overflow-y-auto pb-8">
              <div className="flex flex-col gap-2">
                <h2 className="text-lg font-semibold text-muted-foreground">
                  Analysis
                </h2>
                <Accordion
                  type="multiple"
                  className="flex w-full max-w-2xl flex-col gap-4 rounded-lg bg-background p-4 shadow-fl"
                >
                  <AccordionItem
                    value="brandcheck"
                    className={ACCORDION_ITEM_CLASS}
                  >
                    <AccordionTrigger>
                      <div className="flex w-64 items-center justify-between">
                        <span className="font-medium">
                          Brand Check AI Score
                        </span>
                        <span className="font-medium text-accent-blue">
                          {latestBrandCheck.brandCheckScore}/100
                        </span>
                      </div>
                    </AccordionTrigger>
                    <AccordionContent>
                      {
                        <TextWithSwatches
                          text={latestBrandCheck.brandCheckDescription}
                        />
                      }
                      <div className="pt-2">
                        <h4 className="pb-1 text-sm font-medium">
                          Recommendations:
                        </h4>
                        <ul className="flex list-disc flex-col gap-1 pl-5">
                          {latestBrandCheck.brandCheckRecommendations.map(
                            (rec, idx) => (
                              <li key={idx} className="text-sm">
                                {<TextWithSwatches text={rec} />}
                              </li>
                            ),
                          )}
                        </ul>
                      </div>
                    </AccordionContent>
                  </AccordionItem>

                  {latestBrandCheck.mediaCheckScore !== undefined && (
                    <AccordionItem
                      value="media"
                      className={ACCORDION_ITEM_CLASS}
                    >
                      <AccordionTrigger>
                        <div className="flex w-64 items-center justify-between">
                          <span className="font-medium">Media Score</span>
                          <span className="font-medium text-accent-blue">
                            {latestBrandCheck.mediaCheckScore}/100
                          </span>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        {
                          <TextWithSwatches
                            text={latestBrandCheck.mediaCheckDescription}
                          />
                        }
                        <div className="pt-2">
                          <h4 className="pb-1 text-sm font-medium">Details:</h4>
                          <div className="flex flex-col rounded-lg bg-background text-primary/70 shadow-fl">
                            {latestBrandCheck.mediaCheckDetails.map(
                              (detail) => {
                                const media = data?.media?.find(
                                  (m) => m._id === detail.id,
                                );
                                return (
                                  <div
                                    className="flex items-center gap-4 p-2"
                                    key={detail.id}
                                  >
                                    {media && (
                                      <div className="relative w-fit shrink-0">
                                        <LoadMedia
                                          mimeType={media.mimeType}
                                          src={media?.editUri ?? media.uri}
                                          thumbnail={media.thumbnail}
                                          className="pointer-events-none h-auto w-24 object-cover object-center"
                                        />
                                      </div>
                                    )}
                                    <div className="w-16 shrink-0 font-medium text-accent-blue">
                                      {detail.score}/100
                                    </div>
                                    <div className="text-sm">
                                      {
                                        <TextWithSwatches
                                          text={detail.description}
                                        />
                                      }
                                    </div>
                                  </div>
                                );
                              },
                            )}
                          </div>
                        </div>
                        <div className="pt-2">
                          <h4 className="pb-1 text-sm font-medium">
                            Recommendations:
                          </h4>
                          <ul className="flex list-disc flex-col gap-1 pl-5">
                            {latestBrandCheck.mediaCheckRecommendations.map(
                              (rec, idx) => (
                                <li key={idx} className="text-sm">
                                  {<TextWithSwatches text={rec} />}
                                </li>
                              ),
                            )}
                          </ul>
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  )}

                  {latestBrandCheck.captionCheckScore !== undefined && (
                    <AccordionItem
                      value="caption"
                      className={ACCORDION_ITEM_CLASS}
                    >
                      <AccordionTrigger>
                        <div className="flex w-64 items-center justify-between">
                          <span className="font-medium">Caption Score</span>
                          <span className="font-medium text-accent-blue">
                            {latestBrandCheck.captionCheckScore}/100
                          </span>
                        </div>
                      </AccordionTrigger>
                      <AccordionContent>
                        {
                          <TextWithSwatches
                            text={latestBrandCheck.captionCheckDescription}
                          />
                        }
                        <div className="pt-2">
                          <h4 className="pb-1 text-sm font-medium">
                            Recommendations:
                          </h4>
                          <ul className="flex list-disc flex-col gap-1 pl-5">
                            {latestBrandCheck.captionCheckRecommendations.map(
                              (rec, idx) => (
                                <li key={idx} className="text-sm">
                                  {<TextWithSwatches text={rec} />}
                                </li>
                              ),
                            )}
                          </ul>
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  )}
                </Accordion>
              </div>

              <div className="flex flex-col gap-2">
                <h2 className="text-lg font-semibold text-muted-foreground">
                  Recommendations
                </h2>
                <div className="rounded-lg bg-background p-4 shadow-fl">
                  {latestBrandCheck.brandCheckRecommendations &&
                  latestBrandCheck.brandCheckRecommendations.length > 0 ? (
                    <div className="flex flex-col gap-4">
                      <p className="text-sm text-muted-foreground">
                        Based on our analysis, here are key recommendations to
                        improve your brand alignment:
                      </p>
                      <div className="flex flex-col gap-2">
                        {latestBrandCheck.brandCheckRecommendations.map(
                          (recommendation, index) => (
                            <div
                              key={index}
                              className="rounded-lg bg-secondary p-3"
                            >
                              <div className="flex items-start gap-2">
                                <BadgeCheck className="h-5 w-5 shrink-0 text-accent-blue" />
                                <p className="text-sm">
                                  {<TextWithSwatches text={recommendation} />}
                                </p>
                              </div>
                            </div>
                          ),
                        )}
                      </div>
                      <div className="pt-2">
                        <p className="text-sm text-muted-foreground">
                          Implementing these recommendations can help improve
                          your brand consistency score and overall content
                          performance.
                        </p>
                      </div>
                    </div>
                  ) : (
                    <p className="text-muted-foreground">
                      No recommendations available
                    </p>
                  )}
                </div>
              </div>
            </div>

            {/* Right column - Actions */}
            <div className="flex h-full flex-1 flex-col gap-4 overflow-y-auto pb-8">
              <div className="flex flex-col gap-2">
                <h2 className="text-lg font-semibold text-muted-foreground">
                  Flagged Issues ({latestBrandCheck.flaggedIssues?.length || 0})
                </h2>
                <div className="flex flex-col gap-2 rounded-lg bg-background p-4 shadow-fl">
                  {latestBrandCheck.flaggedIssues &&
                  latestBrandCheck.flaggedIssues.length > 0 ? (
                    latestBrandCheck.flaggedIssues.map((issue, index) => (
                      <FlaggedIssue
                        key={index}
                        title={issue.type}
                        description={issue.description}
                      />
                    ))
                  ) : (
                    <p className="text-muted-foreground">No issues flagged</p>
                  )}
                </div>
              </div>
              <div className="flex flex-col gap-2">
                <h3 className="text-lg font-semibold text-muted-foreground">
                  Collaboration
                </h3>
                <div className="rounded-lg bg-background p-4 shadow-fl">
                  <div className="flex flex-col gap-4">
                    {latestBrandCheck.status === "pending" ? (
                      <>
                        <p className="text-sm text-muted-foreground">
                          Add Feedback
                        </p>
                        <div className="flex gap-1">
                          <img
                            src={withPfp(user?.pfp, username(user))}
                            className="size-6 rounded-full shadow-fl"
                            crossOrigin="anonymous"
                          />
                          <Textarea
                            placeholder="Will approve once..."
                            value={approvalNotes}
                            onChange={(e) => setApprovalNotes(e.target.value)}
                            className="min-h-[80px]"
                          />
                        </div>
                        <div className="flex gap-2">
                          <Button
                            className="flex-1 bg-accent-blue text-base text-white hover:bg-accent-blue/80"
                            onClick={handleApprove}
                            disabled={approveMutation.isPending}
                          >
                            <Check className="size-4" />
                            Approve
                          </Button>
                          <Button
                            className="flex-1 bg-red-100 text-base text-red-600 hover:bg-red-100/80"
                            onClick={handleReject}
                            disabled={rejectMutation.isPending}
                          >
                            <X className="size-4" />
                            Reject
                          </Button>
                        </div>
                      </>
                    ) : (
                      <div
                        className={cn(
                          "flex flex-col gap-4 rounded-lg p-4",
                          latestBrandCheck.status === "approved"
                            ? "bg-green-50"
                            : "bg-red-50",
                        )}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center gap-2">
                            <img
                              src={withPfp(
                                latestBrandCheck?.reviewedBy?.pfp,
                                username(latestBrandCheck?.reviewedBy),
                              )}
                              className="size-8 rounded-full shadow-fl"
                              crossOrigin="anonymous"
                            />
                            <span className="text-lg font-semibold">
                              {username(latestBrandCheck?.reviewedBy)}
                            </span>
                          </div>
                          <Badge
                            variant={
                              latestBrandCheck.status === "approved"
                                ? "success"
                                : latestBrandCheck.status === "rejected"
                                  ? "destructive"
                                  : "outline"
                            }
                          >
                            {latestBrandCheck.status === "approved" && (
                              <Check className="mr-1 h-4 w-4 text-green-600" />
                            )}
                            {latestBrandCheck.status.charAt(0).toUpperCase() +
                              latestBrandCheck.status.slice(1)}
                          </Badge>
                        </div>
                        <div className="flex items-center gap-2">
                          <Clock className="size-6 text-muted-foreground" />
                          <span className="text-muted-foreground">
                            {dayjs(latestBrandCheck?.reviewedAt).format(
                              "ddd, MMM D, YYYY [at] h:mm A",
                            )}
                          </span>
                        </div>
                        {!!latestBrandCheck?.reviewNotes && (
                          <p className="rounded-lg bg-background p-4 text-muted-foreground">
                            {latestBrandCheck?.reviewNotes}
                          </p>
                        )}
                      </div>
                    )}

                    <Separator />

                    <div className="flex flex-col gap-4">
                      <div className="flex items-center justify-between">
                        <h4 className="text-lg font-semibold text-muted-foreground">
                          Brand Check Discussion
                        </h4>
                        <div className="flex items-center gap-2 rounded-full bg-[#F9F9FC] px-2 py-1 text-sm text-muted-foreground">
                          <MessageCircle className="size-4" />
                          <span>
                            {pluralize(
                              latestBrandCheck.comments?.length || 0,
                              "Comment",
                            )}
                          </span>
                        </div>
                      </div>
                      <div
                        className="flex max-h-[200px] flex-col gap-4 overflow-y-auto"
                        ref={(el) => {
                          if (el) {
                            el.scrollTop = el.scrollHeight;
                          }
                        }}
                      >
                        {latestBrandCheck.comments &&
                        latestBrandCheck.comments.length > 0 ? (
                          latestBrandCheck.comments.map((comment) => (
                            <div
                              key={comment._id}
                              className="flex flex-col gap-1"
                            >
                              <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                  <img
                                    src={withPfp(
                                      latestBrandCheck?.reviewedBy?.pfp,
                                      username(latestBrandCheck?.reviewedBy),
                                    )}
                                    className="size-6 rounded-full shadow-fl"
                                    crossOrigin="anonymous"
                                  />
                                  <span className="font-semibold">
                                    {username(latestBrandCheck?.reviewedBy)}
                                  </span>
                                </div>
                                <span className="text-xs text-muted-foreground">
                                  {dayjs(comment.createdAt).fromNow()}
                                </span>
                              </div>
                              <p className="ml-8 rounded-lg bg-[#F9F9FC] px-4 py-2">
                                {<TextWithSwatches text={comment.comment} />}
                              </p>
                            </div>
                          ))
                        ) : (
                          <p className="text-sm text-muted-foreground">
                            No comments yet
                          </p>
                        )}
                      </div>
                      <div className="flex flex-col gap-2">
                        <div className="flex gap-1">
                          <img
                            src={withPfp(user?.pfp, username(user))}
                            className="size-6 rounded-full shadow-fl"
                            crossOrigin="anonymous"
                          />
                          <Textarea
                            placeholder="Add a comment..."
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            className="min-h-[60px]"
                          />
                        </div>
                        <Button
                          size="sm"
                          className="mt-2"
                          onClick={handleAddComment}
                          disabled={
                            commentMutation.isPending || !comment.trim()
                          }
                        >
                          Add Comment
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        ) : (
          <div className="flex h-full w-full items-center justify-center">
            <div className="max-w-md rounded-lg bg-background p-8 text-center shadow-fl">
              <h3 className="mb-2 text-xl font-semibold">
                No Brand Check Available
              </h3>
              <p className="mb-4 text-muted-foreground">
                This post doesn&apos;t have a brand check yet. Generate one to
                analyze brand alignment.
              </p>
              <Button
                onClick={() => {
                  generateBrandCheckMutation.mutate(portfolioItemId);
                }}
                disabled={generateBrandCheckMutation.isPending}
              >
                {generateBrandCheckMutation.isPending ? (
                  <>
                    <span className="mr-2 h-4 w-4 animate-spin rounded-full border-2 border-background border-t-foreground"></span>
                    Processing...
                  </>
                ) : (
                  "Generate Brand Check"
                )}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const FlaggedIssue = ({
  title,
  description,
}: {
  title: string;
  description: string;
}) => (
  <div className="rounded-lg border border-[#B91C1B4D] bg-red-50 p-4">
    <div className="flex items-center gap-4">
      <FlagIcon className="size-7 shrink-0 text-[#B91C1B]" />
      <div>
        <h3 className="font-medium text-[#B91C1B]">{title}</h3>
        <p className="text-sm text-[#D43537]">{description}</p>
      </div>
    </div>
  </div>
);

const BrandCheckSkeleton = () => (
  <div className="flex flex-col gap-8 p-4">
    <h1 className="text-3xl font-semibold">Brand Check</h1>
    <div className="grid grid-cols-3 gap-4">
      {[...Array(3)].map((_, i) => (
        <Skeleton key={i} className="h-32" />
      ))}
    </div>
    <div className="grid grid-cols-2 gap-8">
      <div>
        <Skeleton className="mb-4 h-6 w-32" />
        <div className="space-y-4">
          {[...Array(4)].map((_, i) => (
            <Skeleton key={i} className="h-24" />
          ))}
        </div>
      </div>
      <div>
        <Skeleton className="mb-4 h-6 w-32" />
        <div className="space-y-2">
          {[...Array(3)].map((_, i) => (
            <Skeleton key={i} className="h-12" />
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default BrandCheck;
