import { B64 } from "./loaders";
import { Crop, PercentCrop } from "react-image-crop";
import heic2any from "heic2any";
import { FFmpeg } from "@ffmpeg/ffmpeg";
import { fetchFile, toBlobURL } from "@ffmpeg/util";

export const loadImage = (uri: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous";
    image.onload = () => resolve(image);
    image.onerror = (e) => {
      console.error("Failed to load image:", e);
      reject(new Error("Failed to load image"));
    };
    image.src = uri; // Set src after handlers to avoid race conditions
  });
};

export const loadVideo = (uri: string): Promise<HTMLVideoElement> => {
  return new Promise((resolve, reject) => {
    const video = document.createElement("video");
    video.crossOrigin = "anonymous";
    video.autoplay = true;
    video.loop = true;
    video.muted = true; // Required for autoplay
    video.playsInline = true;
    video.onloadeddata = () => resolve(video);
    video.onerror = (e) => {
      console.error("Failed to load video:", e);
      reject(new Error("Failed to load video"));
    };
    video.src = uri; // Set src after handlers
    video.load();
  });
};

export const conversion = async (
  image: HTMLImageElement | ImageBitmap,
  format: string,
  blob = false,
  options: { quality?: number } = {},
): Promise<string | Blob> => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", { alpha: format !== "image/jpeg" });
  if (!ctx) throw new Error("Failed to get canvas context");

  canvas.width = image.width;
  canvas.height = image.height;

  if (format === "image/jpeg") {
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
  }
  ctx.drawImage(image, 0, 0);

  const quality = options.quality ?? 1;

  if (!blob) {
    return canvas.toDataURL(format, quality);
  }

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (b) => (b ? resolve(b) : reject(new Error("Failed to create blob"))),
      format,
      quality,
    );
  });
};

export const crop = async (asset: B64, crop: Crop): Promise<B64> => {
  const image = await loadImage(asset.b64 as string);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", { alpha: true });
  if (!ctx) throw new Error("Failed to get canvas context");

  canvas.width = crop.width;
  canvas.height = crop.height;

  ctx.drawImage(
    image,
    crop.x,
    crop.y,
    crop.width,
    crop.height,
    0,
    0,
    crop.width,
    crop.height,
  );

  return {
    b64: canvas.toDataURL("image/png", 1),
    ext: "png",
  };
};

export const cropByPercent = async (
  asset: B64,
  crop: PercentCrop,
): Promise<Blob> => {
  const image = await loadImage(asset.b64 as string);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", { alpha: true });
  if (!ctx) throw new Error("Failed to get canvas context");

  const crx = (image.width * crop.x) / 100;
  const cry = (image.height * crop.y) / 100;
  const crw = (image.width * crop.width) / 100;
  const crh = (image.height * crop.height) / 100;
  const dim = Math.min(crw, crh);

  canvas.width = dim;
  canvas.height = dim;

  ctx.drawImage(image, crx, cry, dim, dim, 0, 0, dim, dim);

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (b) => (b ? resolve(b) : reject(new Error("Failed to create blob"))),
      "image/png",
      1,
    );
  });
};

export const resizeImage = async (
  image: string,
  width: number,
  height: number,
  blob = false,
  format = "image/png",
): Promise<string | Blob> => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", { alpha: format !== "image/jpeg" });
  if (!ctx) throw new Error("Failed to get canvas context");

  const img = await loadImage(image);
  canvas.width = width;
  canvas.height = height;

  // Enable image smoothing for better quality
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(img, 0, 0, width, height);

  if (blob) {
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (b) => (b ? resolve(b) : reject(new Error("Failed to create blob"))),
        format,
        1,
      );
    });
  }

  return canvas.toDataURL(format, 1);
};

export type ThumbnailResult = {
  thumbnail: Blob;
  width: number;
  height: number;
};

export const generateImageThumbnail = async (
  uri: string,
  size = 400,
): Promise<ThumbnailResult> => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", { alpha: false });
  if (!ctx) throw new Error("Failed to get canvas context");

  const img = await loadImage(uri);
  const ratio = size / img.width;
  const width = size;
  const height = Math.round(img.height * ratio);

  canvas.width = width;
  canvas.height = height;

  // Enable image smoothing for better quality
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(img, 0, 0, width, height);

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (!blob) {
          reject(new Error("Failed to create thumbnail blob"));
          return;
        }
        resolve({ thumbnail: blob, width, height });
      },
      "image/webp",
      0.85, // Slightly reduced quality for better compression
    );
  });
};

export const transparencyCheck = async (asset: B64): Promise<boolean> => {
  if (asset.ext !== "png") return false;

  const image = await loadImage(asset.b64 as string);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", { alpha: true });
  if (!ctx) throw new Error("Failed to get canvas context");

  canvas.width = image.width;
  canvas.height = image.height;
  ctx.drawImage(image, 0, 0);

  // More efficient pixel scanning using Uint8ClampedArray
  const { data } = ctx.getImageData(0, 0, canvas.width, canvas.height);
  for (let i = 3; i < data.length; i += 4) {
    if (data[i] < 255) return true;
  }
  return false;
};

export const blobToB64 = (blob: Blob): Promise<B64> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () =>
      resolve({
        ext: blob.type,
        b64: reader.result,
      });
    reader.onerror = () => reject(new Error("Failed to read blob"));
    reader.readAsDataURL(blob);
  });
};

export const checkHeicConvertToAny = async (
  blob: Blob,
  toType: string,
): Promise<Blob> => {
  if (blob.type !== "image/heic") return blob;

  try {
    return (await heic2any({
      blob,
      toType,
      quality: 0.5,
    })) as Blob;
  } catch (error) {
    console.error("HEIC conversion failed:", error);
    throw new Error("Failed to convert HEIC image");
  }
};

export const lossy = async (blob: Blob, quality: number): Promise<Blob> => {
  if (quality > 1 || quality < 0) return blob;

  const bitmap = await createImageBitmap(blob);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", { alpha: false });
  if (!ctx) throw new Error("Failed to get canvas context");

  canvas.width = bitmap.width;
  canvas.height = bitmap.height;
  ctx.drawImage(bitmap, 0, 0);
  bitmap.close(); // Clean up bitmap

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (b) => (b ? resolve(b) : reject(new Error("Failed to create blob"))),
      "image/jpeg",
      quality,
    );
  });
};

export const reduceSize = async (
  blob: Blob,
  maxBytes: number,
): Promise<Blob> => {
  if (blob.size <= maxBytes) return blob;

  const ratio = maxBytes / blob.size;
  const quality = Math.max(0.1, Math.min(1, ratio - 0.05)); // Ensure quality between 0.1 and 1
  const reduced = await lossy(blob, quality);

  return reduced.size > maxBytes ? reduceSize(reduced, maxBytes) : reduced;
};

export const reducePixels = async (
  blob: Blob,
  maxPixels: number,
  outputType = "image/jpeg",
): Promise<Blob> => {
  if (!blob.type.startsWith("image/")) {
    throw new Error("The provided file is not an image");
  }

  const img = await loadImage(URL.createObjectURL(blob));
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", { alpha: outputType !== "image/jpeg" });
  if (!ctx) throw new Error("Failed to get canvas context");

  let { width, height } = img;
  const aspectRatio = width / height;

  if (width * height > maxPixels) {
    width = Math.sqrt(maxPixels * aspectRatio);
    height = width / aspectRatio;
  }

  canvas.width = Math.floor(width);
  canvas.height = Math.floor(height);

  // Enable image smoothing for better quality
  ctx.imageSmoothingEnabled = true;
  ctx.imageSmoothingQuality = "high";

  ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (b) => (b ? resolve(b) : reject(new Error("Failed to create blob"))),
      outputType,
      1,
    );
  });
};

export const shave = async (
  bitmap: ImageBitmap,
  armin: number,
  armax: number,
): Promise<Blob> => {
  const aspectRatio = bitmap.width / bitmap.height;
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d", { alpha: false });
  if (!ctx) throw new Error("Failed to get canvas context");

  let width = bitmap.width;
  let height = bitmap.height;

  if (aspectRatio < armin) {
    // Shave from bottom
    height = Math.floor(width / armin);
  } else if (aspectRatio > armax) {
    // Shave from right
    width = Math.floor(height * armax);
  }

  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(bitmap, 0, 0);

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (b) => (b ? resolve(b) : reject(new Error("Failed to create blob"))),
      "image/jpeg",
      1,
    );
  });
};

interface BlobToFileArgs {
  blob: Blob;
  fileName: string;
  options?: FilePropertyBag;
}

export const DEFAULT_OPTIONS: FilePropertyBag = {
  lastModified: Date.now(),
  type: "image/png",
};

export const blobToFile = ({
  blob,
  fileName,
  options = DEFAULT_OPTIONS,
}: BlobToFileArgs): File => {
  return new File([blob], fileName, { ...DEFAULT_OPTIONS, ...options });
};

export const webmToMp4 = async (file: Blob): Promise<Blob> => {
  const ffmpeg = new FFmpeg();
  // const baseURL = "https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd";
  const baseURL = `https://${location.host}/ffmpeg`;

  try {
    await ffmpeg.load({
      coreURL: await toBlobURL(`${baseURL}/ffmpeg-core.js`, "text/javascript"),
      wasmURL: await toBlobURL(
        `${baseURL}/ffmpeg-core.wasm`,
        "application/wasm",
      ),
    });

    await ffmpeg.writeFile("input.webm", await fetchFile(file));
    await ffmpeg.exec([
      "-i",
      "input.webm",
      "-c:v",
      "libx264",
      "-preset",
      "fast",
      "-crf",
      "23",
      "-c:a",
      "aac",
      "-movflags",
      "+faststart",
      "output.mp4",
    ]);

    const data = await ffmpeg.readFile("output.mp4");
    const resultFile = new Blob([data], { type: "video/mp4" });

    return resultFile;
  } catch (error) {
    console.error("Error converting webm to mp4:", error);
    throw new Error("Failed to convert webm to mp4");
  }
};

export const svgToImage = async (
  svgBlob: Blob,
  type: "image/png" | "image/webp",
): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(svgBlob);

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d", { alpha: true });
      if (!ctx) {
        URL.revokeObjectURL(objectUrl);
        reject(new Error("Could not get canvas context"));
        return;
      }

      canvas.width = img.width;
      canvas.height = img.height;

      // Enable image smoothing for better quality
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = "high";

      ctx.drawImage(img, 0, 0);

      canvas.toBlob(
        (blob) => {
          URL.revokeObjectURL(objectUrl);
          if (!blob) {
            reject(new Error("Failed to create blob from canvas"));
            return;
          }
          resolve(blob);
        },
        type,
        1.0,
      );
    };

    img.onerror = () => {
      URL.revokeObjectURL(objectUrl);
      reject(new Error("Failed to load SVG image"));
    };

    img.src = objectUrl;
  });
};

/**
 * Generates thumbnails on demand to show as video previews
 * @param videoUrl - the video url
 * @param size - the target width of the thumbnail in pixels
 * @param timeRatio - the part of the video to take the thumbnail (0-1)
 * @returns Promise resolving to thumbnail blob, width and height
 */
export const generateVideoThumbnail = async (
  videoUrl: string,
  size = 400,
  timeRatio = 0.25,
) => {
  return new Promise<ThumbnailResult>((resolve, reject) => {
    const video = document.createElement("video");
    video.crossOrigin = "anonymous";
    video.src = videoUrl;
    video.autoplay = true;
    video.preload = "metadata";
    video.muted = true;
    video.playsInline = true;
    video.play().catch(() => {});

    video.onerror = () => {
      reject(new Error("Failed to load video"));
    };

    video.onloadeddata = async () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");

      if (!ctx) {
        reject(new Error("Canvas context is not supported"));
        return;
      }

      try {
        // Set the video's currentTime to the specified ratio of duration
        // Ensure duration is valid before calculating currentTime
        if (isFinite(video.duration)) {
          video.currentTime =
            video.duration * Math.max(0, Math.min(1, timeRatio));
        } else {
          video.currentTime = 0; // Default to start if duration is invalid
        }

        // Wait for the seek to complete
        await new Promise((resolveSeek) => {
          video.onseeked = resolveSeek;
        });

        // Calculate dimensions maintaining aspect ratio
        const ratio = size / video.videoWidth;
        const width = size;
        const height = Math.round(video.videoHeight * ratio);

        canvas.width = width;
        canvas.height = height;
        ctx.drawImage(video, 0, 0, width, height);

        const blob = await new Promise<Blob>((resolve, reject) => {
          canvas.toBlob(
            (blob) => {
              if (blob) {
                resolve(blob);
              } else {
                reject(new Error("Failed to create thumbnail blob"));
              }
            },
            "image/webp",
            1,
          );
        });

        resolve({ thumbnail: blob, width, height });
      } catch (error) {
        console.error("Error generating video thumbnail:", error);
        reject(error);
      } finally {
        // Clean up
        video.pause();
        URL.revokeObjectURL(videoUrl);
      }
    };
  });
};
