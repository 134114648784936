import axios from "axios";

// Mindbody API endpoints
const MINDBODY_BASE_URL = "/api/mindbody";

export const activateMindbody = async (siteId: string) => {
  const response = await axios.get(`${MINDBODY_BASE_URL}/activate`, {
    params: { siteId },
  });
  return response.data;
};

export const connectMindbody = async (credentials: {
  username?: string;
  password?: string;
}) => {
  const response = await axios.post(
    `${MINDBODY_BASE_URL}/connect`,
    credentials,
  );
  return response.data;
};

export const disconnectMindbody = async () => {
  const response = await axios.get(`${MINDBODY_BASE_URL}/disconnect`);
  return response.data;
};

export const getMindbodyClassesExternal = async (params: {
  startDate?: string;
  endDate?: string;
  locationIds: string[];
}) => {
  const response = await axios.get(`${MINDBODY_BASE_URL}/classes/external`, {
    params,
  });
  return response.data;
};

export const getMindbodyLocationsExternal = async () => {
  const response = await axios.get(`${MINDBODY_BASE_URL}/locations/external`);
  return response.data;
};

export const getMindbodyPackagesExternal = async () => {
  const response = await axios.get(`${MINDBODY_BASE_URL}/packages/external`);
  return response.data;
};

export const getMindbodyServicesExternal = async () => {
  const response = await axios.get(`${MINDBODY_BASE_URL}/services/external`);
  return response.data;
};

export const getMindbodyProductsExternal = async () => {
  const response = await axios.get(`${MINDBODY_BASE_URL}/products/external`);
  return response.data;
};

export const getMindbodyStaffExternal = async () => {
  const response = await axios.get(`${MINDBODY_BASE_URL}/staff/external`);
  return response.data;
};

export const getMindbodySite = async () => {
  const response = await axios.get(`${MINDBODY_BASE_URL}/site`);
  return response.data;
};

export const getMindbodyPromoCodes = async () => {
  const response = await axios.get(`${MINDBODY_BASE_URL}/promocodes`);
  return response.data;
};

export const addMindbodyPromoCode = async (promoCodeData: {
  name: string;
  code: string;
  discount?: {
    type: "Percent" | "Amount";
    amount: number;
  };
  activationDate?: string;
  expirationDate?: string;
  maxUses?: number;
  daysAfterCloseDate?: number;
  allowOnline?: boolean;
  daysValid?: string[];
  applicableItems?: any;
}) => {
  const response = await axios.post(
    `${MINDBODY_BASE_URL}/promocodes`,
    promoCodeData,
  );
  return response.data;
};

export const getMindbody = async () => {
  const response = await axios.get(`${MINDBODY_BASE_URL}`);
  return response.data;
};
