import { PlatformType } from "../util/platforms";
import axios from "axios";
import { z } from "zod";
import { Team } from "./team.api";

export const locationSchema = z.object({
  name: z.string().min(1, "Location name is required"),
  admin_name: z.string().min(1, "Admin name is required"),
  admin_email: z.string().email("Invalid email address"),
  address: z.string().min(1, "Address is required"),
  city: z.string().min(1, "City is required"),
  state: z.string().min(1, "State is required"),
  zip: z.string().regex(/^\d{5}(-\d{4})?$/, "Invalid ZIP code"),
  contact_email: z
    .string()
    .email("Invalid email address")
    .optional()
    .or(z.literal("")),
  phone_number: z.string().regex(/^\d{10}$/, "Invalid phone number"),
  website_url: z.string().url("Invalid URL").optional().or(z.literal("")),
  hours: z.object({
    sunday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
    monday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
    tuesday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
    wednesday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
    thursday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
    friday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
    saturday: z.object({
      is_open: z.boolean(),
      open: z.string(),
      close: z.string(),
    }),
  }),
  time_zone: z.string().min(1, "Time zone is required"),
});
export type LocationFormData = z.infer<typeof locationSchema>;

// TODO: This should probably own `platformContentType` rather than overrides...
export type LocationAccountInfo = {
  organizationId: string;
  organizationName: string;
  platform: PlatformType;
  platformAccountName: string;
  platformAccountPfp: string;
  platformAccountId: string;
  teamId: string;
  teamName: string;
  location: LocationFormData;
};

export const getLocationsAccountInfo = async (): Promise<
  LocationAccountInfo[]
> => {
  const response = await axios.get<LocationAccountInfo[]>(
    "/api/locations/accounts",
  );
  return response.data;
};

export type Location = Partial<LocationFormData> & {
  _id: string;
  customFields?: Record<string, any>;
};
export const upsertLocation = async (
  teamId: string,
  locationData: Partial<Location>,
  finishedOnboarding?: boolean,
) => {
  const response = await axios.patch<Location>(
    `/api/locations/${teamId}`,
    locationData,
    {
      params: {
        finishedOnboarding,
      },
    },
  );
  return response.data;
};

const createNewLocation = async (
  organizationId: string,
  locationData: LocationFormData,
  hubId?: string,
  requiresOnboarding?: boolean,
  skipInvite?: boolean,
) => {
  const response = await axios.post<{ team: string; location: string }>(
    `/api/locations/${organizationId}/new`,
    locationData,
    {
      params: {
        hubId,
        requiresOnboarding,
        skipInvite,
      },
    },
  );
  return response.data;
};

const getTeamLocation = async (
  teamId: string,
): Promise<{ _id: string; name: string; location: Location }> => {
  const response = await axios.get<{
    _id: string;
    name: string;
    location: Location;
  }>(`/api/locations/team/${teamId}`);
  return response.data;
};

const inviteLocationAdmin = async (teamId: string) => {
  const response = await axios.post<{ message: string }>(
    `/api/locations/${teamId}/invite`,
  );
  return response.data;
};

export const getHubLocationOrganizations = async () => {
  const response = await axios.get<
    {
      _id: string;
      name: string;
      owner_email: string;
    }[]
  >(`/api/locations/hub/organizations`);
  return response.data;
};

export const buildAddressForWorkspace = (workspace?: Team) => {
  const addressLine = workspace?.location?.address || "";
  const city = workspace?.location?.city || "";
  const state = workspace?.location?.state || "";
  const zip = workspace?.location?.zip || "";
  const address = `${addressLine}${addressLine ? ", " : ""}${city}${
    city ? ", " : ""
  }${state}${state && zip ? " " : ""}${zip}`.trim();
  return { address, name: workspace?.name, _id: workspace?._id };
};

export interface LocationsAPI {
  createNewLocation: (
    organizationId: string,
    locationData: LocationFormData,
    hubId?: string,
    requiresOnboarding?: boolean,
    skipInvite?: boolean,
  ) => Promise<{
    team: string;
    location: string;
  }>;
  getTeamLocation: (teamId: string) => Promise<{
    _id: string;
    name: string;
    location: Location;
  }>;
  inviteLocationAdmin: (teamId: string) => Promise<{
    message: string;
  }>;
}

export default {
  createNewLocation,
  getTeamLocation,
  inviteLocationAdmin,
} as LocationsAPI;
