import React, { Dispatch, SetStateAction } from "react";
import TemplatePreview from "~/src/partials/Templates/Preview";
import Gallery from "../Post/Gallery";
import { PortfolioItem } from "../../api/portfolio.api";
import { getPostProperties } from "../../views/Post/utils";
import { LocationAccountInfo } from "../../api/locations.api";
import { cn } from "../../util/reusables";
import { PlatformType } from "../../util/platforms";
import dayjs from "dayjs";

interface PreviewProps {
  item: PortfolioItem;
  focusedAccount?: LocationAccountInfo;
  carouselIndex?: number;
  setCarouselIndex?: Dispatch<SetStateAction<number>>;
  onClickMedia?: () => void;
  onClickAccount?: () => void;
  mediaClassName?: string;
  className?: string;
  account?: LocationAccountInfo;
}

export const Preview = ({
  item,
  focusedAccount,
  mediaClassName,
  carouselIndex = 0,
  setCarouselIndex,
  onClickAccount,
  onClickMedia,
  className,
  account,
}: PreviewProps) => {
  if (!item) return null;

  const { media, caption, platformContentType } = getPostProperties(
    item,
    focusedAccount?.platform,
  );

  const gallery = media?.length ? (
    <Gallery
      media={media}
      className="w-full"
      mediaClassName={mediaClassName}
      carouselIndex={carouselIndex}
      setCarouselIndex={setCarouselIndex}
    />
  ) : (
    <></>
  );

  return (
    <div
      className={cn(
        "flex max-w-xs flex-col justify-start space-y-4",
        className,
      )}
    >
      <TemplatePreview
        display={gallery}
        platform={
          focusedAccount?.platform ??
          (item as any).platform ??
          PlatformType.Instagram
        }
        platformContentType={platformContentType}
        forceDisplayPfp={focusedAccount?.platformAccountPfp}
        forceDisplayName={focusedAccount?.platformAccountName}
        caption={caption}
        key={carouselIndex + media?.[carouselIndex]?.uri}
        media={media?.[carouselIndex]?.uri}
        mimeType={media?.[carouselIndex]?.mimeType}
        onSelectAccount={onClickAccount}
        onSelectMedia={onClickMedia}
        account={account}
        when={dayjs(item.larvalPosts?.[0]?.scheduled?.timestamp)}
      />
    </div>
  );
};
