import axios from "axios";
import { z } from "zod";
import { ObjectValues } from "../../../util/reusables";
import { CreateAdCreativeData } from "./adcreatives.api";
import { MetaAdLabel } from "./adlabels.api";
import { AdsetDestination } from "./adsets.api";
import { PaidCampaignObjective } from "./campaigns.api";

export const PaidAdStatus = {
  ACTIVE: "ACTIVE",
  PAUSED: "PAUSED",
  DELETED: "DELETED",
  ARCHIVED: "ARCHIVED",
} as const;
export type PaidAdStatus = ObjectValues<typeof PaidAdStatus>;

export const IMetaAd = z.object({
  id: z.string().describe("Ad's ID"),
  name: z.string().describe("Ad's name"),
  status: z
    .enum(Object.values(PaidAdStatus) as [string, ...string[]])
    .describe("Ad status"),
  adset_id: z.string().describe("ID of the ad set that contains this ad"),
  creative: z
    .object({
      creative_id: z
        .string()
        .min(1, "Please select an ad creative")
        .describe("Creative ID on creation"),
      id: z.string().optional().describe("Creative ID on read"),
      // Add more fields as needed for the creative
    })
    .describe("Creative associated with this ad"),

  // Optional fields
  adlabels: z
    .array(MetaAdLabel)
    .optional()
    .describe("Ad Labels associated with this ad"),
  bid_amount: z.number().optional().describe("Bid amount for this ad"),
  configured_status: z
    .enum(Object.values(PaidAdStatus) as [string, ...string[]])
    .optional()
    .describe("The configured status of this ad"),
  effective_status: z
    .enum([
      "ACTIVE",
      "PAUSED",
      "DELETED",
      "PENDING_REVIEW",
      "DISAPPROVED",
      "PREAPPROVED",
      "PENDING_BILLING_INFO",
      "CAMPAIGN_PAUSED",
      "ARCHIVED",
      "ADSET_PAUSED",
      "IN_PROCESS",
      "WITH_ISSUES",
    ])
    .optional()
    .describe("The effective status of this ad"),
  created_time: z.string().datetime().optional().describe("Created time"),
  updated_time: z.string().datetime().optional().describe("Updated time"),
  campaign_id: z
    .string()
    .optional()
    .describe("ID of the campaign that contains this ad"),
  targeting: z.any().optional().describe("Targeting specs for this ad"),
  tracking_specs: z
    .array(z.any())
    .optional()
    .describe("Tracking specs for this ad"),
  isParent: z.boolean().optional().describe("Whether this is a parent ad"),
  children: z.array(z.string()).optional().describe("Children ad IDs"),
});

export type IMetaAd = z.infer<typeof IMetaAd> & {
  _id?: string;
  hub?: string;
  team?: string;
  parent?: string;
  metaErrors?: any[];
};

export const CreateAdSchema = IMetaAd.pick({
  name: true,
  status: true,
  adset_id: true,
  // creative: true,
  campaign_id: true,
  isParent: true,
  adlabels: true,
  tracking_specs: true,
}).extend({
  name: z.string().min(1, "Ad name is required"),
});

export type CreateAdData = z.infer<typeof CreateAdSchema>;
export const createPaidAd = async (
  adData: CreateAdData,
  adCreativeData?: CreateAdCreativeData,
  mediaIds?: string[],
): Promise<{
  ad: IMetaAd;
  successCount?: number;
  errorCount?: number;
}> => {
  const response = await axios.post<{
    ad: IMetaAd;
    successCount?: number;
    errorCount?: number;
  }>("/api/paid/ads", { adData, adCreativeData, mediaIds });
  return response.data;
};

export const retryPaidAd = async (
  _id: string,
): Promise<{
  ad: IMetaAd;
  successCount?: number;
  errorCount?: number;
}> => {
  const response = await axios.post<{
    ad: IMetaAd;
    successCount?: number;
    errorCount?: number;
  }>(`/api/paid/ads/${_id}/retry`);
  return response.data;
};

export const updatePaidAdStatus = async (
  adId: string,
  on: boolean,
  shouldReturnParent?: boolean,
): Promise<IMetaAd> => {
  const response = await axios.patch<IMetaAd>(`/api/paid/ads/${adId}/status`, {
    on,
    shouldReturnParent,
  });
  return response.data;
};

export const updatePaidAd = async (
  adId: string,
  updateData: Partial<CreateAdData>,
): Promise<IMetaAd> => {
  const response = await axios.patch<IMetaAd>(
    `/api/paid/ads/${adId}`,
    updateData,
  );
  return response.data;
};

export const getPaidAd = async (adId: string): Promise<IMetaAd> => {
  const response = await axios.get<IMetaAd>(`/api/paid/ads/${adId}`);
  return response.data;
};

export const listPaidAdsForAdset = async (
  adsetId: string,
): Promise<IMetaAd[]> => {
  const response = await axios.get<IMetaAd[]>(`/api/paid/ads/adset/${adsetId}`);
  return response.data;
};

export const deletePaidAd = async (
  adId: string,
): Promise<{ message: string }> => {
  const response = await axios.delete<{ message: string }>(
    `/api/paid/ads/${adId}`,
  );
  return response.data;
};

export const syncPaidAdsForAdset = async (adsetId?: string): Promise<void> => {
  const response = await axios.post<void>(`/api/paid/ads/sync/${adsetId}`);
  return response.data;
};

export const CallToAction = {
  ADD_TO_CART: "ADD_TO_CART",
  APPLY_NOW: "APPLY_NOW",
  ASK_ABOUT_SERVICES: "ASK_ABOUT_SERVICES",
  ASK_FOR_MORE_INFO: "ASK_FOR_MORE_INFO",
  AUDIO_CALL: "AUDIO_CALL",
  BOOK_A_CONSULTATION: "BOOK_A_CONSULTATION",
  BOOK_NOW: "BOOK_NOW",
  BOOK_TRAVEL: "BOOK_TRAVEL",
  BUY: "BUY",
  BUY_NOW: "BUY_NOW",
  BUY_TICKETS: "BUY_TICKETS",
  BUY_VIA_MESSAGE: "BUY_VIA_MESSAGE",
  CALL: "CALL",
  CALL_ME: "CALL_ME",
  CALL_NOW: "CALL_NOW",
  CHAT_WITH_US: "CHAT_WITH_US",
  CONFIRM: "CONFIRM",
  CONTACT: "CONTACT",
  CONTACT_US: "CONTACT_US",
  DONATE: "DONATE",
  DONATE_NOW: "DONATE_NOW",
  DOWNLOAD: "DOWNLOAD",
  EVENT_RSVP: "EVENT_RSVP",
  FIND_A_GROUP: "FIND_A_GROUP",
  FIND_YOUR_GROUPS: "FIND_YOUR_GROUPS",
  FOLLOW_NEWS_STORYLINE: "FOLLOW_NEWS_STORYLINE",
  FOLLOW_PAGE: "FOLLOW_PAGE",
  FOLLOW_USER: "FOLLOW_USER",
  GET_A_QUOTE: "GET_A_QUOTE",
  GET_DIRECTIONS: "GET_DIRECTIONS",
  GET_OFFER: "GET_OFFER",
  GET_OFFER_VIEW: "GET_OFFER_VIEW",
  GET_PROMOTIONS: "GET_PROMOTIONS",
  GET_QUOTE: "GET_QUOTE",
  GET_SHOWTIMES: "GET_SHOWTIMES",
  GET_STARTED: "GET_STARTED",
  INQUIRE_NOW: "INQUIRE_NOW",
  INSTALL_APP: "INSTALL_APP",
  INSTALL_MOBILE_APP: "INSTALL_MOBILE_APP",
  JOIN_CHANNEL: "JOIN_CHANNEL",
  LEARN_MORE: "LEARN_MORE",
  LIKE_PAGE: "LIKE_PAGE",
  LISTEN_MUSIC: "LISTEN_MUSIC",
  LISTEN_NOW: "LISTEN_NOW",
  MAKE_AN_APPOINTMENT: "MAKE_AN_APPOINTMENT",
  MESSAGE_PAGE: "MESSAGE_PAGE",
  MOBILE_DOWNLOAD: "MOBILE_DOWNLOAD",
  NO_BUTTON: "NO_BUTTON",
  OPEN_INSTANT_APP: "OPEN_INSTANT_APP",
  OPEN_LINK: "OPEN_LINK",
  ORDER_NOW: "ORDER_NOW",
  PAY_TO_ACCESS: "PAY_TO_ACCESS",
  PLAY_GAME: "PLAY_GAME",
  PLAY_GAME_ON_FACEBOOK: "PLAY_GAME_ON_FACEBOOK",
  PURCHASE: "PURCHASE",
  PURCHASE_GIFT_CARDS: "PURCHASE_GIFT_CARDS",
  RAISE_MONEY: "RAISE_MONEY",
  RECORD_NOW: "RECORD_NOW",
  REFER_FRIENDS: "REFER_FRIENDS",
  REQUEST_TIME: "REQUEST_TIME",
  SAY_THANKS: "SAY_THANKS",
  SEE_MORE: "SEE_MORE",
  SELL_NOW: "SELL_NOW",
  SEND_A_GIFT: "SEND_A_GIFT",
  SEND_GIFT_MONEY: "SEND_GIFT_MONEY",
  SEND_UPDATES: "SEND_UPDATES",
  SHARE: "SHARE",
  SHOP_NOW: "SHOP_NOW",
  SIGN_UP: "SIGN_UP",
  SOTTO_SUBSCRIBE: "SOTTO_SUBSCRIBE",
  START_ORDER: "START_ORDER",
  SUBSCRIBE: "SUBSCRIBE",
  SWIPE_UP_PRODUCT: "SWIPE_UP_PRODUCT",
  SWIPE_UP_SHOP: "SWIPE_UP_SHOP",
  UPDATE_APP: "UPDATE_APP",
  USE_APP: "USE_APP",
  USE_MOBILE_APP: "USE_MOBILE_APP",
  VIDEO_ANNOTATION: "VIDEO_ANNOTATION",
  VIDEO_CALL: "VIDEO_CALL",
  VIEW_CHANNEL: "VIEW_CHANNEL",
  VIEW_PRODUCT: "VIEW_PRODUCT",
  VISIT_PAGES_FEED: "VISIT_PAGES_FEED",
  WATCH_MORE: "WATCH_MORE",
  WATCH_VIDEO: "WATCH_VIDEO",
  WHATSAPP_MESSAGE: "WHATSAPP_MESSAGE",
  WOODHENGE_SUPPORT: "WOODHENGE_SUPPORT",
} as const;

export type CallToAction = ObjectValues<typeof CallToAction>;

// TODO: FINISH THIS
export const CallToActionInfo: Record<
  CallToAction,
  {
    label: string;
    value: string;
  }
> = {
  ADD_TO_CART: { label: "Add to Cart", value: "ADD_TO_CART" },
  APPLY_NOW: { label: "Apply Now", value: "APPLY_NOW" },
  ASK_ABOUT_SERVICES: {
    label: "Ask About Services",
    value: "ASK_ABOUT_SERVICES",
  },
  ASK_FOR_MORE_INFO: { label: "Ask for More Info", value: "ASK_FOR_MORE_INFO" },
  AUDIO_CALL: { label: "Audio Call", value: "AUDIO_CALL" },
  BOOK_A_CONSULTATION: {
    label: "Book a Consultation",
    value: "BOOK_A_CONSULTATION",
  },
  BOOK_NOW: { label: "Book Now", value: "BOOK_NOW" },
  BOOK_TRAVEL: { label: "Book Travel", value: "BOOK_TRAVEL" },
  BUY: { label: "Buy", value: "BUY" },
  BUY_NOW: { label: "Buy Now", value: "BUY_NOW" },
  BUY_TICKETS: { label: "Buy Tickets", value: "BUY_TICKETS" },
  BUY_VIA_MESSAGE: { label: "Buy via Message", value: "BUY_VIA_MESSAGE" },
  CALL: { label: "Call", value: "CALL" },
  CALL_ME: { label: "Call Me", value: "CALL_ME" },
  CALL_NOW: { label: "Call Now", value: "CALL_NOW" },
  CHAT_WITH_US: { label: "Chat with Us", value: "CHAT_WITH_US" },
  CONFIRM: { label: "Confirm", value: "CONFIRM" },
  CONTACT: { label: "Contact", value: "CONTACT" },
  CONTACT_US: { label: "Contact Us", value: "CONTACT_US" },
  DONATE: { label: "Donate", value: "DONATE" },
  DONATE_NOW: { label: "Donate Now", value: "DONATE_NOW" },
  DOWNLOAD: { label: "Download", value: "DOWNLOAD" },
  EVENT_RSVP: { label: "Event RSVP", value: "EVENT_RSVP" },
  FIND_A_GROUP: { label: "Find a Group", value: "FIND_A_GROUP" },
  FIND_YOUR_GROUPS: { label: "Find Your Groups", value: "FIND_YOUR_GROUPS" },
  FOLLOW_NEWS_STORYLINE: {
    label: "Follow News Storyline",
    value: "FOLLOW_NEWS_STORYLINE",
  },
  FOLLOW_PAGE: { label: "Follow Page", value: "FOLLOW_PAGE" },
  FOLLOW_USER: { label: "Follow User", value: "FOLLOW_USER" },
  GET_A_QUOTE: { label: "Get a Quote", value: "GET_A_QUOTE" },
  GET_DIRECTIONS: { label: "Get Directions", value: "GET_DIRECTIONS" },
  GET_OFFER: { label: "Get Offer", value: "GET_OFFER" },
  GET_OFFER_VIEW: { label: "Get Offer View", value: "GET_OFFER_VIEW" },
  GET_PROMOTIONS: { label: "Get Promotions", value: "GET_PROMOTIONS" },
  GET_QUOTE: { label: "Get Quote", value: "GET_QUOTE" },
  GET_SHOWTIMES: { label: "Get Showtimes", value: "GET_SHOWTIMES" },
  GET_STARTED: { label: "Get Started", value: "GET_STARTED" },
  INQUIRE_NOW: { label: "Inquire Now", value: "INQUIRE_NOW" },
  INSTALL_APP: { label: "Install App", value: "INSTALL_APP" },
  INSTALL_MOBILE_APP: {
    label: "Install Mobile App",
    value: "INSTALL_MOBILE_APP",
  },
  JOIN_CHANNEL: { label: "Join Channel", value: "JOIN_CHANNEL" },
  LEARN_MORE: { label: "Learn More", value: "LEARN_MORE" },
  LIKE_PAGE: { label: "Like Page", value: "LIKE_PAGE" },
  LISTEN_MUSIC: { label: "Listen Music", value: "LISTEN_MUSIC" },
  LISTEN_NOW: { label: "Listen Now", value: "LISTEN_NOW" },
  MAKE_AN_APPOINTMENT: {
    label: "Make an Appointment",
    value: "MAKE_AN_APPOINTMENT",
  },
  MESSAGE_PAGE: { label: "Message Page", value: "MESSAGE_PAGE" },
  MOBILE_DOWNLOAD: { label: "Mobile Download", value: "MOBILE_DOWNLOAD" },
  NO_BUTTON: { label: "No Button", value: "NO_BUTTON" },
  OPEN_INSTANT_APP: { label: "Open Instant App", value: "OPEN_INSTANT_APP" },
  OPEN_LINK: { label: "Open Link", value: "OPEN_LINK" },
  ORDER_NOW: { label: "Order Now", value: "ORDER_NOW" },
  PAY_TO_ACCESS: { label: "Pay to Access", value: "PAY_TO_ACCESS" },
  PLAY_GAME: { label: "Play Game", value: "PLAY_GAME" },
  PLAY_GAME_ON_FACEBOOK: {
    label: "Play Game on Facebook",
    value: "PLAY_GAME_ON_FACEBOOK",
  },
  PURCHASE: { label: "Purchase", value: "PURCHASE" },
  PURCHASE_GIFT_CARDS: {
    label: "Purchase Gift Cards",
    value: "PURCHASE_GIFT_CARDS",
  },
  RAISE_MONEY: { label: "Raise Money", value: "RAISE_MONEY" },
  RECORD_NOW: { label: "Record Now", value: "RECORD_NOW" },
  REFER_FRIENDS: { label: "Refer Friends", value: "REFER_FRIENDS" },
  REQUEST_TIME: { label: "Request Time", value: "REQUEST_TIME" },
  SAY_THANKS: { label: "Say Thanks", value: "SAY_THANKS" },
  SEE_MORE: { label: "See More", value: "SEE_MORE" },
  SELL_NOW: { label: "Sell Now", value: "SELL_NOW" },
  SEND_A_GIFT: { label: "Send a Gift", value: "SEND_A_GIFT" },
  SEND_GIFT_MONEY: { label: "Send Gift Money", value: "SEND_GIFT_MONEY" },
  SEND_UPDATES: { label: "Send Updates", value: "SEND_UPDATES" },
  SHARE: { label: "Share", value: "SHARE" },
  SHOP_NOW: { label: "Shop Now", value: "SHOP_NOW" },
  SIGN_UP: { label: "Sign Up", value: "SIGN_UP" },
  SOTTO_SUBSCRIBE: { label: "Sotto Subscribe", value: "SOTTO_SUBSCRIBE" },
  START_ORDER: { label: "Start Order", value: "START_ORDER" },
  SUBSCRIBE: { label: "Subscribe", value: "SUBSCRIBE" },
  SWIPE_UP_PRODUCT: { label: "Swipe Up Product", value: "SWIPE_UP_PRODUCT" },
  SWIPE_UP_SHOP: { label: "Swipe Up Shop", value: "SWIPE_UP_SHOP" },
  UPDATE_APP: { label: "Update App", value: "UPDATE_APP" },
  USE_APP: { label: "Use App", value: "USE_APP" },
  USE_MOBILE_APP: { label: "Use Mobile App", value: "USE_MOBILE_APP" },
  VIDEO_ANNOTATION: { label: "Video Annotation", value: "VIDEO_ANNOTATION" },
  VIDEO_CALL: { label: "Video Call", value: "VIDEO_CALL" },
  VIEW_CHANNEL: { label: "View Channel", value: "VIEW_CHANNEL" },
  VIEW_PRODUCT: { label: "View Product", value: "VIEW_PRODUCT" },
  VISIT_PAGES_FEED: { label: "Visit Pages Feed", value: "VISIT_PAGES_FEED" },
  WATCH_MORE: { label: "Watch More", value: "WATCH_MORE" },
  WATCH_VIDEO: { label: "Watch Video", value: "WATCH_VIDEO" },
  WHATSAPP_MESSAGE: { label: "WhatsApp Message", value: "WHATSAPP_MESSAGE" },
  WOODHENGE_SUPPORT: { label: "Woodhenge Support", value: "WOODHENGE_SUPPORT" },
};

export type CampaignObjectiveCallToActionMapping = {
  [key in PaidCampaignObjective]: {
    [key in AdsetDestination]?: CallToAction[];
  } & {
    default?: CallToAction[];
  };
};

/**
 * Maps campaign objectives and destinations to valid call-to-action buttons
 * https://developers.facebook.com/docs/marketing-api/reference/ad-creative/call-to-actions
 */
export const CAMPAIGN_OBJECTIVE_CTA_MAPPING: CampaignObjectiveCallToActionMapping =
  {
    [PaidCampaignObjective.Awareness]: {
      default: [
        CallToAction.APPLY_NOW,
        CallToAction.BOOK_NOW,
        CallToAction.CALL_NOW,
        CallToAction.CONTACT_US,
        CallToAction.DOWNLOAD,
        CallToAction.GET_DIRECTIONS,
        CallToAction.GET_QUOTE,
        CallToAction.GET_SHOWTIMES,
        CallToAction.LEARN_MORE,
        CallToAction.LISTEN_NOW,
        CallToAction.ORDER_NOW,
        CallToAction.REQUEST_TIME,
        // Not seeing a match for...
        // CallToAction.SAVE,
        // CallToAction.SEE_MENU,
        // CallToAction.SEND_MESSAGE,
        // CallToAction.SEND_WHATSAPP_MESSAGE,
        CallToAction.SHOP_NOW,
        CallToAction.SIGN_UP,
        CallToAction.SUBSCRIBE,
        CallToAction.WATCH_MORE,
      ],
    },
    [PaidCampaignObjective.Traffic]: {
      [AdsetDestination.WEBSITE]: [
        CallToAction.APPLY_NOW,
        CallToAction.BOOK_NOW,
        CallToAction.CONTACT_US,
        CallToAction.DONATE_NOW,
        CallToAction.DOWNLOAD,
        CallToAction.GET_OFFER,
        CallToAction.GET_QUOTE,
        CallToAction.GET_SHOWTIMES,
        CallToAction.LEARN_MORE,
        CallToAction.LISTEN_NOW,
        CallToAction.ORDER_NOW,
        CallToAction.REQUEST_TIME,
        // CallToAction.SEE_MENU,
        CallToAction.SHOP_NOW,
        CallToAction.SIGN_UP,
        CallToAction.SUBSCRIBE,
        CallToAction.WATCH_MORE,
      ],
      [AdsetDestination.APP]: [
        CallToAction.BOOK_NOW,
        CallToAction.DONATE_NOW,
        CallToAction.DOWNLOAD,
        CallToAction.LEARN_MORE,
        CallToAction.OPEN_LINK,
        CallToAction.ORDER_NOW,
        CallToAction.PLAY_GAME,
        // CallToAction.SEE_MENU,
        CallToAction.SHOP_NOW,
        CallToAction.SIGN_UP,
        CallToAction.SUBSCRIBE,
        CallToAction.USE_APP,
        CallToAction.WATCH_MORE,
      ],
      // TODO: Keep working on CTAs from here...
      [AdsetDestination.MESSENGER]: [
        CallToAction.APPLY_NOW,
        CallToAction.BOOK_NOW,
        CallToAction.CONTACT_US,
        CallToAction.DONATE_NOW,
        CallToAction.GET_QUOTE,
        CallToAction.LEARN_MORE,
        CallToAction.ORDER_NOW,
        CallToAction.MESSAGE_PAGE, // SEND_MESSAGE equivalent?
      ],
      [AdsetDestination.WHATSAPP]: [],
      [AdsetDestination.CALLS]: [
        CallToAction.CALL_NOW,
        CallToAction.GET_DIRECTIONS,
      ],
      default: [CallToAction.LEARN_MORE, CallToAction.NO_BUTTON],
    },
    [PaidCampaignObjective.Engagement]: {
      [AdsetDestination.MESSAGE_DESTINATIONS]: [
        CallToAction.MESSAGE_PAGE,
        // CallToAction.SEND_MESSAGE,
        CallToAction.WHATSAPP_MESSAGE,
      ],
      [AdsetDestination.ON_AD]: [
        CallToAction.LEARN_MORE,
        CallToAction.LIKE_PAGE,
        CallToAction.NO_BUTTON,
        CallToAction.WATCH_MORE,
      ],
      [AdsetDestination.CALLS]: [
        CallToAction.CALL_NOW,
        CallToAction.GET_DIRECTIONS,
      ],
      [AdsetDestination.WEBSITE]: [
        CallToAction.CONTACT_US,
        CallToAction.GET_QUOTE,
        CallToAction.LEARN_MORE,
        CallToAction.SHOP_NOW,
        CallToAction.SIGN_UP,
      ],
      [AdsetDestination.APP]: [
        CallToAction.INSTALL_APP,
        CallToAction.PLAY_GAME,
        CallToAction.USE_APP,
      ],
      [AdsetDestination.FACEBOOK]: [
        CallToAction.LEARN_MORE,
        CallToAction.LIKE_PAGE,
      ],
      default: [CallToAction.LEARN_MORE, CallToAction.NO_BUTTON],
    },
    [PaidCampaignObjective.Leads]: {
      [AdsetDestination.WEBSITE]: [
        CallToAction.APPLY_NOW,
        CallToAction.CONTACT_US,
        CallToAction.DOWNLOAD,
        CallToAction.GET_QUOTE,
        CallToAction.LEARN_MORE,
        CallToAction.SIGN_UP,
      ],
      [AdsetDestination.ON_AD]: [
        CallToAction.APPLY_NOW,
        CallToAction.GET_QUOTE,
        CallToAction.LEARN_MORE,
        CallToAction.SIGN_UP,
        CallToAction.SUBSCRIBE,
      ],
      default: [
        CallToAction.GET_QUOTE,
        CallToAction.LEARN_MORE,
        CallToAction.SIGN_UP,
      ],
    },
    [PaidCampaignObjective.AppPromotion]: {
      default: [
        CallToAction.INSTALL_APP,
        CallToAction.INSTALL_MOBILE_APP,
        CallToAction.PLAY_GAME,
        CallToAction.USE_APP,
        CallToAction.USE_MOBILE_APP,
      ],
    },
    [PaidCampaignObjective.Sales]: {
      [AdsetDestination.WEBSITE]: [
        CallToAction.BOOK_NOW,
        CallToAction.BUY_NOW,
        CallToAction.DOWNLOAD,
        CallToAction.GET_OFFER,
        CallToAction.LEARN_MORE,
        CallToAction.SHOP_NOW,
      ],
      [AdsetDestination.APP]: [
        CallToAction.INSTALL_APP,
        CallToAction.SHOP_NOW,
        CallToAction.USE_APP,
      ],
      [AdsetDestination.MESSAGE_DESTINATIONS]: [
        CallToAction.MESSAGE_PAGE,
        // CallToAction.SEND_MESSAGE,
        CallToAction.SHOP_NOW,
        CallToAction.WHATSAPP_MESSAGE,
      ],
      default: [
        CallToAction.BUY_NOW,
        CallToAction.LEARN_MORE,
        CallToAction.SHOP_NOW,
      ],
    },
  };

export const getCallToActionsForObjective = (
  objective: PaidCampaignObjective,
  destination?: AdsetDestination,
): Array<{
  action: CallToAction;
  info: ReturnType<typeof getCallToActionInfo>;
}> => {
  const objectiveMapping = CAMPAIGN_OBJECTIVE_CTA_MAPPING[objective];
  if (!objectiveMapping) return [];

  const actions = destination
    ? objectiveMapping[destination] || objectiveMapping.default || []
    : objectiveMapping.default || [];

  return actions.map((action) => ({
    action,
    info: getCallToActionInfo(objective, destination, action),
  }));
};

export const getCallToActionInfo = (
  objective: PaidCampaignObjective,
  destination: AdsetDestination,
  action: CallToAction,
) => {
  const info = CallToActionInfo[action];
  if (!info)
    return {
      label: action,
      value: action,
      description: "Call-to-action button for your ad.",
    };

  return {
    ...info,
    description: (() => {
      switch (action) {
        case CallToAction.SHOP_NOW:
          return objective === PaidCampaignObjective.Sales
            ? "Direct people to purchase products on your website or app."
            : "Direct people to your shop or product listings.";
        case CallToAction.LEARN_MORE:
          return "Direct people to learn more about your offering.";
        case CallToAction.SIGN_UP:
          return objective === PaidCampaignObjective.Leads
            ? "Encourage people to sign up for your offering or service."
            : "Direct people to sign up for your offering.";
        // Add more contextual descriptions based on objective/destination
        default:
          return `Use the "${info.label}" call-to-action button.`;
      }
    })(),
  };
};

export const syncPaidAdsForAccount = async (): Promise<{
  message: string;
  adsResult: {
    nMatched: number;
    nModified: number;
    nUpserted: number;
  };
  creativesResult: {
    nMatched: number;
    nModified: number;
    nUpserted: number;
  };
}> => {
  const response = await axios.get<{
    message: string;
    adsResult: {
      nMatched: number;
      nModified: number;
      nUpserted: number;
    };
    creativesResult: {
      nMatched: number;
      nModified: number;
      nUpserted: number;
    };
  }>("/api/paid/insights/ads/sync");
  return response.data;
};
