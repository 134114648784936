import React, { useContext, useEffect, useState } from "react";
import API from "~/src/api/withApi";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
} from "~/src/primitives/dialog";
import { OrganizationContext } from "../../Organization";
import { AlertOctagon, ChevronsUpDown, Info } from "lucide-react";
import { Button } from "~/src/primitives/button";
import { Input } from "~/src/primitives/input";
import { UserAccount } from "~/src/api/auth.api";
import UserSearch from "./UserSearch";
import { username } from "~/src/util/reusables";
import { Separator } from "~/src/primitives/separator";
import { useDispatch } from "react-redux";
import { setUser } from "~/src/reducers/user";
import { Alert } from "~/src/primitives/alert";

interface TransferOwnershipModalProps {
  show: boolean;
  onHide: () => void;
}

const TransferOwnershipModal = ({
  show,
  onHide,
}: TransferOwnershipModalProps) => {
  const dispatch = useDispatch();
  const organization = useContext(OrganizationContext);
  const [confirmation, setConfirmation] = useState<string>("");
  const [selectedOwner, setSelectedOwner] = useState<Partial<UserAccount>>();

  const onTransfer = (newOwner: string) =>
    API.transferOrganizationOwnership(newOwner)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => dispatch(setUser(user)));

  useEffect(() => {
    if (organization) {
      setSelectedOwner(organization.owner);
    }
  }, [organization]);

  if (!organization || !selectedOwner) return <></>;

  const confirmationKey = organization.name ?? "My Organization";
  const eligibleTransfers = [
    organization.owner,
    ...organization.seats.filter(
      (s) =>
        !s.subscription ||
        !s.subscription.tier ||
        (s.subscription.tier as any) === "0",
    ),
  ];

  return (
    <Dialog open={show} onOpenChange={(open) => !open && onHide()}>
      <DialogContent>
        <DialogHeader>
          <div className="flex flex-row items-center space-x-2 font-bold">
            <div>
              Transfer ownership of {organization?.name ?? "My Organization"}
            </div>
          </div>
        </DialogHeader>

        <Separator />

        <Alert>
          <div className="flex flex-row items-center space-x-2">
            <div>
              <Info className="mr-2 h-4 w-4" />
            </div>
            <div className="text-xs">
              You may transfer organization ownership to members of your
              organization,{" "}
              <strong>
                who do not have a Flamel.AI subscription of their own.
              </strong>
            </div>
          </div>
        </Alert>

        <div className="my-2">
          <div className="mb-3 text-xs">Choose an owner</div>
          <UserSearch
            seats={eligibleTransfers}
            onSelect={(s) => setSelectedOwner(s)}
            onDeselect={() => setSelectedOwner(organization.owner)}
            selected={[selectedOwner._id ?? organization.owner._id]}
          >
            <Button variant="outline">
              <img
                src={selectedOwner?.pfp}
                className="mr-3 h-6 w-6 rounded-full"
                alt=""
                crossOrigin="anonymous"
              />
              <div className="whitespace-nowrap text-sm font-bold">
                {username(selectedOwner)}
              </div>
              <ChevronsUpDown className="ml-auto size-3" />
            </Button>
          </UserSearch>
        </div>

        <div className="my-2">
          <div className="mb-3 text-xs">
            Type the organization name below to confirm -{" "}
            <strong>you will not be able to undo this action.</strong>
          </div>
          <Input
            value={confirmation}
            onChange={(e) => setConfirmation(e.target.value)}
            placeholder={confirmationKey}
          />
        </div>

        <DialogFooter>
          <Button variant="outline" size="sm" onClick={onHide}>
            Close
          </Button>
          <Button
            variant="destructive"
            size="sm"
            disabled={confirmation !== confirmationKey}
            onClick={() => {
              onTransfer(selectedOwner._id);
              onHide();
            }}
          >
            <AlertOctagon className="mr-2 h-4 w-4" /> Transfer Ownership
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default TransferOwnershipModal;
