type MetaAdsManagerEditor = {
  act: string;
  campaigns?: string[];
  adsets?: string[];
  ads?: string[];
};
export const openMetaAdsManagerEditor = (
  slug: string,
  { act, campaigns, adsets, ads }: MetaAdsManagerEditor,
) => {
  const metaUrl = new URL(
    `https://adsmanager.facebook.com/adsmanager/manage/${slug}/edit`,
  );
  metaUrl.searchParams.set("act", act);
  if (campaigns?.length)
    metaUrl.searchParams.set("selected_campaign_ids", campaigns.join(","));
  if (adsets?.length)
    metaUrl.searchParams.set("selected_adset_ids", adsets.join(","));
  if (ads?.length) metaUrl.searchParams.set("selected_ad_ids", ads.join(","));

  window.open(metaUrl.toString(), "_blank", "noopener,noreferrer");
};

export const openMetaEventsManagerEditor = (act: string, pixel_id?: string) => {
  let metaUrl: URL;
  if (pixel_id) {
    metaUrl = new URL(
      `https://www.facebook.com/events_manager2/list/pixel/${pixel_id}/overview`,
    );
  } else {
    metaUrl = new URL(`https://www.facebook.com/events_manager2/overview`);
  }
  metaUrl.searchParams.set("act", act);
  window.open(metaUrl.toString(), "_blank", "noopener,noreferrer");
};
