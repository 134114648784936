import React from "react";
import API from "~/src/api/withApi";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "~/src/primitives/dialog";
import { Input } from "~/src/primitives/input";
import { Button } from "~/src/primitives/button";
import { Check, Cog, Plus } from "lucide-react";
import {
  updateCurrentWorkspace,
  WorkspaceManagement,
} from "~/src/api/team.api";
import { Role } from "~/src/api/auth.api";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "~/src/primitives/popover";
import { Separator } from "~/src/primitives/separator";
import { useDispatch } from "react-redux";
import { setUser } from "~/src/reducers/user";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "~/src/primitives/select";
import { approvalWorkflowFromId } from "../Approval";
import { setLoading, setSuccess } from "~/src/reducers/toolkit";
import { withPfp } from "~/src/util/reusables";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Load from "../../../../partials/Load/Load";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "../../../../primitives/command";
import { Badge } from "../../../../primitives/badge";
import { unenumify } from "../UserSeats";
import {
  applyApprovalWorkflowToWorkspace,
  removeApprovalWorkflowFromWorkspace,
} from "../../../../api/approvalWorkflows.api";

interface WorkspaceSettingsModalProps {
  show: boolean;
  workspace_id?: string;
  onHide: () => void;
}

const WorkspaceSettingsModal = ({
  show,
  workspace_id,
  onHide,
}: WorkspaceSettingsModalProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const { data: workspace, isLoading: isWorkspaceLoading } =
    useQuery<WorkspaceManagement>({
      queryKey: ["workspace", workspace_id],
      queryFn: () => API.getWorkspaceManagement(workspace_id),
      enabled: !!workspace_id && show,
    });

  const updateNameMutation = useMutation({
    mutationFn: (newName: string) => updateCurrentWorkspace({ name: newName }),
    onSuccess: () => {
      dispatch(setSuccess("Name updated successfully"));
      queryClient.invalidateQueries({ queryKey: ["workspace", workspace_id] });
      API.getSession("?update=true").then(({ user }) => {
        dispatch(setUser(user));
      });
    },
  });

  const onDeleteWorkspace = async (workspaceId: string) =>
    API.deleteOrganizationWorkspace(workspaceId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => {
        dispatch(setUser(user));
        queryClient.invalidateQueries({
          queryKey: ["workspace", workspace_id],
        });
      });

  const addUserToWorkspace = (userId: string, workspaceId: string) => {
    API.addUserToOrganizationWorkspace(workspaceId, userId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => {
        dispatch(setUser(user));
        queryClient.invalidateQueries({
          queryKey: ["workspace", workspace_id],
        });
      });
  };

  const removeUserFromWorkspace = (userId: string, workspaceId: string) => {
    API.removeUserFromOrganizationWorkspace(workspaceId, userId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => {
        dispatch(setUser(user));
        queryClient.invalidateQueries({
          queryKey: ["workspace", workspace_id],
        });
        queryClient.refetchQueries({
          queryKey: ["portfolioItems"],
          exact: false,
        });
      });
  };

  const onApplyApprovalWorkflowToWorkspace = (
    workspaceId: string,
    approvalWorkflowId: string,
  ) =>
    applyApprovalWorkflowToWorkspace(workspaceId, approvalWorkflowId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => {
        dispatch(setUser(user));
        queryClient.invalidateQueries({
          queryKey: ["workspace", workspace_id],
        });
        queryClient.refetchQueries({
          queryKey: ["portfolioItems"],
          exact: false,
        });
      });

  const onRemoveApprovalWorkflowFromWorkspace = (workspaceId: string) =>
    removeApprovalWorkflowFromWorkspace(workspaceId)
      .then(() => API.getSession("?update=true"))
      .then(({ user }) => {
        dispatch(setUser(user));
        queryClient.invalidateQueries({
          queryKey: ["workspace", workspace_id],
        });
        queryClient.refetchQueries({
          queryKey: ["portfolioItems"],
          exact: false,
        });
      });

  if (isWorkspaceLoading) return <Load />;

  return (
    <Dialog
      open={show}
      onOpenChange={(open) => {
        if (!open) {
          onHide();
        }
      }}
    >
      {isWorkspaceLoading ? (
        <DialogContent>
          {" "}
          <DialogHeader className="sr-only">
            <DialogTitle>Workspace Settings</DialogTitle>
          </DialogHeader>
          <Load className="min-h-96" />
        </DialogContent>
      ) : (
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="sr-only">Workspace Settings</DialogTitle>
            <div className="flex flex-row items-center justify-center">
              <div>
                <Cog className="mr-2 h-4 w-4" />
              </div>
              <div>
                <span className="font-lighter">
                  Settings for{" "}
                  <span className="font-bold">{workspace?.name}</span>
                </span>
              </div>
            </div>
          </DialogHeader>

          <div className="my-4">
            <div className="mb-6 flex flex-row items-center justify-start gap-2">
              <div className="mx-4 text-xs font-bold">Name:</div>
              <div className="flex gap-2">
                <Input
                  spellCheck={false}
                  defaultValue={workspace?.name}
                  placeholder="Enter workspace name"
                  onChange={(e) => {
                    if (e.target.value && e.target.value !== workspace?.name) {
                      updateNameMutation.mutate(e.target.value);
                    }
                  }}
                />
              </div>
            </div>

            <Separator className="my-6" />

            <div className="flex flex-col gap-4">
              <div className="flex flex-row items-center justify-between">
                <div className="font-bold">Access Control</div>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button size="xs" className="whitespace-nowrap">
                      <Plus className="mr-1 size-3" />
                      Add User
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="w-[300px] p-0">
                    <Command>
                      <CommandInput placeholder="Search users..." />
                      <CommandList>
                        <CommandEmpty>No users found.</CommandEmpty>
                        <CommandGroup>
                          {workspace?.organization?.seats
                            ?.filter(
                              (seat) =>
                                seat.organizationRole !== Role.SUPER_ADMIN,
                            )
                            .map((user) => (
                              <CommandItem
                                key={user._id}
                                value={user.email}
                                onSelect={() => {
                                  const isSelected = workspace?.users?.some(
                                    (u) => u.email === user.email,
                                  );
                                  if (isSelected) {
                                    removeUserFromWorkspace(
                                      user._id,
                                      workspace._id,
                                    );
                                  } else {
                                    addUserToWorkspace(user._id, workspace._id);
                                  }
                                }}
                              >
                                <div className="flex items-center gap-2">
                                  <img
                                    src={withPfp(
                                      user.pfp,
                                      `${user?.firstname} ${user?.lastname}`,
                                    )}
                                    className="h-5 w-5 rounded-full shadow-fl"
                                    crossOrigin="anonymous"
                                  />
                                  <div className="flex flex-col">
                                    <span>
                                      {user.firstname && user.lastname
                                        ? `${user.firstname} ${user.lastname}`
                                        : user.email}
                                    </span>
                                    {user.email &&
                                      user.firstname &&
                                      user.lastname && (
                                        <span className="text-xs text-muted-foreground">
                                          {user.email}
                                        </span>
                                      )}
                                  </div>
                                  {workspace?.users?.some(
                                    (u) => u._id === user._id,
                                  ) && (
                                    <Check className="ml-auto h-4 w-4 text-green-400" />
                                  )}
                                </div>
                              </CommandItem>
                            ))}
                        </CommandGroup>
                      </CommandList>
                    </Command>
                  </PopoverContent>
                </Popover>
              </div>

              <div className="flex max-h-[300px] flex-col gap-2 overflow-y-auto rounded-lg border pr-2">
                {/* Super Admins */}
                {workspace?.organization?.seats
                  ?.filter((seat) => seat.organizationRole === Role.SUPER_ADMIN)
                  .map((admin) => (
                    <div
                      key={admin._id}
                      className="flex items-center gap-3 border-b p-2"
                    >
                      <img
                        src={withPfp(
                          admin.pfp,
                          `${admin?.firstname} ${admin?.lastname}`,
                        )}
                        className="h-10 w-10 rounded-full border shadow-sm"
                        crossOrigin="anonymous"
                      />
                      <div className="flex flex-1 flex-col">
                        <span className="font-medium">
                          {admin.firstname && admin.lastname
                            ? `${admin.firstname} ${admin.lastname}`
                            : admin.email}
                        </span>
                        {admin.email && admin.firstname && admin.lastname && (
                          <span className="text-xs text-muted-foreground">
                            {admin.email}
                          </span>
                        )}
                      </div>
                      <Badge variant="secondary">
                        {unenumify(admin.organizationRole)}
                      </Badge>
                    </div>
                  ))}

                {/* Users */}
                {workspace?.users
                  ?.filter((e) => e.organizationRole !== Role.SUPER_ADMIN)
                  .map((e) => (
                    <div
                      key={e._id}
                      className="flex items-center gap-3 border-b p-2"
                    >
                      <img
                        src={withPfp(e.pfp, `${e?.firstname} ${e?.lastname}`)}
                        className="h-10 w-10 rounded-full border shadow-sm"
                        crossOrigin="anonymous"
                      />
                      <div className="flex flex-1 flex-col">
                        <span className="font-medium">
                          {e.firstname && e.lastname
                            ? `${e.firstname} ${e.lastname}`
                            : e.email}
                        </span>
                        {e.email && e.firstname && e.lastname && (
                          <span className="text-xs text-muted-foreground">
                            {e.email}
                          </span>
                        )}
                      </div>
                      <div className="flex items-center gap-2">
                        <Badge variant="outline">
                          {unenumify(e.organizationRole)}
                        </Badge>
                        <Button
                          variant="destructive"
                          size="sm"
                          onClick={() =>
                            removeUserFromWorkspace(e._id, workspace._id)
                          }
                        >
                          Remove
                        </Button>
                      </div>
                    </div>
                  ))}
              </div>
            </div>

            {workspace?.organization?.approvalWorkflows?.length > 0 && (
              <>
                <Separator className="my-6" />
                <div className="flex flex-row items-center justify-start gap-2">
                  <div className="mx-4 text-xs font-bold">
                    Approval Workflow:
                  </div>
                  <Select
                    defaultValue={workspace?.approvalWorkflow?._id ?? null}
                    onValueChange={async (v) => {
                      dispatch(setLoading("Updating approval workflow..."));
                      await onRemoveApprovalWorkflowFromWorkspace(
                        workspace._id,
                      ).then(
                        async () =>
                          v &&
                          (await onApplyApprovalWorkflowToWorkspace(
                            workspace._id,
                            v,
                          )),
                      );
                      dispatch(setSuccess("Updated!"));
                    }}
                  >
                    <SelectTrigger className="w-[200px]">
                      <SelectValue>
                        {approvalWorkflowFromId(
                          workspace?.approvalWorkflow?._id,
                          workspace?.organization as any,
                        )?.name ?? <strong>None</strong>}
                      </SelectValue>
                    </SelectTrigger>
                    <SelectContent>
                      <SelectGroup>
                        <SelectItem value={null} key={"NULLKEY"}>
                          <strong>None</strong>
                        </SelectItem>
                        <SelectSeparator />
                        {workspace?.organization?.approvalWorkflows?.map(
                          (awf) => (
                            <SelectItem value={awf?._id} key={awf?._id}>
                              {awf?.name}
                            </SelectItem>
                          ),
                        )}
                      </SelectGroup>
                    </SelectContent>
                  </Select>
                </div>
              </>
            )}
          </div>

          <DialogFooter className="sm:justify-between">
            {workspace?._id !== workspace?.organization?.owner?.defaultTeam && (
              <Button
                variant="destructive"
                size="sm"
                onClick={() => {
                  onDeleteWorkspace(workspace?._id).then(() => onHide());
                }}
              >
                Delete Workspace
              </Button>
            )}
            <Button variant="outline" size="sm" onClick={onHide}>
              Close
            </Button>
          </DialogFooter>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default WorkspaceSettingsModal;
