import axios from "axios";
import { Media } from "./media.api";

export interface ContentPillar {
  _id: string;
  name: string;
  description: string;
  isGenerated?: boolean;
}

export interface BrandType {
  _id?: string;
  logos?: string[] | Media[]; // brand logos
  colors?: string[]; // list of hex colors
  font?: string; // font name
  secondaryFonts?: string[];
  overview?: string; // long text input for user to describe their company.
  voice?: string; // long text input field for user to describe their brand voice/tone.
  audience?: string; // long text input field for user to describe their target audience.
  hub?: string;
  team?: string;
  contentPillars?: string[] | ContentPillar[];
  restrictFonts?: boolean;

  // New fields
  typographyGuidelines?: string; // Guidelines for typography usage
  colorPaletteGuidelines?: string; // Description of color palette usage
  imageryStyle?: string; // Guidelines for imagery and photos
  socialMediaGuidelines?: string; // Guidelines for social media content
  brandValues?: string; // Core brand values
  restrictions?: string; // Brand restrictions and what to avoid
}

const getBrand = async () => (await axios.get(`/api/brand`)).data;

const createBrand = async (diff: Partial<BrandType>): Promise<BrandType> => {
  const result = await axios.post(`/api/brand`, {
    method: "POST",
    body: JSON.stringify(diff),
    headers: {
      "Content-Type": "application/json",
    },
  });
  return result.data;
};

const updateBrand = (brandId: string, diff: Partial<BrandType>) =>
  fetch(`/api/brand/${brandId}`, {
    method: "PATCH",
    body: JSON.stringify(diff),
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());

export const generateContentPillars = async (brandId: string, n = 1) => {
  const res = await axios.post(`/api/brand/${brandId}/pillars/generate`, {
    n,
  });
  return res.data as ContentPillar[];
};

export interface BrandAPI {
  getBrand: () => Promise<BrandType & { name: string; isReadOnly: boolean }>;
  createBrand: (brand: BrandType) => Promise<BrandType>;
  updateBrand: (
    brandId: string,
    diff: Partial<BrandType>,
  ) => Promise<BrandType>;
  generateContentPillars: (
    brandId: string,
    n?: number,
  ) => Promise<ContentPillar[]>;
}

export default {
  getBrand,
  createBrand,
  updateBrand,
  generateContentPillars,
} as BrandAPI;
